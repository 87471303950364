// Logic for price filter dropdowns

import cssConstants from '../../utils/cssConstants';

const elements = {};

const selectors = {
    PRICE_WRAPPER: '.js-price-wrapper',
    PRICE_FROM: '.js-price-from',
    PRICE_TO: '.js-price-to',
    PRICE_APPLY_BTN: '.js-price-apply',
    PRICE_DROPDOWN: '.js-price-dropdown'
};

const classes = {
    PRICE_FROM: 'js-price-from',
    PRICE_TO: 'js-price-to'
};

const priceFilters = {
    init: function() {
        elements.PRICE_APPLY_BTN = document.querySelectorAll(selectors.PRICE_APPLY_BTN);
        this.applyPriceSelection();
        this.bindChangeEventsToPriceDropdowns();

        // After price has been applied, trigger change events so that impossible prices would be hidden TODO: check if possible and fix this in productsearchfiltersprice.isml isscript or move to controller.
        document.querySelectorAll(selectors.PRICE_DROPDOWN).forEach((dropdown) => {
            dropdown.dispatchEvent(new Event('change'));
        });
    },

    applyPriceSelection: function() {
        elements.PRICE_APPLY_BTN.forEach((btn) => {
            btn.addEventListener('click', function() {
                const hashParams = (window.location.hash.length > 1) ? MD.utils.url.querystringToObject(window.location.hash) : {};
                if (Object.keys(hashParams).length === 0) {
                    if (window.location.search) {
                        hashParams.q = window.location.search.replace('?q=', '');
                    }
                }
                const wrapper = this.closest(selectors.PRICE_WRAPPER);
                const priceFrom = wrapper.querySelector('.' + classes.PRICE_FROM).value;
                const priceTo = wrapper.querySelector('.' + classes.PRICE_TO).value;

                if (isNaN(priceFrom) && isNaN(priceTo)) {
                    btn.setAttribute('disabled', 'disabled');
                    return;
                }

                hashParams.pmin = priceFrom;
                hashParams.pmax = priceTo;

                if (isNaN(priceFrom)) { // if price from is unset, set the lowest value
                    const options = wrapper.querySelector('.' + classes.PRICE_FROM).querySelectorAll('option');
                    hashParams.pmin = options[1].value;
                }

                if (isNaN(priceTo)) { // if price to is unset, set the highest value
                    const options = wrapper.querySelector('.' + classes.PRICE_TO).querySelectorAll('option');
                    hashParams.pmax = options[options.length - 1].value;
                }

                window.location.hash = new URLSearchParams(Object.entries(hashParams));
            });
        });
    },

    bindChangeEventsToPriceDropdowns: function() {
        document.querySelectorAll(selectors.PRICE_DROPDOWN).forEach((dropdown) => {
            dropdown.addEventListener('change', () => {
                priceFilters.updatePriceDrowdowns(dropdown);
            });
        });
    },

    updatePriceDrowdowns: function(dropdown) {
        const isFromDD = dropdown.classList.contains(classes.PRICE_FROM);
        const isToDD = dropdown.classList.contains(classes.PRICE_TO);
        const targetValue = parseInt(dropdown.value, 10);

        if (isFromDD) {
            const priceToSelect = dropdown.closest(selectors.PRICE_WRAPPER).querySelector(selectors.PRICE_TO);
            priceToSelect.querySelectorAll('option').forEach((option) => {
                if (parseInt(option.value, 10) <= targetValue) {
                    option.classList.add(cssConstants.HIDDEN);
                    option.setAttribute('disabled', 'disabled');
                } else {
                    option.classList.remove(cssConstants.HIDDEN);
                    option.removeAttribute('disabled');
                }
            });

            if (!isNaN(dropdown.value) || !isNaN(priceToSelect.value)) {
                dropdown.closest(selectors.PRICE_WRAPPER).querySelector(selectors.PRICE_APPLY_BTN).removeAttribute('disabled');
            }
        }

        if (isToDD) {
            const priceFromSelect = dropdown.closest(selectors.PRICE_WRAPPER).querySelector(selectors.PRICE_FROM);
            priceFromSelect.querySelectorAll('option').forEach((option) => {
                if (parseInt(option.value, 10) >= targetValue) {
                    option.classList.add(cssConstants.HIDDEN);
                    option.setAttribute('disabled', 'disabled');
                } else {
                    option.classList.remove(cssConstants.HIDDEN);
                    option.removeAttribute('disabled');
                }
            });

            if (!isNaN(dropdown.value) || !isNaN(priceFromSelect.value)) {
                dropdown.closest(selectors.PRICE_WRAPPER).querySelector(selectors.PRICE_APPLY_BTN).removeAttribute('disabled');
            }
        }
    }
};

export default priceFilters;