/*
 * This class is used to manage country related actions.
 * */
import CustomSelect from './components/customSelect';
import onePopupSolution, {flyoutsConfig} from './components/onepopupsolution';
import helpers from './utils/helpers';
import cssConstants from './utils/cssConstants';

const MD = window.MD || {};

function localeSelector(countrySelect, languageSelect, priv) {
    if (!countrySelect) return;

    // eslint-disable-next-line no-unused-vars
    const countryCustomSelect = new CustomSelect(countrySelect, {
        customClass: 'country-select',
        optionLabel: function() {
            const data = [];
            const itemData = {};
            countrySelect.querySelectorAll('option').forEach((option) => {
                itemData.slug = option.innerText;
                itemData.value = option.value;
                itemData.text = option.innerText;
                data.push(priv._format(itemData));
            });
            return data;
        }
    });

    if (languageSelect) {
        const languageCustomSelect = new CustomSelect(languageSelect);

        countrySelect.addEventListener('change', () => {
            languageCustomSelect.reInit();
        });
    }
}

MD.Country = (function (window, $) {
    let initialized = false;

    const elements = {
        countryselector: null,
        countrylist: null,
        languagelist: null,
        flyout: {
            country: $('.js-flyout-country'),
            headerSpace: $('.js-header-space'),
            header: $('.js-header-total'),
        }
    };

    const priv = {

        init: function() {
            initialized = true;

            if (!window.MD.imageBaseUrl) {
                window.MD.imageBaseUrl = '/on/demandware.static/Sites-EUR-Site/-/en_NL/v1410526426406/img/';
            }

            priv._updateCountrySelectorStatus();

            // initialize language drop down list
            priv._createLanguageOptions(priv._getCorrectBlockForSpecSelector(elements.countrylist, 'countryselect'));
            priv._createLanguageOptions(priv._getCorrectBlockForSpecSelector(elements.countrylist, 'countryselectPopup'), true);
            const locale = elements.countryselector.data('locale');
            const languageSelect = document.querySelector('.js-language-select');
            const languagePopupSelect = document.querySelector('.js-language-popup-select');

            if (languageSelect) {
                languageSelect.value = locale || app.locale;

                if (languageSelect.value === '' && !languageSelect.querySelector('option[value=' + locale || app.locale + ']')) {
                    languageSelect.value = languageSelect.querySelector('option').value;
                }

                const selectChangeEvent = new Event('change');
                languageSelect.dispatchEvent(selectChangeEvent);
            }

            const countrySelect = document.querySelector('.js-country-selector .country');
            const countryPopupSelect = document.querySelector('.js-country-popup .country');

            localeSelector(countrySelect, languageSelect, priv);
            localeSelector(countryPopupSelect, languagePopupSelect, priv);

            // Position the flyout on load
            priv.position();
        },

        bindEvents: function() {
            elements.countryselector.find('.js-country-popup-confirm').click(function() {
                let countryList = elements.countrylist[0];
                let languageList = elements.languagelist[0];
                if ($(this).hasClass('popup')) {
                    MD.cookie.setCookie('popupalreadyshowed', true, 0.25);
                    countryList = elements.countrylist[1];
                    languageList = elements.languagelist[1];
                }
                const countryUrls = $('option:selected', countryList).attr('data-url');
                const urls = JSON.parse(countryUrls);
                const language = $('option:selected', languageList).val();
                const country = $('option:selected', countryList).val();
                let url = urls[language];

                if (country === 'MC') {
                    url = app.util.appendParamToURL(url, 'country', country);
                }

                MD.cookie.setCookie('country', country, 365);

                MD.cookie.setCookie('customLocale', language, 365);

                window.location = url;
            });

            elements.countryselector.find('.js-country-popup-cancel').click(() => {
                MD.cookie.setCookie('popupalreadyshowed', true, 0.25);
                elements.flyout.country.removeClass(cssConstants.SHOWING);

                onePopupSolution.showNextFlyout(flyoutsConfig.locale);
                $('.js-country-toggle').removeClass('open');

                $('.js-header-space').removeClass('country-popup-active');
            });

            elements.countrylist.on('change', function() {
                let isPopUp = false;
                const country = this;
                if (this.id === 'countryselectPopup') {
                    isPopUp = true;
                }
                priv._createLanguageOptions(country, isPopUp);
            });

            // On resizing the window, recalculate the position of the flyout and set the correct left value
            $(window).on('resize', priv.position);

            /* TEMP flyout stuff: we switch both mobile and DT version */
            $('.menu-language .menu-link, .country-flyout .close').on('click', () => {
                $('.menu-language .menu-link').toggleClass('open');
                $('.country-flyout').toggle();
            });
        },

        _createLanguageOptions: function(countryCode, isPopUp) {
            countryCode = $(countryCode).val();
            if (countryCode) {
                let correctLangContainer = null;
                if (isPopUp) {
                    correctLangContainer = priv._getCorrectBlockForSpecSelector(elements.languagelist, 'languagePopupSelect');
                } else {
                    correctLangContainer = priv._getCorrectBlockForSpecSelector(elements.languagelist, 'languageselect');
                }
                $('option', correctLangContainer).remove();
                $.each(app.countryMapping[countryCode].locales, (index, locale) => {
                    const option = priv._getLanguageOption(locale);
                    if (option) {
                        $(correctLangContainer).append($(option));
                    }
                });
                elements.languagelist.trigger('change');
            }
        },

        _getCorrectBlockForSpecSelector: function(list, idName) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id === idName) {
                    return list[i];
                }
            }

            return null;
        },

        _getLanguageOption: function(locale) {
            if (locale) {
                return $('<option>').text(app.languages[locale]).val(locale);
            }
            return null;
        },

        _format: function(itemData) { // add country images
            const option = '<span><img src="' + window.MD.imageBaseUrl + 'flag/' + itemData.value.toLowerCase() + '.png" alt="' + itemData.slug + '" class="flag" /> ' + itemData.text + '</span>';
            return option;
        },

        _updateCountrySelectorStatus: function() {
            let countryCookie = MD.cookie.getCookie('country');
            const localeCookie = MD.cookie.getCookie('locale');
            let html = '';
            if (countryCookie && localeCookie) {
                if (app.shippingCountryMapping && app.shippingCountryMapping[countryCookie] && app.shippingCountryMapping[countryCookie].parent) {
                    countryCookie = app.shippingCountryMapping[countryCookie].parent;
                }

                html = '<img src="' + window.MD.imageBaseUrl + 'flag/' + countryCookie.toLowerCase() + '.png" alt="' + countryCookie + '"><span>' + localeCookie.substring(0, 2).toUpperCase() + '</span>';
            }

            $('.js-country-toggle').html(html);
        },

        position: function () {
            if (elements.menu.length !== 0) {
                // On the mobile split line we stop positioning the flyout dynamically and swap to a left:auto
                if (!helpers.isMobile()) {
                    const left = elements.menu.offset().left - (elements.countryFlyout.outerWidth() / 2) + (elements.menu.width() / 2);
                    elements.countryFlyout.css('left', left);
                } else {
                    elements.countryFlyout.css('left', '50%');
                }
            }
        }

    };

    return {
        onReady: function() {
            if (initialized) {
                return;
            }

            elements.countryselector = $('.country-selector');
            elements.countrylist = $('.country', elements.countryselector);
            elements.languagelist = $('.language', elements.countryselector);

            // These elements are used to position the flyout
            elements.menu = $('.menu-language');
            elements.countryFlyout = $('#js-country-flyout');

            priv.init();
            priv.bindEvents();
        },
        toggleSelector: function() {
            if (elements.countryselector) {elements.countryselector.toggle();}
        }
    };
}(window, jQuery));

$(() => {
    MD.Country.onReady();
});
