var MD = window.MD || {};

MD.minicart = function (window, $, app) {
    var elements = {};
    var priv = {

        init: function () {
            // Click goes to cart page if no lineitems
            if (!window.carthaslineitems) {
                $('#mini-cart').on('click', function (ev) {
                    var $target = $(ev.target);
                    if (!$target.hasClass("js-minicart-remove")) {
                        window.location = window.cartpageurl;
                    }
                });
            }

            var slider = $('.js-cart-slide-menu');
            if (slider.length) {
                if (MD.utils.isMobile()) {
                    priv.slider = new MobileMinicartSlideMenu();
                } else {
                    priv.slider = new SlideMenu(slider.get(0), jQuery);
                }

                priv.bindEvents();
            }

        },

        bindEvents: function () {
            var $body = $('body');

            if (MD.utils.isMobile()) {
                $body.on("click", '.js-minicart', function (e) {
                    e.preventDefault();
                    window.location.href = window.cartpageurl;
                });

                $body.on("click", '.mini-cart-close-icon, .js-background-overlay', function (e) {
                    e.preventDefault();
                    if (window.carthaslineitems) {
                        priv.slider.slideUp();
                        $('#js-minicart-flyout').removeClass('show-cart');
                        $('.js-background-overlay').removeClass('active');
                    }
                });
            } else if (MD.utils.isTablet()) {
                $body
                    .on('click', '.js-cart-outside', function () {
                        if (window.carthaslineitems) {
                            if ($('#js-minicart-flyout').is(':visible')) {
                                $('#js-minicart-flyout').removeClass('show-cart');
                                $('#js-minicart-flyout').slideUp();
                            } else {
                                $('#js-minicart-flyout').addClass('show-cart');
                                $('#js-minicart-flyout').slideDown();

                            }
                        }
                    });
            } else {
                $body
                    .on("click", '.mini-cart-close-icon', function (e) {
                        e.preventDefault();
                        if (window.carthaslineitems) {
                            $('#js-minicart-flyout').removeClass('show-cart');
                            $('.js-background-overlay').removeClass('active');
                        }
                    })
                    .on("click", '.js-cart-outside', function (e) {
                        if ($(e.target).hasClass('show-cart')) {
                            $('#js-minicart-flyout').removeClass('show-cart');
                            $('.js-background-overlay').removeClass('active');
                        }
                    })
                    .on("click", '.js-background-overlay', function (e) {
                        $('#js-minicart-flyout').removeClass('show-cart');
                        $('.js-background-overlay').removeClass('active');
                    })
                    .on('click', '.js-minicart', function () {
                        $('#js-minicart-flyout').addClass('show-cart');
                        $('.js-background-overlay').addClass('active');
                    });
                // tablet
                $('.js-minicart').on('click', function () {
                    if (window.carthaslineitems) {
                        $('#js-minicart-flyout').addClass('show-cart');
                        $('.js-background-overlay').addClass('active');
                    }
                });
            }

            $body.on("click", '.js-minicart-remove', function (e) {
                e.preventDefault();

                var item = $(this);
                var gtagData = item.data("gtag"),
                    currency = item.data("currency");
                if (typeof gtagData != 'undefined' && gtagData != null && gtagData != '') {
                    priv.initGTMRemoveFromCartEvent(gtagData, currency);
                }

                priv.removeProduct(item);
                return false;
            });
        },

        update: function (postdata) {
            $.ajax({
                url: app.util.ajaxUrl(Urls.addProduct),
                data: postdata,
                success: function (response) {
                    $('.js-headercheckout').fadeIn();
                    priv.show(response);
                }
            });

        },

        show: function (data) {
            window.carthaslineitems = true;
            if (MD.utils.isMobile()) {
                $('.js-cart-outside').html($(data));
                priv.init();
                priv.slider.slideDown();
                $('.js-headercheckout').fadeOut();
            } else {
                $('.js-cart-outside').replaceWith(data);
                new SlideMenu($('#mini-cart'), jQuery);
                $('#js-minicart-flyout').addClass('show-cart');
                $('.js-background-overlay').addClass('active');
            }
        },

        removeProduct: function (target) {
            $.ajax({
                url: app.util.ajaxUrl(Urls.addProduct),
                data: {
                    source: 'minicart',
                    cartAction: 'remove',
                    pid: target.data("id")
                },
                success: function (response) {
                    if (response.indexOf('mini-cart-subtotals') != -1) {
                        $('#js-minicart-flyout').removeClass('show-cart');
                        $('.js-background-overlay').removeClass('active');
                        priv.show(response);
                    } else {
                        $('.js-headercheckout').fadeOut();
                        $('.js-cart-outside').replaceWith(response);
                        $('#js-minicart-flyout').removeClass('show-cart');
                        $('.js-background-overlay').removeClass('active');
                    }
                }
            });
        },

        initGTMRemoveFromCartEvent: function (gtagData, currency) {
            dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'currencyCode': currency,
                    'remove': {
                        'products': [{
                            'name': gtagData[0].name,
                            'id': gtagData[0].id,
                            'price': gtagData[0].price,
                            'category': gtagData[0].category,
                            'variant': gtagData[0].variant,
                            'dimension1': gtagData[0].dimension1,
                            'dimension2': gtagData[0].dimension2,
                            'dimension3': gtagData[0].dimension3,
                            'quantity': gtagData[0].quantity
                        }]
                    }
                }
            });
        },

        triggerHover: function () {
            $('#js-minicart-flyout').addClass('show-cart');
            $('.js-background-overlay').addClass('active');
        },
    };

    return {
        /**
         * Initializes the logic for the current page
         * to be called on $(document).ready
         */
        onReady: function () {
            priv.init();
        },
        update: function (postdata) {
            priv.update(postdata);
        },
        show: function (html) {
            priv.show(html);
            MD.bonusproduct.loadBonusOption();
        },
        triggerHover: function () {
            priv.triggerHover();
        }
    };
}(window, jQuery, app);

$(document).ready(function () {
    MD.minicart.onReady();
    if (MD.productDetailPage) {
        MD.productDetailPage.triggerMinicartHover(); // trigger the minicart hover on productDetailPage, which will return here if true
    }
});

function MobileMinicartSlideMenu() {
    var $overlay = $('.js-background-overlay');
    return {
        slideDown: open,
        slideUp: close,
        closeDirectly: open,
    };

    function open() {
        $('body').addClass('mini-cart-show');
        $overlay.addClass('active');
        $('.button.primary.add-to-cart').addClass('minicart-active');
    }

    function close() {
        $('body').removeClass('mini-cart-show');
        $overlay.removeClass('active');
        $('.button.primary.add-to-cart').removeClass('minicart-active');
    }
}

var SlideMenu = function (htmlObj, $) {
    var config = {},
        defaults = {
            container: $(htmlObj),
            slidingPaneSel: '.js-slide-part', // use data-slider-panel-sel in dom
            animationDuration: '200',
            closeOnOpen: '.js-slide-menu',
            interactionModel: 'hover',
            initializeEvents: 'true'
        };

    // Setting config with the default values, and then overwriting
    // with the data specific settings.
    $.extend(config, defaults, $(htmlObj).data());

    /**
     * @namespace Private methods and variables
     */
    var priv = {
        timeoutMouseIn: null,
        timeoutMouseOut: null,
        isOpen: false,
        isFading: false,
        animationDuration: 500,
        init: function () {
            config.containerSelector = "." + $(config.container)[0].className.replace(/ /g, '.');

            if (config.slidingPaneSel.startsWith("#")) {
                if ($(config.slidingPaneSel).length == 0)
                    return;
            } else {
                if ($(config.slidingPaneSel, config.container).length == 0)
                    return;
            }

            priv.animationDuration = parseInt(config.animationDuration);
            if (isNaN(priv.animationDuration)) {
                priv.animationDuration = parseInt(def.animationDuration);
            }

            if (config.initializeEvents == "true") {
                priv.bindEvents();
            }
        },
        bindEvents: function () {
            $('#wrapper').on('click', config.containerSelector, function (e) {
                // console.log("click", priv, config);
                // Do not process if it's a click on the slidingPanelSel or a child
                // cause that would close the flyout when click on the flyout
                if (e.target.id != config.slidingPaneSel && !$(config.slidingPaneSel).find(e.target).length) {
                    if (priv.isOpen) {
                        priv.slideUp();
                    } else {
                        priv.slideDown();
                    }
                    e.stopPropagation();
                }
            });
            // mouseenter(priv.slideDown).mouseleave(priv.slideUp);
        },
        closeDirectly: function () {
            priv.isOpen = false;
            if (priv.timeoutMouseIn != null) {
                clearTimeout(priv.timeoutMouseIn);
                priv.timeoutMouseIn = null;
            }
            if (priv.timeoutMouseOut != null) {
                clearTimeout(priv.timeoutMouseOut);
                priv.timeoutMouseOut = null;
            }
            $(config.slidingPaneSel, config.container).hide();
        },
        slideDown: function (e) {
            priv.isOpen = true;
            if (priv.timeoutMouseIn != null) {
                clearTimeout(priv.timeoutMouseIn);
                priv.timeoutMouseIn = null;
            }
            if (priv.timeoutMouseOut != null) {
                clearTimeout(priv.timeoutMouseOut);
                priv.timeoutMouseOut = null;
            }

            // close other slide menu's
            $(config.closeOnOpen).each(function () {
                if (this != config.container.get(0) && this.slideMenu)
                    this.slideMenu.closeDirectly();
            });

            $(document).on('click.SlideMenu', function (e) {
                if (e.target.id != config.slidingPaneSel && !$(config.slidingPaneSel).find(e.target).length && $(config.slidingPanelSel + ":visible")) {
                    priv.slideUp();
                }
            });

            var time = priv.isFading ? priv.animationDuration + 10 : 0;
            priv.timeoutMouseIn = setTimeout(function () {
                priv.isFading = true;

                if (config.slidingPaneSel.startsWith("#")) {
                    $(config.slidingPaneSel).slideDown(priv.animationDuration, function () {
                        priv.isFading = false;
                    });
                } else {
                    $(config.slidingPaneSel, config.container).slideDown(priv.animationDuration, function () {
                        priv.isFading = false;
                    });
                }
            }, time);
        },

        slideUp: function (e) {
            $(document).off('click.SlideMenu');
            priv.isOpen = false;

            if (priv.timeoutMouseOut != null) {
                clearTimeout(priv.timeoutMouseOut);
                priv.timeoutMouseOut = null;
            }
            var time = priv.animationDuration + 10;
            priv.timeoutMouseOut = setTimeout(function () {
                priv.isFading = true;

                if (config.slidingPaneSel.startsWith("#")) {
                    $(config.slidingPaneSel).slideUp(priv.animationDuration, function () {
                        priv.isFading = false;
                    });
                } else {
                    $(config.slidingPaneSel, config.container).slideUp(priv.animationDuration, function () {
                        priv.isFading = false;
                    });
                }
            }, time);
        },
    };
    priv.init();
    return {
        closeDirectly: priv.closeDirectly,
        slideDown: priv.slideDown,
        slideUp: priv.slideUp,
    };
};

$(document).ready(function () {
    $('.js-slide-menu').each(function () {
        this.slideMenu = new SlideMenu(this, jQuery);
    });
});
