import Captcha from './captcha';
import helpers from '../utils/helpers';
import Validation from '../validation';

const selectors = {
    FORM: 'form.newsletter-form',
    EMAIL_FIELD: 'form.newsletter-form .newsletter-mail',
    NEWSLETTER_WRAP: '.js-newsletter-wrapper'
};

const newsletter = {
    isCaptachaLoaded: false,
    elements: {
        newsletterCaptcha: null
    },

    init: function() {
        if (!document.querySelector(selectors.FORM) || !document.querySelector(selectors.EMAIL_FIELD)) {
            return;
        }
        document.querySelector(selectors.EMAIL_FIELD).addEventListener('input', () => {
            if (!this.isCaptachaLoaded) {
                this.loadCaptcha();
            }
            this.isCaptachaLoaded = true;
        });
    },

    loadCaptcha: function() {
        this.elements.form = document.querySelector(selectors.FORM);
        this.elements.button = this.elements.form.querySelector('.submit');

        this.bindEvents();

        this.elements.newsletterValidation = new Validation(this.elements.form, this.elements.form.querySelectorAll('input'));
        this.elements.newsletterValidation.initialize();

        if (document.getElementById('captcha_newsletter_container')) {
            this.elements.newsletterCaptcha = new Captcha('captcha_newsletter_container', this.onSubmitNewsletter);
        }
        this.elements.captchaContainer = this.elements.newsletterCaptcha.captchaContainer;
    },

    bindEvents: function() {
        this.elements.button.addEventListener('click', (e) => {
            e.preventDefault();
            newsletter.elements.newsletterValidation.validateEach();

            if (newsletter.elements.newsletterCaptcha.captchaContainer === null) {
                newsletter.elements.newsletterCaptcha = new Captcha('captcha_newsletter_container', newsletter.onSubmitNewsletter);
            }

            if (newsletter.elements.newsletterValidation.fields[0].classList.contains('input-error')) {
                newsletter.elements.newsletterCaptcha.reset();
            } else {
                newsletter.elements.newsletterCaptcha.execute();
            }
        });
    },

    onSubmitNewsletter : function() {
        const form = newsletter.elements.form; // eslint-disable-line
        const url = form.getAttribute('action');
        const data = helpers.serialize(form, false) + '&' + newsletter.elements.button.getAttribute('name') + '=' + newsletter.elements.button.getAttribute('name');
        helpers.postAjax(url, data, (response) => {
            document.querySelector(selectors.NEWSLETTER_WRAP).innerHTML = response;
        });
    }
};

export default newsletter.init();