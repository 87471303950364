// Add to wishlist PLP functionality

import helpers from '../../utils/helpers';

const addToWishlist = {
    addToWishlistPLP: function(button) {
        const heartIcon = button.querySelector('.wishlist-product-img');
        const customerAuthenticated = UserSettings.authenticated;
        const pid = heartIcon.getAttribute('data-pid');
        const productset = heartIcon.getAttribute('data-productset');

        if (!customerAuthenticated) {
            let url = app.util.appendParamsToUrl(Urls.addToWishlistUnauthenticated, {pid: pid, productset: productset});
            url = window.location.protocol + '//' + window.location.hostname + ((url.charAt(0) === '/') ? url : ('/' + url));
            window.location.href = url;
            return;
        }

        const source = heartIcon.getAttribute('source');
        const data = {
            Quantity: '1',
            uuid: '',
            pid: pid,
            source: source,
            productset: productset
        };

        let url = app.util.appendParamsToUrl(Urls.addToWishlist, data);
        url = window.location.protocol + '//' + window.location.hostname + ((url.charAt(0) === '/') ? url : ('/' + url));

        helpers.postAjax(url, data, (wlResponse) => {
            const responseData = JSON.parse(wlResponse);
            heartIcon.setAttribute('src', responseData.src);
            heartIcon.setAttribute('title', responseData.title);
            heartIcon.setAttribute('alt', responseData.alt);
            document.querySelector('.miniwishlist .wishlist-header-img').setAttribute('src', responseData.src);
            button.removeEventListener('click', null);
            button.classList.add('js-added-to-wishlist', 'added-to-wishlist');
        });
    }
};

export default addToWishlist;