const footerMenuItem = document.querySelectorAll('.page-footer .h2');

const footerMenu = {
    init: () => {
        if (footerMenuItem.length > 0) {
            footerMenuItem.forEach((item) => {
                item.addEventListener('click', () => {
                    item.classList.toggle('active');
                });
            });
        }
    }
};

export default footerMenu.init();