import cssConstants from '../utils/cssConstants';
import helpers from '../utils/helpers';

const cache = {
    MENU_BTN: document.querySelector('.js-menu-btn'),
    MAIN_FLYOUT: document.querySelector('.js-main-flyout'),
    TOP_MENU_ITEMS: document.querySelectorAll('.touch ul.level-1 li .has-sub'),
    MOBILE_SEARCH: document.querySelector('.js-mobile-search')
};

const classes = {
    MENU_SHOWN: 'menu-shown'
};

const menu = {
    lastScrollTop: 0,
    bindEvents: function() {
        if (cache.MENU_BTN) {
            cache.MENU_BTN.addEventListener('click', this.toggleOpenMobileMenu);
        }

        if (cache.TOP_MENU_ITEMS) {
            cache.TOP_MENU_ITEMS.forEach((item) => {
                item.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (helpers.isMobile()) {
                        this.toggleOpenMobileSubmenu(item);
                    }

                    if (helpers.isTablet()) {
                        this.toggleOpenTabletMenu(item);
                    }
                });
            });

            if (helpers.isTablet()) {
                document.body.addEventListener('click', (e) => { // close flyout on click outside
                    const openFlyout = document.querySelector('.' + classes.MENU_SHOWN);
                    if (!openFlyout || e.target.closest('ul.menu-category.level-1')) {
                        return;
                    }
                    openFlyout.classList.remove(classes.MENU_SHOWN);
                });
            }
        }

        if (helpers.isMobile() && cache.MOBILE_SEARCH) {
            const scrollingElement = document.querySelector('.pt_product-details') ? document.querySelector('#wrapper') : document;

            scrollingElement.addEventListener('scroll', () => {
                this.setSearchbarVisibility(scrollingElement);
            });

            cache.MOBILE_SEARCH.addEventListener('transitionend', () => {
                cache.MOBILE_SEARCH.classList.remove('transition');
            });
        }
    },

    toggleOpenMobileMenu: () => {
        cache.MENU_BTN.classList.toggle(cssConstants.ACTIVE);
        cache.MAIN_FLYOUT.classList.toggle('mobile-active');
        document.body.classList.toggle('mobile-no-scrolling');
    },

    toggleOpenMobileSubmenu: (item) => {
        let scrollDestination = item.offsetTop;
        let itemToScroll = document.querySelector('.js-main-flyout');
        item.classList.toggle('active');

        if (item.closest('.home-menu-mobile')) {
            itemToScroll = document.querySelector('.js-home-wrapper');
            scrollDestination -= 80;
        }

        helpers.scrollTo(scrollDestination, 200, itemToScroll);
    },

    toggleOpenTabletMenu: (item) => {
        const flyout = item.parentElement.querySelector('.level-2');
        const openFlyout = document.querySelector('.' + classes.MENU_SHOWN);
        if (openFlyout) { // if flyout of another item is open, close it first
            openFlyout.classList.remove(classes.MENU_SHOWN);
        }
        if (flyout) {
            flyout.classList.add(classes.MENU_SHOWN);
        }
    },

    setSearchbarVisibility: function(scrollingElement) {
        let winY = document.querySelector('#wrapper').scrollTop;
        const mainWrapper = document.getElementById('wrapper');
        const homePage = mainWrapper.classList.contains('pt_storefront');

        if (scrollingElement === document) {
            winY = document.documentElement.scrollTop;
        }

        if (winY > this.lastScrollTop && winY > 0) { // scroll direction is down
            cache.MOBILE_SEARCH.classList.add('mobile-search--invisible');
            cache.MOBILE_SEARCH.classList.add('transition');
            if (homePage !== null) {
                document.body.classList.add('search-is-active');
            }
        } else {
            cache.MOBILE_SEARCH.classList.remove('mobile-search--invisible');
            cache.MOBILE_SEARCH.classList.add('transition');
            document.body.classList.remove('search-is-active');

            if (document.querySelector('.js-initially-hide-mobile-search')) {
                document.querySelector('.js-initially-hide-mobile-search').classList.remove('js-initially-hide-mobile-search');
                helpers.setHeaderHeight();
            }
        }

        this.lastScrollTop = winY;
    }
};

menu.bindEvents();

module.exports = menu;