import cssConstants from './utils/cssConstants';
import helpers from './utils/helpers';
import checkoutHelpers from './checkout/checkoutHelpers';
import accountCountryChange from './accountCountryChange';
import phoneInputMask from './components/phoneInputMask';

const MD = window.MD || {};

const selectors = {
    STATUS_ICON: '.js-validation-status'
};

const json = {
    'js-addr-fname': 'fname',
    'js-addr-lname': 'lname',
    'js-addr-nationalid': 'nationalid',
    'js-addr-address1': 'address1',
    'js-addr-address2': 'address2',
    'js-addr-country': 'country',
    'js-addr-state': 'state',
    'js-addr-city': 'city',
    'js-addr-zip': 'zip',
    'js-addr-phone': 'phone',
    'js-addr-housenumber': 'housenumber',
    'js-addr-street': 'street',
    'js-email-address': 'email'
};
export default class Validation {
    constructor(form, fields, isNotAjaxForm) {
        this.form = form;
        this.fields = fields;
        this.isFormWithBERules = form.matches('.js-address-form') || form.matches('.js-billing');
        this.formButton = form.querySelector('button[type="submit"]');
        this.isNotAjaxForm = isNotAjaxForm || false;
        this.targetClass = null;
        this.isValid = false;
        this.isAddressHiddenAndRequired = false;
        this.isStreetHiddenAndRequired = false;
        this.elements = {
            rules: null,
            country: null,
            countryMapping: null,
            addressForm: null,
            countrySelect: null,
            errorMessages: null
        };
    };

    setRules() {
        if (!this.isFormWithBERules) {
            return;
        }

        this.elements.rules = JSON.parse(app.rules);
        this.elements.country = app.currentCountry;
        this.elements.countryMapping = app.countryMapping;
        this.elements.errorMessages = JSON.parse(app.errorMessages);
    };

    initialize() {
        this.validateOnEntry();
        this.validateOnSubmit();
    };

    isHidden(el) {
        const style = window.getComputedStyle(el);
        let isRowHidden = false;
        if (el.closest('.form-row')) {
            isRowHidden = el.closest('.form-row').classList.contains('hidden');
        }
        return ((style.display === 'none') || (style.visibility === 'hidden') || isRowHidden);
    };

    validateOnSubmit() {
        this.form.addEventListener('submit', (e) => {
            this.validateEach(e);
        });
    };

    validateEach(e) {
        let self = this;
        let invalidFields = 0;

        if (!this.isNotAjaxForm && e) {
            e.preventDefault();
        }
        self.fields.forEach(field => {
            const input = field;
            input.classList.remove('input-error');

            if (!self.isHidden(input)) {
                self.validateFields(input);
                input.classList.add('initially-validated');
            }

            if (input.classList.contains('input-error')) {
                invalidFields++;
            }

            if (invalidFields === 0) {
                self.isValid = true;
            } else {
                self.isValid = false;
            }
        });
    }

    validateOnEntry() {
        let self = this;
        this.fields.forEach(field => {
            const input = field;

            input.addEventListener('input', () => {
                if (input.classList.contains('initially-validated')) {
                    self.validateFields(input);
                }
            });

            input.addEventListener('blur', () => {
                self.validateFields(input);
                input.classList.add('initially-validated');
            });

            input.addEventListener('change', () => {
                self.validateFields(input);
                input.classList.add('initially-validated');
            });
        });
    };

    getMessages(field) {

        const messageRequired = (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages[field]) && helpers.isDefined(this.elements.errorMessages[field]['missing'])) ?
            this.elements.errorMessages[field]['missing'] :
            (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages['field']) && helpers.isDefined(this.elements.errorMessages['field']['missing'])) ?
            this.elements.errorMessages['field']['missing'] :
            'missing';

        const messageInvalid = (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages[field]) && helpers.isDefined(this.elements.errorMessages[field]['invalid'])) ?
            this.elements.errorMessages[field]['invalid'] :
            (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages['field']) && helpers.isDefined(this.elements.errorMessages['field']['invalid'])) ?
            this.elements.errorMessages['field']['invalid'] :
            'Invalid';

            const messageMinlength = (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages[field]) && helpers.isDefined(this.elements.errorMessages[field]['minlength'])) ?
            this.elements.errorMessages[field]['minlength'] :
            (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages['field']) && helpers.isDefined(this.elements.errorMessages['field']['minlength'])) ?
            this.elements.errorMessages['field']['minlength'] :
            'Invalid';

        const messageMaxlength = (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages[field]) && helpers.isDefined(this.elements.errorMessages[field]['maxlength'])) ?
            this.elements.errorMessages[field]['maxlength'] :
            (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages['field']) && helpers.isDefined(this.elements.errorMessages['field']['maxlength'])) ?
            this.elements.errorMessages['field']['maxlength'] :
            'Invalid';

        const tntAddressMessage = (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages[field]) && helpers.isDefined(this.elements.errorMessages[field]['tntaddress'])) ?
            this.elements.errorMessages[field]['tntaddress'] :
            (helpers.isDefined(this.elements.errorMessages) && helpers.isDefined(this.elements.errorMessages['field']) && helpers.isDefined(this.elements.errorMessages['field']['tntaddress'])) ?
            this.elements.errorMessages['field']['tntaddress'] :
            'Invalid';

        const messages = {
            required: messageRequired,
            regex: messageInvalid,
            minlength: messageMinlength,
            maxlength: messageMaxlength,
            tntvalidation: tntAddressMessage
        };

        return messages;
    };

    findSpecificClass(field) {
        let val = '';

        for (let i = 0; i < field.classList.length; i++) {
            val = field.classList[i];
            if (val.substring(0, 3) === 'js-') {
                this.targetClass = val;
                break;
            }
        }
    };

    validateFields(field) {
        if (this.elements.errorMessages === null) {
            this.setRules();
        }

        let regex = '';
        const isEmail = field.getAttribute('type') === 'email';
        const isCheckbox = field.getAttribute('type') === 'checkbox';
        let dateValue = field.getAttribute('type') === 'date' && field.value;
        let hasSpecificRule = false;
        let hasDefaultRules = false;
        let isRequired = field.classList.contains('required') || field.required;

        if (this.isFormWithBERules) {
            this.findSpecificClass(field);
            const selectedCountry = this.form.querySelector('select.js-addr-country').value;
            let specificRulePerCountry = this.elements.rules.rules[selectedCountry] ? this.elements.rules.rules[selectedCountry][json[this.targetClass]] : this.elements.rules.rules['default'][json[this.targetClass]];
            const defaultRules = this.elements.rules.rules.default[json[this.targetClass]];
            hasDefaultRules = typeof defaultRules !== 'undefined';
            hasSpecificRule = typeof specificRulePerCountry !== 'undefined';

            const isBillingForm = this.form.matches('.js-billing');
            const address1Rules = this.elements.rules.rules[selectedCountry] ? this.elements.rules.rules[selectedCountry][json['js-addr-address1']] : this.elements.rules.rules.default[json['js-addr-address1']]
            const streetRules = this.elements.rules.rules[selectedCountry] ? this.elements.rules.rules[selectedCountry][json['js-addr-street']] : this.elements.rules.rules.default[json['js-addr-street']]

            if (hasDefaultRules) {
                isRequired = defaultRules.required;
            }

            if (hasSpecificRule) {
                regex = new RegExp(specificRulePerCountry.regex);

                if (typeof specificRulePerCountry.required !== 'undefined') {
                    isRequired = specificRulePerCountry.required;
                }
            }

            if (isBillingForm && this.form.querySelector('.js-addr-address1').closest('.form-row').classList.contains('hidden') && address1Rules) {
                if (address1Rules.required) {
                    this.isAddressHiddenAndRequired = true;
                }
            }

            if (isBillingForm && this.form.querySelector('.js-addr-street').closest('.form-row').classList.contains('hidden') && streetRules) {
                if (streetRules.required) {
                    this.isStreetHiddenAndRequired = true;
                }
            }

            if (this.isAddressHiddenAndRequired) {
                if (field.matches('.js-addr-street') || field.matches('.js-addr-housenumber')) {
                    isRequired = true;
                }
            }

            if (this.isStreetHiddenAndRequired) {
                if (field.matches('.js-addr-address1')) {
                    isRequired = true;
                }
            }
        }

        if (isEmail) {
            regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,63}))$/);
        }

        if (this.isFormWithBERules && isRequired) {
            if (field.value.trim() === '' || field.value.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gm, "")) {
                if (field.classList.contains(this.targetClass)) {
                    this.setStatus(field, this.getMessages(json[this.targetClass]).required, 'error');
                }
            } else if (hasSpecificRule || isEmail) {
                if (!regex.test(field.value.trim())) {
                    this.setStatus(field, this.getMessages(json[this.targetClass]).regex, 'error');
                } else {
                    this.setStatus(field, null, 'success');
                }
            } else {
                this.setStatus(field, null, 'success');
            }
        } else if (!this.isFormWithBERules && isRequired) {
            if (field.value.trim() === '') {
                this.setStatus(field, Resources.REQUIRED, 'error');
            } else if (isEmail) {
                if (!regex.test(field.value.trim())) {
                    this.setStatus(field, Resources.INVALID_EMAIL, 'error');
                } else {
                    this.setStatus(field, null, 'success');
                }
            } else if (isCheckbox && !field.checked && isRequired) {
                this.setStatus(field, Resources.REQUIRED, 'error');
            } else {
                this.setStatus(field, null, 'success');
            }
        } else {
            if (dateValue && !(helpers.inDateRange(dateValue))) {
                this.setStatus(field, Resources.VALIDATE_DATE, 'error');
            } else if (dateValue) {
                this.setStatus(field, null, 'success');
            }
        }
    };

    setStatus(field, message, status) {
        const statusIcon = field.parentElement.querySelector(selectors.STATUS_ICON);
        const errorMessage = field.parentElement.querySelector('.error-msg');
        if (statusIcon) {
            statusIcon.classList.remove(cssConstants.HIDDEN);
        }

        if (status === "success") {
            if (statusIcon) {
                statusIcon.classList.remove(cssConstants.INVALID);
            }

            if (errorMessage) {
                errorMessage.innerText = "";
            }
            field.classList.remove('input-error');
            if (this.formButton) {
                checkoutHelpers.enableButton(this.formButton);
            }
        }

        if (status === "error") {
            if (statusIcon) {
                statusIcon.classList.add(cssConstants.INVALID);
            }

            if (field.parentElement.querySelector('.error-msg')) {
                field.parentElement.querySelector('.error-msg').innerText = message;
            }
            field.classList.add('input-error');

            if (this.formButton) {
                checkoutHelpers.disableButton(this.formButton);
            }

            if (form && form.querySelector('.checkout__shipping-form-content')) {
                if (this.isHidden(form.querySelector('.checkout__shipping-form-content'))) {
                    checkoutHelpers.showTheForm();
                }
            }
        }
    };
}

const form = document.querySelector('.js-address-form');

if (form && form.querySelector('.checkout__shipping-form-content')) {
    const fields = form.querySelector('.checkout__shipping-form-content').querySelectorAll('input, select');
    const validator = new Validation(form, fields);
    validator.initialize();
}

if (document.querySelector('.js-checkout-guest-form')) {
    const guestForm = document.querySelector('.js-checkout-guest-form');
    const guestFormFields = guestForm.querySelectorAll('input');
    const guestFormValidator = new Validation(guestForm, guestFormFields);
    guestFormValidator.initialize();
}

const loginForm = document.querySelector('.js-checkout-login-form');

if (loginForm) {
    const loginFormFields = loginForm.querySelectorAll('input');
    const loginFormValidator = new Validation(loginForm, loginFormFields);
    loginFormValidator.initialize();
}

const couponCodeWrapper = document.querySelector('.js-coupon-code-wrapper');

if (couponCodeWrapper) {
    const couponCodeFields = couponCodeWrapper.querySelectorAll('input');
    const couponCodeValidator = new Validation(couponCodeWrapper, couponCodeFields);
    couponCodeValidator.initialize();
}

MD.addressValidate = function(window) {
    let addNewAddressFormValidator;
    function initValidation() {
        const addNewAddressForm = document.querySelector('.js-address-form');
        const addNewAddressFormFields = addNewAddressForm.querySelectorAll('input');
        addNewAddressFormValidator = new Validation(addNewAddressForm, addNewAddressFormFields, true);
        addNewAddressFormValidator.initialize();
        phoneInputMask.init();
    }
    return {
        init: function() {
            initValidation();
            accountCountryChange.init();
        },
        validator: function() {
            return addNewAddressFormValidator;
        },
        isValid: function() {
            return addNewAddressFormValidator.isValid;
        }
    }
}(window);

// init validations for Quiz email field (Page Designer)
const quizForm = document.querySelectorAll('.js-quiz-subscribe-form');

if (quizForm) {
    quizForm.forEach(function (form) {
        const quizFormFields = form.querySelectorAll('input');
        const quizFormValidator = new Validation(form, quizFormFields);
        quizFormValidator.initialize();
    });
}
