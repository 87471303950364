import cssConstants from '../utils/cssConstants';
import onePopupSolution, {flyoutsConfig} from './onepopupsolution';
import menu from './menu';

const cache = {
    LOGIN_ICON: document.querySelector('.js--iconfont-lady-icon'),
    LOGIN_ICON_MOBILE: document.querySelector('.js--mobile-iconfont-lady-icon'),
    CLOSE_ICON: document.querySelector('.js-user-authentication-cancel'),
    LOGIN_CONTENT: document.querySelector('.js-user-authentication'),
    LOGIN_BTN: document.querySelector('.js-user-authentication-login'),
    REGISTER_BTN: document.querySelector('.js-user-authentication-register'),
    NOTIFICATION_NUMBER: document.querySelector('.js--user-login-icon__notification'),
    ACCOUNT_FLYOUT: document.querySelector('.js-flyout-authentication'),
    HEADER_SPACE: document.querySelector('.js-header-space'),
    HEADER: document.querySelector('.js-header-total'),
    MENU: document.querySelector('.js-main-flyout')
};

function accountFlyout() {
    if (cache.LOGIN_CONTENT) cache.LOGIN_CONTENT.classList.add(cssConstants.SHOWING);
    if (cache.ACCOUNT_FLYOUT) cache.ACCOUNT_FLYOUT.classList.add(cssConstants.SHOWING);
    if (cache.HEADER_SPACE) cache.HEADER_SPACE.style.height = cache.HEADER.offsetHeight + 'px';
}

const headerLogin = {
    init: function() {
        if (!cache.LOGIN_ICON) {
            return;
        }

        if (cache.NOTIFICATION_NUMBER && !MD.cookie.getCookie('hideNotificationNumber')) {
            cache.NOTIFICATION_NUMBER.classList.add(cssConstants.SHOW_BLOCK);
        }

        cache.LOGIN_ICON.addEventListener('mouseover', () => {
            accountFlyout();
        });

        cache.LOGIN_ICON.addEventListener('focus', () => {
            accountFlyout();
        });

        cache.LOGIN_ICON_MOBILE.addEventListener('click', () => {
            accountFlyout();
            if (cache.MENU.classList.contains('mobile-active')) {
                menu.toggleOpenMobileMenu();
            }
        });

        if (cache.CLOSE_ICON) {
            cache.CLOSE_ICON.addEventListener('click', () => {
                cache.HEADER_SPACE.style.height = 'auto';
                cache.LOGIN_CONTENT.classList.remove(cssConstants.SHOWING);
                MD.cookie.setCookie('loginPopupShown', true, 3650);
                hideNotificationNumber();
                onePopupSolution.showNextFlyout(flyoutsConfig.account);
            });
        }

        if (cache.LOGIN_BTN) {
            cache.LOGIN_BTN.addEventListener('click', () => {
                MD.cookie.setCookie('loginPopupShown', true, 3650);
            });
        }

        if (cache.REGISTER_BTN) {
            cache.REGISTER_BTN.addEventListener('click', () => {
                MD.cookie.setCookie('loginPopupShown', true, 3650);
            });
        }

        document.addEventListener('click', (e) => {
            const target = e.target;

            if (!target.classList.contains('js--iconfont-lady-icon') && !target.closest('.js-user-authentication') && cache.LOGIN_CONTENT && cache.LOGIN_CONTENT.classList.contains(cssConstants.SHOW_BLOCK) && !target.classList.contains('js--mobile-iconfont-lady-icon')) {
                cache.LOGIN_CONTENT.classList.remove(cssConstants.SHOW_BLOCK);
                hideNotificationNumber();
            }
        });

        const hideNotificationNumber = function () {
            cache.NOTIFICATION_NUMBER.classList.remove(cssConstants.SHOW_BLOCK);
            MD.cookie.setCookie('hideNotificationNumber', true, 3650);
        };
    }
};

export default headerLogin.init();