import cssConstants from '../utils/cssConstants';

const classes = {
    WRAPPER_CLASS: 'custom-select',
    BUTTON_CLASS: 'custom-select__button',
    LIST_CLASS: 'custom-select__list'
};

export default class CustomSelect {
    constructor(elem, settings) {
        this.selectElement = null;
        this.customSelect = null;
        this.customSelectButton = null;
        this.customSelectList = null;

        this.init(elem, settings);
        this.reInit = () => {
            this.destroy(elem.parentNode);
            this.defineElements(elem);
        };
    }

    init(elem, settings) {
        this.selectElement = elem;
        if (settings) {
            this.defineElements(elem, settings);
        } else {
            this.defineElements(elem);
        }
        this.closeOnClickOutside();
    }

    defineElements(el, settings) {
        const parentContainer = document.createElement('div');
        parentContainer.classList.add(classes.WRAPPER_CLASS);
        this.customSelect = parentContainer;

        const customSelectButton = document.createElement('button');
        customSelectButton.classList.add(classes.BUTTON_CLASS);
        this.customSelectButton = customSelectButton;

        customSelectButton.addEventListener('click', (e) => {
            const openDropdown = document.querySelector('.' + classes.WRAPPER_CLASS + '.' + cssConstants.ACTIVE);

            if (openDropdown && !e.target.closest('.' + classes.WRAPPER_CLASS + '.' + cssConstants.ACTIVE)) {
                openDropdown.classList.remove(cssConstants.ACTIVE);
            }

            parentContainer.classList.toggle(cssConstants.ACTIVE);

            const openEvent = new Event('selectOpen');
            this.selectElement.dispatchEvent(openEvent);
        });

        const customSelect = document.createElement('ul');
        customSelect.classList.add(classes.LIST_CLASS);
        this.customSelectList = customSelect;

        this.createStructure(el, settings);
    }

    createStructure(el, settings) {
        let optionLabelArray = null;

        if (settings && settings.customClass) {
            this.customSelectList.className += (' ' + settings.customClass);
        }

        if (settings && settings.optionLabel) {
            optionLabelArray = settings.optionLabel();
        }

        el.parentNode.replaceChild(this.customSelect, el);
        this.customSelect.append(this.customSelectButton, el, this.customSelectList);

        setTimeout(() => {
            const options = el.children;
            this.populateList(options, optionLabelArray);
            this.updateSelection();
        }, 1);
    }

    populateList(options, optionLabelArray) {
        for (let i = 0; i < options.length; i++) {
            const newItem = document.createElement('li');
            newItem.innerText = options[i].innerText;
            this.customSelectList.appendChild(newItem);

            if (optionLabelArray) {
                newItem.innerHTML = optionLabelArray[i];
            }

            if (options[i].disabled) {
                newItem.classList.add(cssConstants.DISABLED);
            }

            if (options[i].selected) {
                newItem.classList.add(cssConstants.SELECTED);
                this.customSelectButton.innerHTML = newItem.innerHTML;
            }

            if (options[i].classList.value.length > 0) {
                newItem.classList.add(options[i].classList.value);
            }

            if (options[i].getAttribute('data-notifymetype')) {
                newItem.setAttribute('data-notifymetype', options[i].getAttribute('data-notifymetype'));
            }

            if (options[i].getAttribute('data-variantid')) {
                newItem.setAttribute('data-variantid', options[i].getAttribute('data-variantid'));
            }
        }
    }

    destroy(el) {
        el.replaceWith(this.selectElement);
    }

    updateSelection() {
        const listItems = this.customSelectList.querySelectorAll('li');

        for (let i = 0; i < listItems.length; i++) {
            listItems[i].addEventListener('click', () => {
                if (!listItems[i].classList.contains(cssConstants.SELECTED) && !listItems[i].classList.contains(cssConstants.DISABLED)) {
                    let c = 0;
                    while (c < listItems.length) {
                        listItems[c++].classList.remove(cssConstants.SELECTED);
                    }

                    this.selectElement.value = this.selectElement.getElementsByTagName('option')[i].value;
                    this.customSelectButton.innerHTML = listItems[i].innerHTML;
                    listItems[i].classList.add(cssConstants.SELECTED);
                    const selectChangeEvent = new Event('change');
                    this.selectElement.dispatchEvent(selectChangeEvent);
                }

                this.customSelect.classList.toggle(cssConstants.ACTIVE);
            });
        }
    }

    closeOnClickOutside() { // eslint-disable-line
        document.addEventListener('click', (e) => {
            e.stopImmediatePropagation();
            const isWrapper = e.target.matches('.' + classes.WRAPPER_CLASS);
            const isButton = e.target.matches('.' + classes.BUTTON_CLASS);
            const openDropdown = document.querySelector('.' + classes.WRAPPER_CLASS + '.' + cssConstants.ACTIVE);

            if (!isWrapper && !isButton && openDropdown) {
                openDropdown.classList.remove(cssConstants.ACTIVE);
            }
        });
    }
}
