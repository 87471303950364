import cssConstants from '../utils/cssConstants';

const zoom = {
    init: function(el) {
        if (el.classList.contains(cssConstants.ZOOMED)) {
            return;
        }
        el.addEventListener('mousemove', (e) => {
            if (!el.classList.contains(cssConstants.ZOOMED)) {
                return;
            }
            const width = e.currentTarget.offsetWidth;
            const height = e.currentTarget.offsetHeight;

            const mouseX = e.offsetX;
            const mouseY = e.offsetY;

            const bgPosX = (mouseX / width * 100);
            const bgPosY = (mouseY / height * 100);

            e.currentTarget.style.backgroundPosition = `${bgPosX}% ${bgPosY}%`;
        });
    }
};

export default zoom;