import helpers from '../utils/helpers';
import Captcha from './captcha';
import cssConstants from '../utils/cssConstants';
import popup from '../popups';
import Validation from '../validation';

const selectors = {
    CONTACT_US_FORM: '.js-contact-us-form',
    CONTACT_US_BTN: '.js-contact-form-button',
    RECAPTCHA_RESPONSE: '.g-recaptcha-response',
    CONTACT_US_POPUP: '.js-popup-contactus',
    RESULT_SUCCESS: '.js-contactus-result-success',
    RESULT_ERROR: '.js-contactus-result-error',
    RESULT_NOT_VALID: '.js-contactus-result-notvalid'
};

const contactUs = {
    contactUsCaptcha: null,
    contactUsValidation: null,
    init: function() {
        if (document.querySelector(selectors.CONTACT_US_FORM)) {
            this.bindEvents();
            this.initCaptcha();

            this.contactUsValidation = new Validation(document.querySelector(selectors.CONTACT_US_FORM), document.querySelector(selectors.CONTACT_US_FORM).querySelectorAll('input, select, textarea'));
            this.contactUsValidation.initialize();
        }
    },

    initCaptcha: function() {
        this.contactUsCaptcha = new Captcha('captcha_contactus_container', this.submitContactUs);
    },

    bindEvents: function() {
        document.querySelector(selectors.CONTACT_US_BTN).addEventListener('click', (e) => {
            e.preventDefault();
            this.contactUsValidation.validateEach();
            if (this.contactUsValidation.isValid) {
                if (this.contactUsCaptcha && this.contactUsCaptcha.captchaContainer !== null) {
                    this.contactUsCaptcha.execute();
                } else {
                    this.initCaptcha();
                    this.contactUsCaptcha.execute();
                }
            }
        });
    },

    showMessage: (isSuccess) => {
        if (isSuccess) {
            document.querySelector(selectors.RESULT_SUCCESS).classList.remove(cssConstants.HIDDEN);
            document.querySelector(selectors.RESULT_ERROR).classList.add(cssConstants.HIDDEN);
        } else {
            document.querySelector(selectors.RESULT_ERROR).classList.remove(cssConstants.HIDDEN);
            document.querySelector(selectors.RESULT_SUCCESS).classList.remove(cssConstants.HIDDEN);
        }
        popup.openPopup(document.querySelector(selectors.CONTACT_US_POPUP));
        popup.bindEvents(document.querySelector(selectors.CONTACT_US_POPUP));
    },

    submitContactUs: function() {
        return new Promise((resolve, reject) => {
            const form = document.querySelector(selectors.CONTACT_US_FORM);
            const url = form.getAttribute('action');
            const data = {
                recaptcha: form.querySelector(selectors.RECAPTCHA_RESPONSE) ? form.querySelector(selectors.RECAPTCHA_RESPONSE).value : null,
                formData: helpers.serialize(form)
            };
            helpers.postAjax(url, data, (res) => {
                if (helpers.isJSONvalid(res)) {
                    const response = JSON.parse(res);

                    if (!response.success) {
                        contactUs.showMessage(response.success);
                        reject();
                        return;
                    }
                    contactUs.contactUsCaptcha.reset();
                    contactUs.showMessage(response.success);
                    resolve();
                    helpers.resetForm(form.querySelectorAll('input, textarea, select'));
                    helpers.clearFormValidation(form.querySelectorAll('input, textarea, select'));
                    contactUs.contactUsValidation.isValid = false;
                }
            });
        });
    }
};

export default contactUs.init();