import cssConstants from '../utils/cssConstants';
import { setHeaderHeight } from '../utils/helpers'; // eslint-disable-line

export const flyoutsConfig = {
    consenttool: 'consenttool',
    locale: 'locale',
    account: 'account',
    appbanner: 'appbanner',
};

const cache = {
    HEADER_SPACE: document.querySelector('.js-header-space'),
    HEADER: document.querySelector('.js-header-total'),
    APP_BANNER_FLYOUT: document.querySelector('.js-flyout-appbanner'),
    BODY: document.documentElement || document.body,
    CONFIG: {attributes: true, childList: true, subtree: true},
    USP_BAR_WRAPPER: document.querySelector('.js-usp-bar-wrapper')
};

const isMobile = (/Mobi|Android|iPad|iPhone|iPod/i).test(navigator.userAgent) && !window.MSStream;

/**
 * Move move Smart App Banner to one popup solution
 * @param {mutations} mutations - elements
 * @param {observer} observer - MutationObserver
 */
function moveSmartBanner(mutations, observer) {
    mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
            if (document.getElementById('highstreet-smart-app-banner')) {
                if (cache.BODY.classList.contains('flyouts-visible') && !!cache.APP_BANNER_FLYOUT.getElementById('highstreet-smart-app-banner')) return;

                const smartAppBanner = document.getElementById('highstreet-smart-app-banner');
                cache.APP_BANNER_FLYOUT.append(smartAppBanner);

                onePopupSolution.appBannerClose();

                observer.disconnect();
            }
        }
    });
}

const onePopupSolution = {
    init: () => {
        onePopupSolution.initOPS();
        onePopupSolution.changeAppBannerPositionIfUSPBarSlotIsDisabled();
    },
    /**
     * Show next flyout
     * @param {string} currentElement - consenttool, locale, account or appbanner
     */
    showNextFlyout: (currentElement) => {
        if (!currentElement) currentElement = flyoutsConfig.consenttool;

        let isConsentToolInteractedWith = false;
        const consentToolCookieValue = onePopupSolution.getCookie('cookieyes-consent') ? onePopupSolution.getCookie('cookieyes-consent').split(',') : null;
        if (consentToolCookieValue) {
            for (let i = 0; i < consentToolCookieValue.length; i++) {
                const cookie = consentToolCookieValue[i];
                if (cookie === 'action:yes') {
                    isConsentToolInteractedWith = true;
                }
            }
        }

        if (!isConsentToolInteractedWith) {
            document.querySelectorAll('.js-flyout').forEach((flyoutItem) => {
                if (flyoutItem.dataset.flyout !== flyoutsConfig.consenttool) flyoutItem.classList.remove(cssConstants.SHOWING);
            });
            return;
        }

        if (cache.HEADER_SPACE) cache.HEADER_SPACE.style.height = 'auto';
        setHeaderHeight();

        const isSmartBannerChecked = !!onePopupSolution.getCookie('smart-app-banner');

        const flyouts = JSON.parse(window.localStorage.getItem('flyouts'));
        let nextFlyout;
        const currentFlyout = document.querySelector('[data-flyout=' + currentElement + ']');
        let delay = currentFlyout ? +currentFlyout.dataset.delay : 0;
        let order = currentFlyout ? +currentFlyout.dataset.flyoutOrder : 0;
        // Current flyout
        if (currentFlyout) currentFlyout.dataset.shown = true;

        document.querySelectorAll('.js-flyout').forEach((flyoutItem) => {
            flyoutItem.classList.remove(cssConstants.SHOWING);
        });

        for (let i = order; i < flyouts.length; i++) {
            const flyout = document.querySelector('[data-flyout="' + flyouts[i].name + '"]');
            const flyoutData = flyout.dataset;
            const isNextFlyout = flyoutData.flyout !== currentElement && flyoutData.shown === 'false' && order <= +flyoutData.flyoutOrder && isConsentToolInteractedWith;
            if (flyoutData.flyout === flyoutsConfig.appbanner && !isMobile) {
                order++;
                continue;
            }

            if (isNextFlyout) {
                order = +flyoutData.flyoutOrder;
                nextFlyout = document.querySelector('[data-flyout-order="' + order + '"]');
                if (nextFlyout.dataset.shown === 'false') {
                    break;
                }
            }

            order++;
        }

        if (!nextFlyout && document.body.classList.contains('flyouts-visible')) {
            document.body.classList.remove('flyouts-visible');
        }

        if (!nextFlyout || ((nextFlyout.dataset.flyout === flyoutsConfig.appbanner && !document.getElementById('highstreet-smart-app-banner') && isMobile) || (nextFlyout.dataset.flyout === flyoutsConfig.appbanner && !isMobile) || (isSmartBannerChecked && isMobile))) {
            if (cache.APP_BANNER_FLYOUT.classList.contains(cssConstants.SHOWING) && isSmartBannerChecked) cache.APP_BANNER_FLYOUT.classList.remove(cssConstants.SHOWING);
            document.body.classList.remove('flyouts-visible');
            return;
        }

        delay = +nextFlyout.dataset.delay;

        const flyout = {
            name: nextFlyout.dataset.flyout,
            order
        };

        window.localStorage.setItem('flyout', JSON.stringify(flyout));

        if (delay > 0) {
            setTimeout(() => {
                if (nextFlyout.dataset.shown === 'false') {
                    nextFlyout.classList.add(cssConstants.SHOWING);
                    if (cache.HEADER_SPACE) cache.HEADER_SPACE.style.height = cache.HEADER.offsetHeight + 'px';
                }
            }, delay);
            return;
        }

        // Next flyout
        if (nextFlyout.dataset.shown === 'false') {
            nextFlyout.classList.add(cssConstants.SHOWING);
            if (cache.HEADER_SPACE) cache.HEADER_SPACE.style.height = cache.HEADER.offsetHeight + 'px';
        }
    },
    /**
     * Get visible flyout
     */
    getVisibleFlyout: () => {
        document.querySelectorAll('.js-flyout').forEach((flyout) => {
            if (flyout.classList.contains(cssConstants.SHOWING)) {
                return {
                    name: flyout.dataset.flyout,
                    order: flyout.dataset.flyout === flyoutsConfig.consenttool ? 0 : +flyout.dataset.flyoutOrder,
                    shown: flyout.dataset.shown === 'true'
                };
            }

            return null;
        });
    },
    /**
     * Check if Smart App Banner is visible
     */
    checkIfSmartAppBannerExists: function() {
        if (!cache.APP_BANNER_FLYOUT && !isMobile) return;
        if (cache.HEADER_SPACE) cache.HEADER_SPACE.style.height = 'auto';
        const observer = new MutationObserver(moveSmartBanner);

        observer.observe(cache.BODY, cache.CONFIG);
    },
    /**
     * Close App banner event
     */
    appBannerClose: function() {
        $('#hsb-rounded-close-button').on('click', () => {
            cache.APP_BANNER_FLYOUT.classList.remove(cssConstants.SHOWING);
            onePopupSolution.showNextFlyout(cache.APP_BANNER_FLYOUT.dataset.flyout);
        });
    },
    /**
     * Close App banner event
     */
    getCookie: function(cookieName) {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },
    /**
     * Check is flyout already shown
     */
    checkIsFlyoutAlreadyShown: function() {
        const isConsentToolShown = !!onePopupSolution.getCookie('cookieyes-consent');
        const isLocaleSelected = !!onePopupSolution.getCookie('popupalreadyshowed');
        const isSmartBannerChecked = !!onePopupSolution.getCookie('smart-app-banner');
        const isCustomerAuthenticated = UserSettings.authenticated || !!onePopupSolution.getCookie('loginPopupShown');

        if (isConsentToolShown && document.querySelector('[data-flyout="' + flyoutsConfig.consenttool + '"]')) {
            const cookieValue = onePopupSolution.getCookie('cookieyes-consent').split(',');
            for (let i = 0; i < cookieValue.length; i++) {
                const cookie = cookieValue[i];
                if (cookie === 'action:') {
                    document.querySelector('[data-flyout="' + flyoutsConfig.consenttool + '"]').dataset.shown = false;
                    break;
                }
                document.querySelector('[data-flyout="' + flyoutsConfig.consenttool + '"]').dataset.shown = true;
            }
        }
        if (isLocaleSelected && document.querySelector('[data-flyout="' + flyoutsConfig.locale + '"]')) {
            document.querySelector('[data-flyout="' + flyoutsConfig.locale + '"]').dataset.shown = true;
        }
        if (isCustomerAuthenticated && document.querySelector('[data-flyout="' + flyoutsConfig.account + '"]')) {
            document.querySelector('[data-flyout="' + flyoutsConfig.account + '"]').dataset.shown = true;
        }
        if (isSmartBannerChecked && isMobile && document.querySelector('[data-flyout="' + flyoutsConfig.appbanner + '"]')) {
            document.querySelector('[data-flyout="' + flyoutsConfig.appbanner + '"]').dataset.shown = true;
        }
    },
    /**
     * Initialize one popup
     */
    initOPS: function() {
        onePopupSolution.checkIsFlyoutAlreadyShown();
        const flyoutsList = [];
        const flyout = JSON.parse(window.localStorage.getItem('flyout'));

        document.querySelectorAll('.js-flyout').forEach((flyoutItem) => {
            flyoutItem.classList.remove(cssConstants.SHOWING);
        });

        for (let i = 0; i < document.querySelectorAll('.js-flyout').length; i++) {
            const flyoutItem = document.querySelectorAll('.js-flyout')[i];
            flyoutsList.push({
                name: flyoutItem.dataset.flyout,
                order: flyoutItem.dataset.flyout === flyoutsConfig.consenttool ? 0 : +flyoutItem.dataset.flyoutOrder,
                shown: flyoutItem.dataset.flyout === flyoutsConfig.appbanner && !isMobile ? true : flyoutItem.dataset.shown === 'true'
            });
        }

        const flyouts = flyoutsList.sort((o1, o2) => {
            if (o1.order > o2.order) {
                return 1;
            }

            if (o1.order < o2.order) {
                return -1;
            }
            return 0;
        });

        const isCookieConsentEnabled = flyouts.find(item => item.name === flyoutsConfig.consenttool);

        window.localStorage.setItem('flyouts', JSON.stringify(flyouts));

        if (isCookieConsentEnabled && isCookieConsentEnabled.shown && flyout !== null) {
            onePopupSolution.showNextFlyout();
        }

        if (isMobile) {
            onePopupSolution.checkIfSmartAppBannerExists();
        }
    },

    changeAppBannerPositionIfUSPBarSlotIsDisabled: function() {
        if (!cache.USP_BAR_WRAPPER || !isMobile) return;

        if (cache.USP_BAR_WRAPPER.children.length === 0) {
            cache.HEADER_SPACE.classList.add('top-usp-bar-disabled');
        }
    }
};

onePopupSolution.init();

export default onePopupSolution;
