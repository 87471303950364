// Add to wishlist PDP functionality

import helpers from '../../utils/helpers';

const selectors = {
    PDP_FORM: '.js-pdp-form',
    PDP_SET_FORM: '.js-product-set-list form',
    ADD_TO_WISHLIST: '.js-add-to-wishlist',
    ADD_TO_WISHLIST_BTN: '.js-add-to-wishlist-btn'
};

const addToWishlist = {
    init: () => {
        document.body.addEventListener('click', (e) => {
            if (!e.target.matches(selectors.ADD_TO_WISHLIST + ', ' + selectors.ADD_TO_WISHLIST + ' *')) {
                return;
            }

            e.preventDefault();
            const wlLink = e.target.matches(selectors.ADD_TO_WISHLIST) ? e.target : e.target.closest(selectors.ADD_TO_WISHLIST);
            addToWishlist.addToWL(wlLink);
        });
    },

    addToWL: function(el) {
        const addToWishlistBtn = el.querySelector(selectors.ADD_TO_WISHLIST_BTN);
        const isCustomerAuthenticated = el.getAttribute('data-customer');
        const isSet = el.getAttribute('data-productset');
        const href = el.href;
        const pdpForm = (isSet === null || isSet === 'false') ? document.querySelector(selectors.PDP_FORM) : document.querySelectorAll(selectors.PDP_SET_FORM)[0];

        function wishlistAdd(element) {
            const data = helpers.serialize(pdpForm);

            if (data.cartAction) {
                delete data.cartAction;
            }

            if (isCustomerAuthenticated === null || isCustomerAuthenticated === 'false') {
                let url = element.getAttribute('data-loginurl');
                const pid = element.getAttribute('data-pid');
                url = app.util.appendParamsToUrl(url, {pid: pid, productset: isSet});
                url = window.location.protocol + '//' + window.location.hostname + ((url.charAt(0) === '/') ? url : ('/' + url));
                window.location.href = url;
                return;
            }

            let url = app.util.appendParamsToUrl(href, data);
            url = window.location.protocol + '//' + window.location.hostname + ((url.charAt(0) === '/') ? url : ('/' + url));
            url = helpers.appendParamToURL(url, 'format', 'ajax');

            helpers.postAjax(url, data, (response) => {
                const responseData = JSON.parse(response);
                const addToWishlistBtnImage = addToWishlistBtn.querySelector('.wishlist-product-img');

                if (pdpForm) {
                    wishlistAdd(element);
                }

                addToWishlistBtnImage.setAttribute('src', responseData.src);
                addToWishlistBtnImage.setAttribute('title', responseData.title);
                addToWishlistBtnImage.setAttribute('alt', responseData.alt);
                addToWishlistBtn.setAttribute('disabled', 'disabled');
                addToWishlistBtn.querySelector('.wl-button-text').innerHTML = responseData.title;
                document.querySelector('.miniwishlist .wishlist-header-img').setAttribute('src', responseData.src);
            });
        }

        wishlistAdd(el);
    }
};

export default addToWishlist;