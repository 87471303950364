import CustomSelect from '../../components/customSelect';
import Captcha from '../../components/captcha';
import helpers from '../../utils/helpers';
import cssConstants from '../../utils/cssConstants';
import Validation from '../../validation';

const selectors = {
    NOTIFY_ME_SUBMIT_BTN: '.js-notifyme-recaptcha',
    NOTIFY_ME_POPUP: '.js-notify-me-popup',
    NOTIFY_ME_POPUP_CURRENT: '.js-notify-me-popup.d-block',
    NOTIFY_ME_HEADER: '.js-notify-me-response-popup .notify-me-header',
    NOTIFY_ME_BODY: '.js-notify-me-response-popup .notify-me-body',
    NOTIFY_ME_RESPONSE_POPUP: '.js-notify-me-response-popup',
    NOTIFY_ME_WITHOUT_RECAPTCHA: '.js-notifyme-without-recaptcha',
    NOTIFY_ME_FORM: '.js-notify-me-form',
    VARIANT_DROPDOWN: '.js-variant-dropdown',
    DIALOG: '.js-dialog',
    DIALOG_CLOSE: '.js-dialog-close',
    MOBILE_NOTIFY_ME: '.js-mobile-variation-select .js-notify-me'
};

const notifyMe = {
    notifyMeCaptcha: null,
    notifyMeValidation: null,
    isInitiated: false,
    init: function() {
        if (this.isInitiated) {
            return;
        }
        this.isInitiated = true;
        document.body.addEventListener('click', (e) => {
            const isCloseButton = e.target.matches(selectors.DIALOG_CLOSE);
            const isNotifyMeWithoutRecaptcha = e.target.matches(selectors.NOTIFY_ME_WITHOUT_RECAPTCHA);
            const isMobileNotifyMe = e.target.matches(selectors.MOBILE_NOTIFY_ME + ', ' + selectors.MOBILE_NOTIFY_ME + ' *');
            const isNotifyMeSubmitBtn = e.target.matches(selectors.NOTIFY_ME_SUBMIT_BTN);

            if (!isCloseButton && !isNotifyMeWithoutRecaptcha && !isMobileNotifyMe && !isNotifyMeSubmitBtn) {
                return;
            }

            if (isCloseButton) {
                e.preventDefault();
                e.stopPropagation();

                e.target.closest(selectors.DIALOG).classList.remove(cssConstants.SHOW_BLOCK);
                if (document.querySelector('.js-tile-overlay-container.active')) {
                    document.querySelector('.js-tile-overlay-container.active').classList.remove('no-z-index');
                }
            }

            if (isNotifyMeWithoutRecaptcha) {
                e.preventDefault();
                const form = e.target.closest('form');
                this.notifyMeSubmit(form);
                return;
            }

            if (isMobileNotifyMe) {
                this.mobileNotifyMe(e.target);
            }

            if (isNotifyMeSubmitBtn) {
                e.preventDefault();
                this.notifyMeValidation.validateEach();

                if (this.notifyMeValidation.isValid) {
                    notifyMe.notifyMeCaptcha.execute();
                }
            }
        });

        document.body.addEventListener('submit', (e) => {
            if (!e.target.matches(selectors.NOTIFY_ME_FORM)) {
                return;
            }
            e.preventDefault();
            const button = e.target.querySelector('button');
            if (button.getAttribute('disabled') === 'disabled') {
                return;
            }

            if (button.classList.contains('js-notifyme-without-recaptcha')) {
                this.notifyMeSubmit(e.target);
            } else {
                notifyMe.notifyMeCaptcha.execute();
            }
        });
        this.notifyMeFormValidation();
    },

    notifyMeFormValidation: function() {
        if (document.querySelector(selectors.NOTIFY_ME_FORM)) {
            const form = document.querySelector(selectors.NOTIFY_ME_POPUP_CURRENT) ? document.querySelector(selectors.NOTIFY_ME_POPUP_CURRENT, selectors.NOTIFY_ME_FORM) : document.querySelector(selectors.NOTIFY_ME_FORM);
            this.notifyMeValidation = new Validation(form, form.querySelectorAll('input'));
            this.notifyMeValidation.initialize();
        }
    },

    reInitNotifyMe: function() {
        const variantDropdown = document.querySelector(selectors.VARIANT_DROPDOWN);
        let variantCustomSelect = null;
        this.notifyMeFormValidation();

        if (variantDropdown) {
            variantCustomSelect = new CustomSelect(variantDropdown, {customClass: 'variant-select'});
        }

        let areNotifyMeEventsBound = false;

        if (!areNotifyMeEventsBound && variantDropdown) {
            variantDropdown.addEventListener('selectOpen', () => {
                const ddItems = variantCustomSelect.customSelectList.children;

                for (let i = 0; i < ddItems.length; i++) {
                    if (ddItems[i].classList.contains('js-notify-me')) {
                        ddItems[i].addEventListener('click', () => { // eslint-disable-line
                            const variantColor = document.querySelectorAll('h1[itemprop=name] .description')[1].innerHTML.substr(2);
                            document.querySelectorAll('h3.title')[1].innerHTML = variantColor; // set color in notify me popup

                            const variantSize = ddItems[i].innerText.split('|')[0];
                            document.querySelector('.notify-me-variantinfo').innerHTML = variantSize; // set size in notify me popup
                            if (ddItems[i].getAttribute('data-variantid')) {
                                document.querySelector('input[name=dwfrm_notifyme_productID]').value = ddItems[i].getAttribute('data-variantid');
                            }
                            document.querySelector(selectors.NOTIFY_ME_POPUP).classList.add(cssConstants.SHOW_BLOCK);

                            if (ddItems[i].getAttribute('data-notifymetype')) {
                                const notifyMeType = ddItems[i].getAttribute('data-notifymetype');
                                document.querySelector('input[name=dwfrm_notifyme_notifymeType]').value = notifyMeType;
                                let buttonText = null;
                                if (notifyMeType === 'pre-order') {
                                    buttonText = Resources.NOTIFYME_TYPE_PREORDER;
                                } else if (notifyMeType === 'back-in-stock') {
                                    buttonText = Resources.NOTIFYME_TYPE_BACKINSTOCK;
                                }
                                if (buttonText) {
                                    document.querySelector('button[name=dwfrm_notifyme_submit]').innerText = buttonText;
                                }
                            }
                            if (!notifyMe.notifyMeCaptcha) {
                                notifyMe.notifyMeCaptcha = new Captcha('captcha_container', notifyMe.recaptchaCallback);
                                notifyMe.init();
                            } else {
                                document.querySelector('button[name=dwfrm_notifyme_submit]').classList.remove('js-notifyme-recaptcha');
                                document.querySelector('button[name=dwfrm_notifyme_submit]').classList.add('js-notifyme-without-recaptcha');
                            }
                        });
                    }
                }
                areNotifyMeEventsBound = true;
            });
        }
    },

    recaptchaCallback: function() {
        return new Promise((resolve, reject) => {
            if (grecaptcha === undefined) {
                reject();
            }

            const response = grecaptcha.getResponse(notifyMe.notifyMeCaptcha.captchaContainer);
            const form = document.querySelector('#captcha_container').closest('form');
            form.querySelector('input[name="dwfrm_notifyme_recaptcha"]').value = response;
            if (!response) {
                reject();
            }
            notifyMe.notifyMeSubmit(form);
        });
    },

    notifyMeSubmit: function(form) {
        if (form.querySelector('button').getAttribute('disabled') === 'disabled') {
            return false;
        }

        const url = form.getAttribute('action');
        const formData = {};

        form.querySelectorAll('input[name]').forEach((node) => {
            formData[node.name] = node.value;
        });
        formData.dwfrm_notifyme_submit = '';

        helpers.postAjax(url, formData, (res) => {
            const notifyMePopup = document.querySelector(selectors.NOTIFY_ME_POPUP_CURRENT) ? document.querySelector(selectors.NOTIFY_ME_POPUP_CURRENT) : document.querySelector(selectors.NOTIFY_ME_POPUP);
            const notifyMeResponsePopup = document.querySelector(selectors.NOTIFY_ME_RESPONSE_POPUP);
            const notifyMeBody = document.querySelector(selectors.NOTIFY_ME_BODY);

            if (!helpers.isJSONvalid(res)) {
                notifyMePopup.classList.remove(cssConstants.SHOW_BLOCK);
                notifyMeBody.innerHTML = '<p class="notifyme-info">' + Resources.NOTIFYME_ERROR + '</p>';
                notifyMeResponsePopup.classList.add(cssConstants.SHOW_BLOCK);
                return;
            }
            const response = JSON.parse(res);
            const notifyMeHeader = document.querySelector(selectors.NOTIFY_ME_HEADER);

            if (response.success) {
                notifyMeHeader.innerHTML = '<h3 class="title">' + Resources.NOTIFYME_THANKYOU + '</h3>';

                if (response.result === 'existing') {
                    notifyMePopup.classList.remove(cssConstants.SHOW_BLOCK);
                    notifyMeBody.innerHTML = '<p class="notifyme-info">' + Resources.NOTIFYME_EXISTINGEMAIL + '</p>';
                    notifyMeResponsePopup.classList.add(cssConstants.SHOW_BLOCK);
                } else if (response.result === 'new') {
                    notifyMePopup.classList.remove(cssConstants.SHOW_BLOCK);
                    notifyMeBody.innerHTML = '<p class="notifyme-info">' + Resources.NOTIFYME_SUCCESS + '</p>';
                    notifyMeResponsePopup.classList.add(cssConstants.SHOW_BLOCK);
                }
            } else {
                notifyMePopup.classList.remove(cssConstants.SHOW_BLOCK);
                notifyMeBody.innerHTML = '<p class="notifyme-info">' + Resources.NOTIFYME_ERROR + '</p>';
                notifyMeResponsePopup.classList.add(cssConstants.SHOW_BLOCK);
            }
        });
        if (document.querySelector('.js-tile-overlay-container.active')) {
            document.querySelector('.js-tile-overlay-container.active').classList.remove('no-z-index');
        }
        return true;
    },

    mobileNotifyMe: function(currentTarget) {
        const target = currentTarget.matches(selectors.MOBILE_NOTIFY_ME) ? currentTarget : currentTarget.closest(selectors.MOBILE_NOTIFY_ME);
        const isProductTile = target.closest('.js-product-tile');
        let variantColor = null;
        let variantSize = null;
        let variantID = null;
        let notifymeType = null;

        // get variant data
        if (isProductTile) {
            const variantData = target.querySelector('.js-variant-data');
            variantColor = variantData.dataset.color;
            variantSize = variantData.dataset.size;
            variantID = variantData.dataset.variantid;
            notifymeType = variantData.dataset.notifymetype;
        } else {
            variantColor = document.querySelectorAll('h1[itemprop=name] .description')[1].innerHTML.substr(2);
            variantSize = target.querySelector('.m-size-value').getAttribute('data-size');
            variantID = target.querySelector('.m-size-value').getAttribute('data-variantid');
            notifymeType = target.querySelector('.m-size-value').getAttribute('data-notifymetype');
        }

        // get target popup: get the only one on PDP and the closest to e.target when product tile
        const targetPopup = isProductTile ? target.closest('.js-product-tile').querySelector(selectors.NOTIFY_ME_POPUP) : document.querySelector(selectors.NOTIFY_ME_POPUP);

        // add variant color in popup
        targetPopup.querySelectorAll('.title')[1].innerHTML = variantColor;

        // add variant size in popup
        targetPopup.querySelector('.notify-me-variantinfo').innerHTML = variantSize;

        // add variant ID in popup
        targetPopup.querySelector('input[name=dwfrm_notifyme_productID]').value = variantID;

        // add notifyme type in popup
        targetPopup.querySelector('input[name=dwfrm_notifyme_notifymeType]').value = notifymeType;

        let buttonText = null;

        if (notifymeType === 'pre-order') {
            buttonText = Resources.NOTIFYME_TYPE_PREORDER;
        } else if (notifymeType === 'back-in-stock') {
            buttonText = Resources.NOTIFYME_TYPE_BACKINSTOCK;
        }
        if (buttonText) {
            document.querySelector('button[name=dwfrm_notifyme_submit]').innerHTML = buttonText;
        }

        if (!isProductTile) {
            document.querySelector('.js-mobile-variation-select').classList.remove('visible');
        }

        // fix crossbrowser issues with z-index in nested popup + fixed positioning
        if (document.querySelector('.js-tile-overlay-container.active')) {
            document.querySelector('.js-tile-overlay-container.active').classList.add('no-z-index');
        }

        targetPopup.classList.add(cssConstants.SHOW_BLOCK);
        this.notifyMeFormValidation();
        if (!notifyMe.notifyMeCaptcha) {
            notifyMe.notifyMeCaptcha = new Captcha('captcha_container', notifyMe.recaptchaCallback);
        } else {
            notifyMe.notifyMeCaptcha.reset();
            targetPopup.querySelector('button[name=dwfrm_notifyme_submit]').classList.remove('js-notifyme-recaptcha');
            targetPopup.querySelector('button[name=dwfrm_notifyme_submit]').classList.add('js-notifyme-without-recaptcha');
        }
    }
};

export default notifyMe;