const MD = window.MD || {};

import cssConstants from './utils/cssConstants';
import helpers from './utils/helpers';

MD.fittingRoom = function (window) {

    const elements = {
        options	      : null,
        country       : null,
        switchTo      : null,
        overbust      : null,
        underbust     : null,
        hip   	      : null,
        activeUnit    : null,
        button        : null,
        error	      : null,
        temp	      : null,
        result        : null,
        subMenutItems : null
    };

    /**
     * Private methods and variables
     */
    var priv = {
        init: function () {
            elements.options      = JSON.parse(app.fittingRoom);
            elements.country      = app.currentCountryCode;
            elements.switchTo     = { 'bra' : { 'cm' : document.querySelector('span.js-fitting-room-bra-cm'), 'inch' : document.querySelector('span.js-fitting-room-bra-inch') }, 'bottom' : { 'cm' : document.querySelector('span.js-fitting-room-bottom-cm'), 'inch' : document.querySelector('span.js-fitting-room-bottom-inch') } };
            elements.overbust     = document.querySelector('select.js-fitting-room-overbust');
            elements.underbust    = document.querySelector('select.js-fitting-room-underbust');
            elements.hip 	      = document.querySelector('select.js-fitting-room-hip');
            elements.button       = { 'bra' : document.querySelector('button.js-fitting-room-save-bra') , 'bottom' : document.querySelector('button.js-fitting-room-save-bottom'),'shop_now_bra': document.querySelector('.shop-bra' ) ,'shop_now_bottom': document.querySelector('.shop-bottom' )};
            elements.error        = { 'bra' : document.querySelector('span.js-fitting-room-error-bra') , 'bottom' : document.querySelector('span.js-fitting-room-error-bottom')};
            elements.result       = { 'bra'    : { 'eu' : document.querySelector('.js-fitting-room-result-bra-eu'), 'fr' : document.querySelector('.js-fitting-room-result-bra-fr'),  'gb' : document.querySelector('.js-fitting-room-result-bra-gb'),  'us' : document.querySelector('.js-fitting-room-result-bra-us') },
                                        'bottom' : { 'eu' : document.querySelector('.js-fitting-room-result-bottom-eu'), 'fr' : document.querySelector('.js-fitting-room-result-bottom-fr'),  'gb' : document.querySelector('.js-fitting-room-result-bottom-gb'),  'us' : document.querySelector('.js-fitting-room-result-bottom-us') } };

            if (!elements.underbust || !elements.overbust || !elements.hip) {
                return false;
            }
            elements.button.shop_now_bra.classList.add(cssConstants.DISABLED);
            elements.button.shop_now_bottom.classList.add(cssConstants.DISABLED);
            elements.activeUnit = 'cm';
            elements.switchTo.bra.cm.classList.add(cssConstants.SELECTED);
            elements.switchTo.bra.inch.classList.remove(cssConstants.SELECTED);
            elements.switchTo.bottom.cm.classList.add(cssConstants.SELECTED);
            elements.switchTo.bottom.inch.classList.remove(cssConstants.SELECTED);

            priv.fillOptions(elements.activeUnit, null, 'underbust');
            priv.fillOptions(elements.activeUnit, null, 'hip');
            priv.bindEvents();
        },

        bindEvents: function() {
            //Click event when user click the Shop this now button for bras
            elements.button.shop_now_bra.addEventListener('click', () => {
                const braSize = priv.GetBraSize();
                if(!Number.isInteger(braSize) && !elements.button.shop_now_bra.classList.contains(cssConstants.DISABLED)){
                    location.replace( app.braFilterPage + '#&pmin=1.00&prefn1=size&prefv1=' + braSize.innerHTML);
                }
            });

            //Click event when user click the Shop this now button for bottom
            elements.button.shop_now_bottom.addEventListener('click', () => {
                const bottomSize = priv.GetBottomSize();
                if(!Number.isInteger(bottomSize) && !elements.button.shop_now_bottom.classList.contains(cssConstants.DISABLED)){
                    location.replace(app.bottomFilterPage + '#&pmin=1.00&prefn1=size&prefv1=' + bottomSize.innerHTML);
                }
            });

            elements.switchTo.bra.cm.addEventListener('click', (e) => {
                e.preventDefault();
                elements.activeUnit = 'cm';
                elements.switchTo.bra.cm.classList.add(cssConstants.SELECTED);
                elements.switchTo.bra.inch.classList.remove(cssConstants.SELECTED);
                priv.fillOptions(elements.activeUnit, elements.underbust.value, 'underbust');
                priv.fillOptions(elements.activeUnit, elements.overbust.value, 'overbust');
            });

            elements.switchTo.bra.inch.addEventListener('click', (e) => {
                e.preventDefault();
                elements.activeUnit = 'inch';
                elements.switchTo.bra.cm.classList.remove(cssConstants.SELECTED);
                elements.switchTo.bra.inch.classList.add(cssConstants.SELECTED);
                priv.fillOptions(elements.activeUnit, elements.underbust.value, 'underbust');
                priv.fillOptions(elements.activeUnit, elements.overbust.value, 'overbust');
            });

            elements.switchTo.bottom.cm.addEventListener('click', (e) => {
                e.preventDefault();
                elements.activeUnit = 'cm';
                elements.switchTo.bottom.cm.classList.add(cssConstants.SELECTED);
                elements.switchTo.bottom.inch.classList.remove(cssConstants.SELECTED);
                priv.fillOptions(elements.activeUnit, elements.hip.value, 'hip');
            });

            elements.switchTo.bottom.inch.addEventListener('click', (e) => {
                e.preventDefault();
                elements.activeUnit = 'inch';
                elements.switchTo.bottom.cm.classList.remove(cssConstants.SELECTED);
                elements.switchTo.bottom.inch.classList.add(cssConstants.SELECTED);
                priv.fillOptions(elements.activeUnit, elements.hip.value, 'hip');
            });

            elements.underbust.addEventListener('change', () => {
                elements.button.shop_now_bra.classList.add(cssConstants.DISABLED);
                elements.error.bra.classList.add(cssConstants.HIDDEN);
                priv.fillOptions(elements.activeUnit, null, 'overbust');

                elements.result.bra.eu.innerHTML = '-';
                elements.result.bra.fr.innerHTML = '-';
                elements.result.bra.gb.innerHTML = '-';
                elements.result.bra.us.innerHTML = '-';
            });

            elements.overbust.addEventListener('change', () => {
                if ((elements.underbust.value != -1 || elements.underbust.value != '-1' ) && ( elements.overbust.value != -1 || elements.overbust.value != '-1' ) ) {
                    priv.printBraResults(elements.underbust.value, elements.overbust.value);
                }
            });

            elements.hip.addEventListener('change', () => {
                elements.error.bottom.classList.add('hidden');

                if ( elements.hip.value != -1 || elements.hip.value != '-1' ) {
                    priv.printBottomResults( elements.hip.value );
                }else{
                    elements.button.shop_now_bottom.classList.add(cssConstants.DISABLED);
                }
            });
            if (elements.button.bra) {
                elements.button.bra.addEventListener('click', (e) => {
                    e.preventDefault();
                    let newBraSize = null;
                    switch (elements.country) {
                        case 'FR':
                            newBraSize = elements.result.bra.fr.innerHTML;
                            break;
                        case 'GB':
                            newBraSize = elements.result.bra.gb.innerHTML;
                            break;
                        case 'US':
                            newBraSize = elements.result.bra.us.innerHTML;
                            break;
                        default:
                            newBraSize = elements.result.bra.eu.innerHTML;
                    }

                    if ((newBraSize != '-' && newBraSize != '' && newBraSize != null) && (elements.underbust.value != '-1' && elements.overbust.value != '-1') ) {

                        const url = app.util.appendParamsToUrl( elements.button.bra.getAttribute('data-action'), {
                            format: 'ajax'
                        });

                        const data = 'brasize=' + newBraSize;

                        helpers.postAjax(url, data, (response) => {
                            const saveResponse = JSON.parse(response);
                            if (saveResponse.success) {
                                elements.error.bra.innerHTML = Resources.FITTINGROOM_SAVE_SUCCESS;
                            } else {
                                if (saveResponse.error == 0 || saveResponse.error == '0') {	// auth error
                                    elements.error.bra.innerHTML = Resources.FITTINGROOM_SAVE_ERROR_AUTH;
                                } else { // ajax error
                                    elements.error.bra.innerHTML = Resources.FITTINGROOM_SAVE_ERROR;
                                }
                            }
                        });
                    } else {
                        elements.error.bra.innerHTML = Resources.FITTINGROOM_SAVE_ERROR_EMPTY;
                    }
                    elements.error.bra.classList.remove(cssConstants.HIDDEN);
                });
            }

            if (elements.button.bottom) {
                elements.button.bottom.addEventListener('click', (e) => {
                    e.preventDefault();

                    var newBottomSize = null;
                    switch (elements.country) {
                        case 'FR':
                            newBottomSize = elements.result.bottom.fr.innerHTML;
                            break;
                        case 'GB':
                            newBottomSize = elements.result.bottom.gb.innerHTML;
                            break;
                        case 'US':
                            newBottomSize = elements.result.bottom.us.innerHTML;
                            break;
                        default:
                            newBottomSize = elements.result.bottom.eu.innerHTML;
                    }

                    if ( (newBottomSize != "-" && newBottomSize != "" && newBottomSize != null) && (elements.hip.val() != "-1") ) {
                        const url = app.util.appendParamsToUrl( elements.button.bottom.data('action'), {
                            format: "ajax"
                        });

                        const data = 'bottomsize=' + newBottomSize;

                        helpers.postAjax(url, data, (response) => {
                            const saveResponse = JSON.parse(response);

                            if ( saveResponse.success ) {
                                elements.error.bottom.innerHTML = Resources.FITTINGROOM_SAVE_SUCCESS;
                            } else {
                                if (saveResponse.error == 0 || saveResponse.error == '0') {	// auth error
                                    elements.error.bottom.innerHTML = Resources.FITTINGROOM_SAVE_ERROR_AUTH;
                                } else { // ajax error
                                    elements.error.bottom.innerHTML = Resources.FITTINGROOM_SAVE_ERROR;
                                }
                            }
                        });
                    } else {
                        elements.error.bottom.innerHTML = Resources.FITTINGROOM_SAVE_ERROR_EMPTY;
                    }
                    elements.error.bottom.classList.remove(cssConstants.HIDDEN);
                });
            }
        },

        fillOptions: function(unit, selected, type) {
            let options = null;
            let item = null;

            if (type === 'underbust') {
                item = elements.options.bra.underbust;
                options = '<option value="-1">'+ Resources.FITTINGROOM_UNDERBUST +'</option>';
            }

            if (type === 'hip') {
                item = elements.options.bottom;
                options = '<option value="-1">'+ Resources.FITTINGROOM_HIP +'</option>';
            }

            if (type === 'overbust') {
                item = elements.options.bra.underbust[elements.underbust.value].overbust;
                options = '<option value="-1">'+ Resources.FITTINGROOM_OVERBUST +'</option>';
            }

            for (const value in item) {
                const range = item[value];

                if (elements.activeUnit == 'cm') {
                    if ( value == selected ) {
                        options += "<option selected value='"+ value +"'>"+ range.cm.min +" - "+ range.cm.max +"</option>";
                    } else {
                        options += "<option value='"+ value +"'>"+ range.cm.min +" - "+ range.cm.max +"</option>";
                    }
                }
                else {
                    if (value == selected) {
                        options += "<option selected value='" + value + "'>"+ range.inch.min + " - " + range.inch.max + "</option>";
                    } else {
                        options += "<option value='" + value + "'>" + range.inch.min + " - " + range.inch.max + "</option>";
                    }
                }
            }

            elements[type].innerHTML = options;
        },

        printBraResults: function( under, over ) {
            const cupsize = elements.options.cupConversion[over];
            const results = elements.options.bra.underbust[under].conversions;
            priv.writeAndScaleResultText( elements.result.bra.eu, results.EU + cupsize.EU );
            priv.writeAndScaleResultText( elements.result.bra.fr, results.FR + cupsize.FR );
            priv.writeAndScaleResultText( elements.result.bra.gb, results.GB + cupsize.GB );
            priv.writeAndScaleResultText( elements.result.bra.us, results.US + cupsize.US );
            elements.button.shop_now_bra.classList.remove(cssConstants.DISABLED);
        },

        printBottomResults: function( hip ) {
            const results = elements.options.bottom[hip].conversions;
            priv.writeAndScaleResultText( elements.result.bottom.eu, results.EU );
            priv.writeAndScaleResultText( elements.result.bottom.fr, results.FR );
            priv.writeAndScaleResultText( elements.result.bottom.gb, results.GB );
            priv.writeAndScaleResultText( elements.result.bottom.us, results.US );
            elements.button.shop_now_bottom.classList.remove(cssConstants.DISABLED);
        },

        writeAndScaleResultText: function( container, text ) {
            container.innerHTML = text;
            if(text.length < 3) {
                container.style.fontSize = '24px';
            } else if(text.length == 3) {
                container.style.fontSize = '20px';
            } else if(text.length == 4) {
                container.style.fontSize = '18px';
            } else if(text.length == 5) {
                container.style.fontSize = '14px';
            } else if(text.length == 6) {
                container.style.fontSize = '12px';
            } else if(text.length == 7) {
                container.style.fontSize = '10px';
            } else {
                container.style.fontSize = '1vw';
            }
        },

        //Get the chosen bra size according to locale of client
        GetBraSize: function() {
            //Control the underbust size selected
            //When function returns integer that means some parameter not setted in the size guide
            if (elements.underbust.value == -1 || elements.overbust.value == -1) {
                return -1;
            }

            if (app.locale==='en_FR' || app.locale==='fr_FR') {
                return elements.result.bra.fr;
            } else if (app.locale==='en_US' || app.locale === 'en_CA' || app.locale === 'fr_CA') {
                return elements.result.bra.us;
            } else if (app.locale === 'en_GB'){
                return elements.result.bra.gb;
            } else {
                return elements.result.bra.eu;
            }
        },
        //Get the chosen bottom size according to locale of client
        GetBottomSize: function() {
            //Control the underbust size selected
            //When function returns integer that means some parameter not setted in the size guide
            if (elements.hip.value == -1) {
                return -1;
            }

            if (app.locale==='en_FR' || app.locale==='fr_FR') {
                return elements.result.bottom.fr;
            } else if (app.locale==='en_US' || app.locale === 'en_CA' || app.locale === 'fr_CA') {
                return elements.result.bottom.us;
            } else if (app.locale === 'en_GB') {
                return elements.result.bottom.gb;
            } else {
                return elements.result.bottom.eu;
            }
        },
	};

	return {

		/**
		 * Initializes the logic for the current page
		 * to be called on $(document).ready
		 */
		onReady: function () {
			priv.init();
		}
	}
}(window);

$(document).ready(function () {
	MD.fittingRoom.onReady();
});