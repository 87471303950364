const phoneInputMask = {
    init: function() {
        const phoneMask = document.querySelectorAll('.js-input-mask');

        if (phoneMask) {
            phoneMask.forEach((mask) => {
                const maskTotalLength = mask.innerText.length;
                const completeMaskContent = mask.innerText;
                const indexesOfSpacesInMask = [];
                const phoneInput = mask.parentElement.querySelector('.js-addr-phone');

                if (mask.innerText.indexOf(' ')) {
                    for (let i = 0; i < mask.innerText.length; i++) {
                        if (mask.innerText[i] === ' ') {
                            indexesOfSpacesInMask.push(i);
                        }
                    }
                }

                phoneInput.addEventListener('input', () => {
                    phoneInput.value = this.formatInput(phoneInput.value.replace(/\s/g, ''), indexesOfSpacesInMask);

                    const maskRemainingLength = maskTotalLength - phoneInput.value.length;
                    const maskFilledLength = maskTotalLength - maskRemainingLength;
                    const filledInput = mask.childNodes[0];
                    const remainingInput = mask.childNodes[1];

                    if (remainingInput) {
                        remainingInput.textContent = completeMaskContent.slice(maskFilledLength);
                    }
                    filledInput.textContent = completeMaskContent.slice(0, maskFilledLength);

                    if (phoneInput.value.length < 1 && mask.classList.contains('hidden')) {
                        mask.classList.remove('hidden');
                    }
                });

                const inputEvent = new Event('input', {bubbles: true, cancelable: true});
                phoneInput.dispatchEvent(inputEvent);
            });
        }
    },

    formatInput: function(input, format) {
        let formatedInput = input;

        for (let i = 0; i < formatedInput.length; i++) {
            if (format.includes(i)) {
                formatedInput = formatedInput.substring(0, i) + ' ' + formatedInput.substring(i, formatedInput.length);
            }
        }
        return formatedInput;
    }
};

export default phoneInputMask;