const cache = {
    TOOLTIP_BTN: document.querySelectorAll('.tippy-btn')
};

const tooltip = {
    init: function(content) {
        if (cache.TOOLTIP_BTN.length) {
            cache.TOOLTIP_BTN.forEach((btn) => {
                if (btn.hasAttribute('data-tippy')) {
                    this.createTooltip(btn, btn.getAttribute('data-tippy'));
                }
                if (content) {
                    this.createTooltip(btn, content);
                }
            });
        }
    },

    createTooltip: function(btn, content) {
        btn.addEventListener('mouseover', () => {
            const tooltipContent = document.createElement('div');
            tooltipContent.classList.add('tooltip-box');
            tooltipContent.innerHTML = content;

            btn.appendChild(tooltipContent);
        });

        btn.addEventListener('mouseout', function() {
            this.removeChild(document.querySelector('.tooltip-box'));
        });
    }
};

tooltip.init();

module.exports = tooltip;