'use strict';

(function (app, $) {
    
    if (!app) {
        throw "app is undefined.";
    }

    if (typeof String.prototype.startsWith != 'function') {
        // see below for better implementation!
        String.prototype.startsWith = function(str) {
            return this.indexOf(str) == 0;
        };
    }

    app.checkout.init = jQuery.noop;

    // Disabling the product tile height changes from app.js
    $.fn.syncHeight = function() {
        return this
    };

    var tmpTileInit = app.product.tile.init;
    app.product.tile.init = function() {
        tmpTileInit();
        const event = new Event('tile-init');
        document.dispatchEvent(event);
    }

    // overwriting the initAddToCart function with ours which is being called also when user selects a variant..
    if (MD.productDetailPage) {
        app.product.initAddToCart = MD.productDetailPage.initAddToCart;
    }

    app.product.init = $.noop;
    app.minicart.init = $.noop;
    app.minicart.show = $.noop;
 
    app.account.init = $.noop;

    app.bonusProductsView.init = $.noop;
    app.bonusProductsView.loadBonusOption = $.noop;

    $(document).ready(function() {
        // done stubbing app.js now init
        app.init();

        if (MD.productDetailPage) {
            MD.productDetailPage.onReady();
        }

        MD.quickview.onReady();

        $(document).trigger('appinitdone');

        // Listen scroll events and show/hide back to top button 
        MD.utils.backToTop();
    });


    // Override method from app.js:3214.
    app.util.updateStateOptions = function(countrySelect) {
        var country = $(countrySelect);
        if (country.length === 0) {
            return;
        }
        var fieldset = country.closest("fieldset");
        var previousState;
        var stateField = country.data("stateField") ? country.data("stateField") : fieldset.find("select[name$='_state']");
        if (stateField.length === 0) {
            return;
        }
        else{
        	previousState = stateField.find('option:selected').val();
        }

        if (!app.countries[country.val()]) {
            stateField.empty();
            stateField.parent().hide();
            return;
        }

        var c = app.countries[country.val()],
            arrHtml = [],
            labelSpan = fieldset.find("label[for='" + stateField[0].id + "'] span").not(".required-indicator");

        // set the label text
        labelSpan.html(c.label);

        var s;
        for (s in c.regions) {
            arrHtml.push('<option value="' + s + '">' + c.regions[s] + '</option>');
        }

        if (!c.regions || $.isEmptyObject(c.regions)) {
            stateField.empty();
            stateField.parent().hide();
            return;
        }

        fieldset.find("select[name$='_state']").parent().show();

        // clone the empty option item and add to stateSelect
        var o1 = stateField.children().first().clone();
        stateField.html(arrHtml.join("")).removeAttr("disabled");
        stateField[0].selectedIndex = 0;
        try{
        	stateField.val(previousState);
        }
        catch (e){
        	
        }
        
    };


    var fillEndlessScrollChunk = function(loadingPlaceHolder, html) {
        loadingPlaceHolder.removeClass('infinite-scroll-loading');
        loadingPlaceHolder.attr('data-loading-state', 'loaded');
        $('div.search-result-content').append(html);
    };

    var tmpSearchInit = app.search.init;
    var scrollingStateRestored = false;
    app.search.init = function() {
        // check for IE8
        if(window.pageXOffset != null) {
            if(!scrollingStateRestored) {
                $(document).ready(function() {
                    if(SitePreferences.LISTING_INFINITE_SCROLL && 'sessionStorage' in window) {
                        var current = location.href.replace(location.hash, "");
                        var cacheKey = 'scroll-cache_' + current;
                        for(var item in sessionStorage) {
                             if(item.indexOf(cacheKey) != -1) {
                                fillEndlessScrollChunk($('.infinite-scroll-placeholder:last-child'), sessionStorage[item]);
                            }
                        }
                    }
                    tmpSearchInit();
                });
                scrollingStateRestored = true;
            }
        }
    }

    app.wishlist.update = function(data, callback) {
        var url = app.util.ajaxUrl(Urls.addProduct);
        $.post(url, data, callback);};

})(app, jQuery);
