import ProductDetailPage from './pages/productDetailPage';
import CategoryPage from './pages/categoryPage';
import App from './app';
import MDapp from './md.app';
import Utils from './utils';
import Cookier from './cookie';
import Minicart from './minicart';
import Account from './account';
import Bonusproduct from './bonusproduct';
import Country from './country';
import Popups from './popups';
import Dialog from './dialog';
import gridToggle from './gridToggle';
import PerfectShape from './perfectShape';
import CountrySpecificPopup from './countrySpecificPopup';
import Quickview from './quickview';

/* TODO:
* - Add non jquery fields below
*/
import touch from './components/touch'; // keep this one first, becasue it adds class to the html tag that others below might be dependant on
import FittingRoom from './fittingRoom';
import validation from './validation';
import tooltip from './components/tooltip';
import menu from './components/menu';
import searchSuggestion from './components/searchSuggestions';
import footerMenu from './components/footerMenu';
import newsletter from './components/newsletter';
import contactUs from './components/contactUs';
import cookieBar from './components/cookiebar';
import headerLogin from './components/headerLogin';

document.onreadystatechange = function () {
    if (document.readyState == 'complete') {
        cookieBar.init();
    }
}
