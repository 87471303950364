import helpers from '../../utils/helpers';
import slider from '../../components/slider';
import notifyMe from './notifyMe';
import popup from '../../popups';
import addToWishlist from './addToWishlistPDP';
import swatchChange from './swatchChange'; // eslint-disable-line
import variantSelection from './variantSelection'; // eslint-disable-line

const cache = {
    PDP_MAIN: document.querySelector('.js-pdp-main')
};

const selectors = {
    UPDATE_IMAGES: '.js-update-images',
    PRODUCT_IMAGES: '.js-product-images',
    QUANTITY: 'select[name="Quantity"]',
    LIST_ID: 'input[name="productlistid"]',
    PRODUCT_SET: '.subProduct',
    PRODUCT_CONTENT: '.js-product-content',
    PRODUCT_VARIATIONS: '.js-product-variations',
    QUICKVIEW_POPUP: '.popup-quickview',
    TRUSTBADGE: 'trustbadge-container-'
};

const pdp = {
    init: function() {
        notifyMe.init();
        notifyMe.reInitNotifyMe();
        addToWishlist.init();
        this.trustBadgeUpdate();
    },

    updateImages: function(isQuickviewSwatch) {
        let wrapperElement = cache.PDP_MAIN;
        if (isQuickviewSwatch) {
            wrapperElement = document.querySelector(selectors.QUICKVIEW_POPUP);
        }
        const newImages = wrapperElement.querySelector(selectors.UPDATE_IMAGES);
        const imgContainer = wrapperElement.querySelector(selectors.PRODUCT_IMAGES);

        imgContainer.innerHTML = newImages.innerHTML;

        slider.init();
    },

    trustBadgeUpdate: function() {
        document.addEventListener('scroll', (e) => {
            const trustbadgeWrapper = document.querySelector(`[id^="${selectors.TRUSTBADGE}"]`);
            if (!trustbadgeWrapper) return;

            if (e.target.scrollTop > 50) {
                trustbadgeWrapper.style.opacity = 0;
            } else {
                trustbadgeWrapper.style.opacity = 1;
            }
        }, true);
    },

    reinitPDPcomponents: function(isQuickviewSwatch) {
        let progressArea = cache.PDP_MAIN;
        if (isQuickviewSwatch) {
            progressArea = document.querySelector(selectors.QUICKVIEW_POPUP);
        }
        app.progress.hide(progressArea);
        app.product.initAddThis();
        MD.productDetailPage.initAddToCart();
        if (!isQuickviewSwatch) {
            MD.productDetailPage.initRecommendations();
            MD.quickview.onReady();
        }
        if (SitePreferences.STORE_PICKUP) {
            app.storeinventory.init();
        }
        if (typeof setAddToCartButton === 'function') { setAddToCartButton(); }

        this.init();
        popup.init();
        swatchChange.init();
        variantSelection.init();

        const stockInfo = document.querySelector(selectors.PRODUCT_VARIATIONS).getAttribute('data-selected-variant');
        const notifyInfo = document.querySelector(selectors.PRODUCT_VARIATIONS).getAttribute('data-is-notify-me-active');

        if (stockInfo === 'true' || (stockInfo === 'false' && notifyInfo === 'true')) {
            document.getElementsByTagName('meta').robots.setAttribute('content', 'index, follow');
        } else if (stockInfo === 'false' && notifyInfo === 'false') {
            document.getElementsByTagName('meta').robots.setAttribute('content', 'noindex, follow');
        }
    },

    getVariationData: function(el) {
        let wrapperElement = cache.PDP_MAIN;
        if (el.closest('.popup-quickview')) {
            wrapperElement = document.querySelector('.popup-quickview');
        }
        const qtySelect = wrapperElement.querySelector(selectors.QUANTITY);
        const listID = wrapperElement.querySelector(selectors.LIST_ID);
        const productSet = el.closest(selectors.PRODUCT_SET);

        const params = {
            Quantity: (qtySelect && !isNaN(qtySelect.value)) ? qtySelect.value : '1'
        };

        if (listID) {
            params.productlistid = listID;
        }
        let target = document.querySelector(selectors.PRODUCT_CONTENT);

        if (productSet && (productSet.length > 0 && productSet.children.length > 0)) {
            target = productSet;
        }

        if (el.closest('.popup-quickview')) {
            target = el.closest('.popup-quickview').querySelector(selectors.PRODUCT_CONTENT);
        }

        params.format = 'ajax';
        let url = el.href || el.value;
        url = helpers.appendParamsToURL(url, params);

        const data = {
            url: url,
            target: target
        };
        return data;
    }
};

export default pdp;