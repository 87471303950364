import helpers from '../../utils/helpers';
import pdp from './pdp'; // eslint-disable-line
import swatchChange from './swatchChange'; // eslint-disable-line

const cache = {
    PDP_MAIN: document.querySelector('.js-pdp-main')
};

const selectors = {
    PDP_FORM: '.js-pdp-form',
    VARIATION_SELECT: '.js-variation-select',
    PID_FIELD: '.js-pid-field',
    QUICKVIEW_POPUP: '.popup-quickview',
    GIFTCARD_ADDTOCART: '.js-giftAddToCart'
};

const variantSelection = {
    init: function() {
        const variationSelect = document.querySelector(selectors.VARIATION_SELECT);
        if (variationSelect) {
            variationSelect.addEventListener('change', this.updateVariation);
        }
    },

    updateVariation: function() {
        const isQuickView = !!this.closest(selectors.QUICKVIEW_POPUP);
        const PIDfield = document.querySelector(selectors.PID_FIELD);
        if (!this.value) {
            return;
        }

        if (document.querySelector('#is_gift')) {
            const giftPid = this.querySelector('option:checked').getAttribute('product-id');
            PIDfield.value = giftPid;
            cache.PDP_MAIN.setAttribute('data-pid', giftPid);

            // update giftcard variant data in add to cart button
            let giftcardData = document.querySelector(selectors.GIFTCARD_ADDTOCART).getAttribute('data-gtagadd');
            giftcardData = JSON.parse(giftcardData)[0];
            giftcardData.id = giftPid;
            giftcardData.name = this.querySelector('option:checked').getAttribute('product-name');
            giftcardData.price = Number(this.value).toFixed(2);
            giftcardData.variant = this.querySelector('option:checked').getAttribute('giftcard-type');
            let newGiftcardData = [giftcardData];
            swatchChange.getGTMProductDetail(newGiftcardData);
            newGiftcardData = JSON.stringify(newGiftcardData);
            document.querySelector(selectors.GIFTCARD_ADDTOCART).setAttribute('data-gtagadd', newGiftcardData);
        } else {
            PIDfield.value = this.querySelector('option:checked').getAttribute('data-variantid'); // update PID in the hidden form
            app.progress.show(cache.PDP_MAIN);

            const variationData = pdp.getVariationData(this);
            const hasSwapImage = this.querySelector('option:checked').getAttribute('data-lgimg') !== null;

            helpers.getAjax((variationData.url), (data) => {
                variationData.target.innerHTML = data;
                if (hasSwapImage) {
                    pdp.updateImages(isQuickView);
                }
                pdp.reinitPDPcomponents(isQuickView);
            });
        }
    }
};

export default variantSelection;