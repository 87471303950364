/* eslint-disable */
const checkoutHelpers = {

    checkoutPage: document.querySelector('.js-checkout-page'),

    changeStep: function(step) {
        const prevStep = this.checkoutPage.getAttribute('data-checkout-step');

        if (prevStep !== step) {
            this.checkoutPage.setAttribute('data-checkout-step', step);
            window.history.replaceState({}, document.title, '?step=' + step);
        }
    },

    fillAddressFields: function (address, form) {
        for (let field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // update the state fields
            if (field === 'countryCode') {
                if (address[field] != form.querySelector('[name$="country"]').value) {
                    var event = document.createEvent('HTMLEvents');
                    event.initEvent('change', true, false);
                    form.querySelector('[name$="country"]').dispatchEvent(event);
                }
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                form.querySelector('[name$="state"]').value = address.stateCode;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            if (form.querySelector('[name$="' + field.replace('Code', '') + '"]')) {
                form.querySelector('[name$="' + field.replace('Code', '') + '"]').value = address[field];
            }

        }
    },

    disableButton: function(btn) {
        if (!btn.getAttribute('disabled')) {
            btn.setAttribute('disabled', 'disabled');
        }
    },

    enableButton: function(btn) {
        if (btn.getAttribute('disabled')) {
            btn.removeAttribute('disabled');
        }
    },

    showTheForm: function () {
        if (this.isFormHidden()) {
            if (document.querySelector('.js-addr-phone') && document.querySelector('.js-input-mask')) {
                const phoneInputValue = document.querySelector('.js-addr-phone').value;
                const phoneInputCountryCode = document.querySelector('.js-addr-phone').getAttribute('data-country-code');
                if (phoneInputValue && phoneInputValue.length > phoneInputCountryCode.length) {
                    document.querySelector('.js-input-mask').classList.add('hidden');
                } else {
                    document.querySelector('.js-input-mask').classList.remove('hidden');
                }
                const inputEvent = new Event('input', {bubbles: true, cancelable: true});
                document.querySelector('.js-addr-phone').dispatchEvent(inputEvent);
            }
            this.checkoutPage.classList.remove('checkout--hide-form');
        }
    },

    hideTheForm: function () {
        if (!this.isFormHidden()) {
            this.checkoutPage.classList.add('checkout--hide-form');
        }
    },

    isFormHidden: function () {
        return this.checkoutPage.classList.contains('checkout--hide-form');
    },

    getSelectedRadioId: function(name) {
        const el = document.getElementsByName(name);

        for (let i = 0; i < el.length; i++) {
            if (el[i].checked) {
                return el[i].id;
            }
        }
    },

    addSuccessStatus: function(wrapper, message, cb) {
        wrapper.innerHTML += '<span class="success-msg">' +
                            '<span class="success-msg__icon iconfont iconfont-check" aria-hidden="true"></span>' +
                            '<span class="success-msg__msg">' +
                            message +
                            '</span>' +
                            '</span>';
        setTimeout(() => {
            wrapper.querySelector('.success-msg').remove();
            if (cb) {
                cb();
            }
        }, 3000);
    },

    initNewAddress: function() {
        document.querySelector('.js-new-address-init').value = true;
    },

    cancelNewAddress: function() {
        document.querySelector('.js-new-address-init').value = false;
    },

    getCartProductsGTMData: function() {
        return JSON.parse(document.querySelector('.js-cart-gtm-product').getAttribute("data-cartproductsgtm"));
    },
}

module.exports = checkoutHelpers;
/* eslint-enable */