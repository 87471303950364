import cssConstants from '../utils/cssConstants';
import helpers from '../utils/helpers';

const cache = {
    SEARCH_CONTAINER: document.querySelector('.js-header-search'),
    SEARCH_CONTAINER_MOBILE: document.querySelector('.js-mobile-header-search')
};

const selectors = {
    SEARCH_FORM: 'form[name="simpleSearch"]',
    SEARCH_FIELD: 'input[name="q"]',
    SEARCH_SUGGESTIONS: '.search-suggestions'
};

const priv = {
    MOBILE_RESULTS: null,
    DESKTOP_RESULTS: null,
    IS_MOBILE: false,
    RUNNING_QUERY: null,
    CURRENT_QUERY: null
};

const searchSuggestions = {
    init: function() {
        if (!cache.SEARCH_CONTAINER || !SitePreferences.LISTING_SEARCHSUGGEST_ENABLED) {
            return;
        }
        const searchField = cache.SEARCH_CONTAINER.querySelector(selectors.SEARCH_FIELD);
        this.arrangeSearchField(searchField, cache.SEARCH_CONTAINER, false);
        this.validateSearch(document.querySelector(selectors.SEARCH_FORM));
        this.arrangeForMobileSearch(cache.SEARCH_CONTAINER_MOBILE);
        this.closeOnClickElsewhere();
    },

    arrangeSearchField: function(field, container, isMobileSearch) {
        field.addEventListener('focus', () => {
            field.setAttribute('autocomplete', 'off');
            if (isMobileSearch) {
                if (!priv.MOBILE_RESULTS) {
                    priv.MOBILE_RESULTS = document.createElement('div');
                    priv.MOBILE_RESULTS.id = 'search-suggestions';
                    priv.MOBILE_RESULTS.classList.add('search-suggestions');
                    container.append(priv.MOBILE_RESULTS);
                }
            } else if (!priv.DESKTOP_RESULTS) {
                priv.DESKTOP_RESULTS = document.createElement('div');
                priv.DESKTOP_RESULTS.id = 'search-suggestions';
                priv.DESKTOP_RESULTS.classList.add('search-suggestions');
                container.append(priv.DESKTOP_RESULTS);
            }
        });

        field.addEventListener('keyup', () => {
            priv.IS_MOBILE = isMobileSearch;

            priv.CURRENT_QUERY = field.value.trim();

            if (priv.RUNNING_QUERY == null) {
                priv.RUNNING_QUERY = priv.CURRENT_QUERY;

                setTimeout(this.suggest, 30);
            }
        });
    },

    arrangeForMobileSearch: function(mobileContainer) {
        if (!mobileContainer || mobileContainer.length === 0) {
            return;
        }

        const mobileSearchForm = mobileContainer.querySelector(selectors.SEARCH_FORM);
        const mobileSearchField = mobileSearchForm.querySelector(selectors.SEARCH_FIELD);

        this.validateSearch(mobileSearchForm);
        this.arrangeSearchField(mobileSearchField, mobileContainer, true);
    },

    suggest: function() {
        if (priv.RUNNING_QUERY !== priv.CURRENT_QUERY) {
            // update running query to the most recent search phrase
            priv.RUNNING_QUERY = priv.CURRENT_QUERY;
        }

        if (priv.RUNNING_QUERY.length < 3) {
            searchSuggestions.clearResults();
            priv.RUNNING_QUERY = null;
            priv.lastQuery = null;
            return;
        }

        if (priv.lastQuery === priv.RUNNING_QUERY) {
            priv.RUNNING_QUERY = null;
            return;
        }

        // build the request url
        let reqUrl = app.util.appendParamToURL(Urls.searchsuggest, 'q', priv.RUNNING_QUERY);
        reqUrl = app.util.appendParamToURL(reqUrl, 'legacy', 'false');

        helpers.getAjax(reqUrl, (data) => {
            const suggestionHTML = data;
            const ansLength = suggestionHTML.trim().length;

            if (ansLength === 0) {
                priv.clearResults();
            } else {
                // update the results div
                if (priv.IS_MOBILE) {
                    priv.MOBILE_RESULTS.innerHTML = suggestionHTML;
                    priv.MOBILE_RESULTS.classList.add(cssConstants.ACTIVE);
                } else {
                    priv.DESKTOP_RESULTS.innerHTML = suggestionHTML;
                    priv.DESKTOP_RESULTS.classList.add(cssConstants.ACTIVE);
                }
            }

            // record the query that has been executed
            priv.lastQuery = priv.RUNNING_QUERY;
            // reset currently running query
            priv.RUNNING_QUERY = null;

            // check for another required update (if current search phrase is different from just executed call)
            if (priv.CURRENT_QUERY !== priv.lastQuery) {
                // ... and execute immediately if search has changed while this server call was in transit
                priv.RUNNING_QUERY = priv.CURRENT_QUERY;
                setTimeout(searchSuggestions.suggest, 30);
            }
        });
    },

    closeOnClickElsewhere: function() {
        document.addEventListener('click', (e) => {
            if (!document.querySelector(selectors.SEARCH_SUGGESTIONS)) {
                return;
            }
            if (e.target.matches('.search-suggestions') || e.target.closest('.search-suggestions')) {
                return;
            }
            this.clearResults();
        });
    },

    clearResults: function() {
        if ((!priv.IS_MOBILE && !priv.DESKTOP_RESULTS) || (priv.IS_MOBILE && !priv.MOBILE_RESULTS)) {
            return;
        }

        if (priv.IS_MOBILE) {
            priv.MOBILE_RESULTS.classList.remove(cssConstants.ACTIVE);
            priv.MOBILE_RESULTS.innerHTML = '';
            return;
        }
        priv.DESKTOP_RESULTS.classList.remove(cssConstants.ACTIVE);
        priv.DESKTOP_RESULTS.innerHTML = '';
    },

    validateSearch: function(searchForm) {
        // prevent search if the value is equal to placeholder or only made of white spaces
        searchForm.addEventListener('submit', function() {
            const fieldDefault = Resources.SIMPLE_SEARCH;
            const searchKey = this.elements.q.value;

            return (!((searchKey === fieldDefault) || (searchKey.replace(/ /g, '') === '')));
        });
    }
};

searchSuggestions.init();

module.exports = searchSuggestions;