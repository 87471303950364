var MD = window.MD || {};

MD.utils = {
    string: {
        /**
        *	This method will concatanate the given arguments.
        *	Use concatenate(arg0,arg1,arg2, etc.);
        *	@arg0 is used as a concatenation character
        *	@arg1..n are concatenated
        *	null values will be ignored, so no additional whitespaces are generated.
        */
        concat: function () {
            if (arguments.length == 0) {
                return "";
            }

            var concatChar = arguments[0];
            var concatenatedString = "";

            for (var i = 1; i < arguments.length; i++) {
                if (arguments[i] != undefined && arguments[i] != null && arguments[i] != "") {
                    concatenatedString += arguments[i] + concatChar;
                }
            }
            return concatenatedString.substr(0, concatenatedString.length - 1);
        }
    },
    url: {
        querystringToObject: function(qs) {
            var querystringParams = {};
            if (qs.length > 1) {
                for (var aItKey, nKeyId = 0, aCouples = qs.substr(1).split("&") ; nKeyId < aCouples.length; nKeyId++) {
                    aItKey = aCouples[nKeyId].split("=");
                    querystringParams[decodeURIComponent(aItKey[0])] = aItKey.length > 1 ? decodeURIComponent(aItKey[1]) : "";
                }
            }
            return querystringParams;
        }
    },
    /**
         * @function
         * @description Extracts all parameters from a given query string into an object
         * @param {String} qs The query string from which the parameters will be extracted
         */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) {
            return {};
        }

        var params = {}, unescapedQS = unescape(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp("([^?=&]+)(=([^&]*))?", "g"),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },
    /**
     * @function
     * @description Returns an URI-Object from a given element with the following properties:<br/>
     * <p>protocol</p>
     * <p>host</p>
     * <p>hostname</p>
     * <p>port</p>
     * <p>path</p>
     * <p>query</p>
     * <p>queryParams</p>
     * <p>hash</p>
     * <p>url</p>
     * <p>urlWithQuery</p>
     * @param {Object} o The HTML-Element
     */
    getUri: function (o) {
        var a;
        if (o.tagName && $(o).attr("href")) {
            a = o;
        } else if (typeof o === "string") {
            a = document.createElement("a");
            a.href = o;
        } else {
            return null;
        }

        return {
            protocol: a.protocol, //http:
            host: a.host, //www.myexample.com
            hostname: a.hostname, //www.myexample.com'
            port: a.port, //:80
            path: a.pathname, // /sub1/sub2
            query: a.search, // ?param1=val1&param2=val2
            queryParams: a.search.length > 1 ? app.util.getQueryStringParams(a.search.substr(1)) : {},
            hash: a.hash, // #OU812,5150
            url: a.protocol + "//" + a.host + a.pathname,
            urlWithQuery: a.protocol + "//" + a.host + a.port + a.pathname + a.search
        };
    },
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        var c = "?";
        if (url.indexOf(c) !== -1) {
            c = "&";
        }
        return url + c + name + "=" + encodeURIComponent(value);
    },
    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {String} params a JSON string with the parameters
     */
    appendParamsToUrl: function(url, params) {
        var uri = app.util.getUri(url),
            includeHash = arguments.length < 3 ? false : arguments[2];

        var qsParams = $.extend(uri.queryParams, params);

        var result = uri.path;
        for(var key in params) {
            result = MD.utils.appendParamToURL(url, key, params[key]);
        }

        if (includeHash) {
            result += uri.hash;
        }
        if (result.indexOf("http") < 0 && result.charAt(0) !== "/") {
            result = "/" + result;
        }

        return result;
    },
    /**
     * @function
     * @description removes the parameter with the given name from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of the parameter
     */
    removeParamFromURL: function(url, parameter) {
        var urlparts = url.split('?');

        if (urlparts.length >= 2) {
            var urlBase = urlparts.shift();
            var queryString = urlparts.join("?");

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = queryString.split(/[&;]/g);
            var i = pars.length;
            while (0 > i--) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            url = urlBase + '?' + pars.join('&');
        }
        return url;
    },

    getQueryString : function(url) {
    	var urlparts = url.split('?');

        if (urlparts.length >= 2) {
            return urlparts[1];
        }

        return '';
    },

    backToTop: function() {

    	// clean unnecessary buttons on the screen
    	$('.backtotop.desktop, .backtotop.mobile').each( function(){
    		$( this ).remove();
    	});

    	// set new button
    	var btt = $('a.backtotop');

    	// there is no backtotop button
    	if ( btt.length == 0 ) {
    		return;
    	}

    	var scrollTop = $(window).scrollTop();
    	var height    = $(window).height();
    	var threshold = height / 2;

    	// show the button if page is initially scrolled down
    	if ( scrollTop > threshold ) {
    		btt.fadeIn( "slow" );
    	}

    	// listen scroll event to show/hide the button
    	$(window).scroll( function( e ) {

    		if ( $(window).scrollTop() > threshold ) {
        		btt.fadeIn( "slow" );
        	}
    		else {
    			btt.fadeOut( "slow" );
    		}
    	});
    },

    isMobile: function() {
        return window.matchMedia("screen and (max-width: 767px)").matches;
    },

    isTablet: function() {
        var userAgent = navigator.userAgent.toLowerCase();
        var isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        
        return isTablet && window.matchMedia("screen and (min-width: 768px)").matches;
    },

    detectSwipeDirection: function($el){
        $el.on("touchstart", startTouch);
        $el.on("touchmove", moveTouch);
        
        // Swipe Up / Down / Left / Right
        var initialX = null;
        var initialY = null;
        
        function startTouch(e) {
            initialX = e.touches[0].clientX;
            initialY = e.touches[0].clientY;
        };
        
        function moveTouch(e) {
            if (initialX === null) {
                return;
            }
            
            if (initialY === null) {
                return;
            }
            
            var currentX = e.touches[0].clientX;
            var currentY = e.touches[0].clientY;
            
            var diffX = initialX - currentX;
            var diffY = initialY - currentY;
            
            if (Math.abs(diffX) > Math.abs(diffY)) {
                // sliding horizontally
                if (diffX > 0) {
                    // swiped left
                    $el.trigger('swipedLeft');
                } else {
                    // swiped right
                    $el.trigger('swipedRight');
                }  
            } else {
                // sliding vertically
                if (diffY > 0) {
                    // swiped up
                    $el.trigger('swipedUp');
                } else {
                    // swiped down
                    $el.trigger('swipedDown');
                }  
            }
            
            initialX = null;
            initialY = null;
        };
    }
}
