import checkoutHelpers from '../checkout/checkoutHelpers';

window.dataLayer = window.dataLayer || [];
let currency;

const checkout = {
    /* eslint-disable */
    pushStepFiveDatalayer: function (paymentMethodName) {
        if (typeof dataLayer != 'undefined') {
            if (isPushEventNeeded(5)) {
                dataLayer.push({
                    'event': 'checkout',
                    'ecommerce': {
                        'checkout': {
                            'actionField': {
                                'step': 5,
                                'option': paymentMethodName
                            },
                            'products': checkoutHelpers.getCartProductsGTMData()
                        }
                    }
                });
            }
        }
    },

    pushCheckoutOption: function (step, option) {
        if (typeof dataLayer != 'undefined') {
            var isNeeded = true;
            if (step === 4 || step === 5) {
                isNeeded = isPushEventNeeded(step, option);
            }
            if (isNeeded) {
                dataLayer.push({
                    'event': 'checkoutOption',
                    'ecommerce': {
                        'checkout_option': {
                            'actionField': { 'step': step, 'option': option }
                        }
                    }
                });
            }
        }
    },
};

function isPushEventNeeded(step, currentOption) {
    var stepEvent = dataLayer.find(function(event) {
        if (event.event === 'checkout' && event.ecommerce.checkout.actionField.step === step) {
            return event;
        }
    });

    if ((step === 4 && stepEvent.ecommerce.checkout.actionField.option === currentOption) || (step === 5 && stepEvent)) {
        return false;
    } 
    return true;
}


const gtmtracking = {
    addToCartTracker: function(productData, val) {
        const productDataArray = [];
        // adding more than one product (wishlist add all)
        if (productData.length > 1) {
            cart.addToCart(productData);
        } else {
            // single product
            productDataArray.push(productData);
            cart.addToCart(productDataArray, val);
        }
    },
    checkout: checkout
};

const cart = {
    // Tracks product adding.
    addToCart : function(data, quantity) {
        const gtmData = product.setProductInfo(data, quantity);
        const object = {
            event: 'addToCart',
            ecommerce: {
                currencyCode: gtmData.currency,
                add: {
                    products: gtmData.products
                }
            }
        };
        dataLayer.push(object);
    }
};

const product = {
    // Getting product info from gtag.
    setProductInfo : function(productData, quantity) {
        const result = {};
        const products = [];
        for (let i = 0; i < productData.length; i++) {
            const object = {
                name: productData[i][0].name,
                id: productData[i][0].id,
                price: productData[i][0].price,
                category: productData[i][0].category,
                variant: productData[i][0].variant,
                dimension1: productData[i][0].dimension1,
                dimension2: productData[i][0].dimension2,
                dimension3: productData[i][0].dimension3,
                dimension4: productData[i][0].dimension4,
                dimension5: productData[i][0].dimension5,
                quantity: quantity ? quantity : 1
            };
            products.push(object);
            currency = productData[i][0].currency;
        }
        result.products = products;
        result.currency = currency;
        return result;
    }
};

module.exports = gtmtracking;
