var MD = window.MD || {};

MD.perfectShape = function (window, $) {
	var elements = {
		window	            : null,
		winLoc              : null,
		stepHash            : null,
		perfectShapeWrapper : null,
		perfectShape        : null,
		shapeResults        : null
	};

	/**
	 * Private methods and variables
	 */
	var priv = {
			
		init: function () {
			elements.window               =  $(window);
			elements.winLoc               =  window.location;
			elements.stepHash             =  { "1" : "shape", "2" : "space", "3" : "size" };
			elements.perfectShapeWrapper  =  $('#perfect-shape');
			
			if (elements.perfectShapeWrapper.length === 0) {
				return false;
			}
			
			elements.perfectShape = { 
				"progressStep"    	:  elements.perfectShapeWrapper.find('.progress'), 
				"shapeGuide"      	:  elements.perfectShapeWrapper.find('.shape-guide'), 
				"selectionThumb"  	:  elements.perfectShapeWrapper.find('.selection'), 
				"nextButton"      	:  elements.perfectShapeWrapper.find('.next-btn'), 
				"braSizeDropdown" 	:  elements.perfectShapeWrapper.find('.bra-size'), 
				"bandSizeSelect"  	:  elements.perfectShapeWrapper.find('#band-size'), 
				"cupSizeSelect"   	:  elements.perfectShapeWrapper.find('#cup-size'), 
				"resultShape"		:  elements.perfectShapeWrapper.find('.result-shape'),
				"saveToAccount" 	:  elements.perfectShapeWrapper.find('#save-to-account')
			},                       
					
			elements.shapeResults = {
				"perky"		:	{
					"little"	:	".push-up, .super-push-up",
					"average"	:	".plunge-balcony-perky, .plunge-perky, .push-up-perky",
					"big"	    :	".balcony, .plunge-balcony"
				},
				"athletic"	: 	{
					"little"	:	".push-up, .super-push-up",
					"average"	:	".plunge-athletic, .push-up-athletic, .plunge-balcony-athletic",
					"big"	    :	".balcony, .plunge-balcony"
				},
				"round"		:	{
					"little"	:	".push-up, .super-push-up",
					"average"	:	".plunge-balcony-perky, .plunge-perky, .push-up-perky",
					"big"	    :	".balcony, .plunge-balcony"
				}
			}
			
			priv.showCurrentStep();
			priv.bindEvents();
		},
		
		bindEvents: function () {
			
			elements.window.on('hashchange', function () {
				priv.showCurrentStep();
	        });
			
	        elements.perfectShape.progressStep.on('click', function () {
	            if (elements.winLoc.hash.indexOf('step') != -1) {
	                var currentStepNo = elements.winLoc.hash.substring(5, 6),
	                 	stepToGoNo = parseInt($(this).data('step'));
	                
	                if (currentStepNo > stepToGoNo) {
	                    var hashToGo = elements.winLoc.hash.replace(currentStepNo, stepToGoNo);
	                    elements.winLoc.hash = hashToGo;
	                }
	            }
	        });
	        
	        elements.perfectShape.selectionThumb.on('click', function () {
	        	var $this = $(this),
	        	 	thumbId = $this.attr('id'),
	        		$closestShapeGuide = $this.closest('.shape-guide');
	        	
	        	$closestShapeGuide.find('.selection.active, .details-circle.active').removeClass('active');
	            $this.addClass('active').find('.details-circle').addClass('active');
	            $closestShapeGuide.find('.details').hide();
	            $('.details.' + thumbId).show();
	            $('html, body').animate({
	        		scrollTop: $('.details.' + thumbId).offset().top - 30
	        	}, 800);
	            
	     		var currentStep = $this.closest('.shape-guide').data('step'),
         			currentHashName = elements.stepHash[currentStep];      	
         			
	     		priv.setHash(currentHashName, thumbId);
     		});
	        
	        elements.perfectShape.nextButton.on('click', function () {
	            var currentStepNo = parseInt($(this).data('step')),
	             	stepToGoNo = currentStepNo + 1,
	             	hashToGo = elements.winLoc.hash.replace(currentStepNo, stepToGoNo);
	            
	            elements.winLoc.hash = hashToGo;
	        });
	        
	        elements.perfectShape.braSizeDropdown.on('change', function () {
	        	var $this = $(this),
	        		$nextBtn = $this.closest('.shape-guide').find('.next-btn');
	        	
	        	if ($this.val() !== '' && $this.siblings('.bra-size').val() !== '') {
	        		$nextBtn.removeClass('disabled');
	        		
	        		var currentStep = $this.closest('.shape-guide').data('step'),
	             		currentHashName = elements.stepHash[currentStep],        	
	             		size = elements.perfectShape.bandSizeSelect.val() + elements.perfectShape.cupSizeSelect.val();
	        		
	        		priv.setHash(currentHashName, size);
	          
	        	} else {
	        		$nextBtn.addClass('disabled');	
	        	}
	        });
	        
	        elements.perfectShape.saveToAccount.on('click', function (e) {
	        	e.preventDefault();
	        	
	        	var shapeResults = $(this).data('shapeResults'),
        			braSize = $(this).data('braSize'),      
	        		paramsUrl = Urls.perfectShapeSaveShapes;
	        	
	        	if (shapeResults != undefined) {
	        		paramsUrl = app.util.appendParamToURL(paramsUrl, 'shaperesults', shapeResults);
	        	}
	        	
	        	if (braSize != undefined) {
	        		paramsUrl = app.util.appendParamToURL(paramsUrl, 'brasize', braSize);
	        	}
	        	
	        	elements.winLoc.href = paramsUrl;
	        });
	        
		},
		
		goToStep: function (stepToGo) {
            elements.perfectShape.shapeGuide.hide();
            elements.perfectShape.progressStep.removeClass('active');
            $('.' + stepToGo).show();
            $('.progress.' + stepToGo).addClass('active');
        },
        
        fillDropdowns: function (bandSize, cupSize) {
        	elements.perfectShape.bandSizeSelect.val(bandSize);
        	elements.perfectShape.cupSizeSelect.val(cupSize);
        	elements.perfectShape.nextButton.removeClass('disabled');
        },
        
        showResults: function (shape, space, size) {
        	elements.perfectShape.resultShape.not('.hide').addClass('hide');
        	
        	var resultingShapes = elements.shapeResults[shape][space];
        	
        	$(resultingShapes).removeClass('hide');
        	$(resultingShapes).find('a').each(function () {
        		var $this = $(this),
        		 	currentHref = $this.attr('href'),
        		 	newHref = currentHref;
        		
        		if(currentHref.indexOf('#') != -1) {
        			newHref = currentHref.substring(0, currentHref.indexOf('#'));
        		}
        		
        		var	hrefToGo = newHref + '#prefn1=size&pmin=1&prefv1=' + size;
        		
        		$this.attr('href', hrefToGo);
        	});
        	
        	elements.perfectShape.saveToAccount.data('shapeResults', resultingShapes.split(', ').join('_').split('.').join(''));
        	elements.perfectShape.saveToAccount.data('braSize', size);
        },
        
        showCurrentStep: function () {
            var hashParams = (elements.winLoc.hash.length > 1) ? MD.utils.getQueryStringParams(elements.winLoc.hash.substr(1)) : {};
            for (var key in hashParams) {
                if (hashParams.hasOwnProperty(key)) {
                    if (key.indexOf('step') != -1) {
                        priv.goToStep(key);
                        var size = hashParams.size;
                        if (key.indexOf('step3') != -1 && size != undefined) {
                        	priv.fillDropdowns(size.substring(0,2), size.substring(2));
                        } else if (key.indexOf('step4') != -1) {
                        	priv.showResults(hashParams.shape, hashParams.space, hashParams.size);
                        }
                    }
                    return false;
                } 
            }
        },
        
        setHash: function (hashName, hashValue) {
        	var hashParams = (elements.winLoc.hash.length > 1) ? MD.utils.getQueryStringParams(elements.winLoc.hash.substr(1)) : {},
            	currentHash = elements.winLoc.hash,
            	hashToGo = currentHash;
  	          	
            if (currentHash.indexOf(hashName) != -1) {
            	hashToGo = currentHash.replace(hashParams[hashName], hashValue);
            } else {
            	hashToGo += '&' + hashName + '=' + hashValue;
            } 
            
            elements.winLoc.hash = hashToGo;
        }
        
	};

	return {

		/**
		 * Initializes the logic for the current page
		 * to be called on $(document).ready
		 */
		onReady: function () {
			priv.init();
		}
	};
}(window, jQuery);

$(document).ready(function () {
	MD.perfectShape.onReady();
});
