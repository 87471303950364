import Swiper from 'swiper'; //eslint-disable-line
import SwiperCore, { Autoplay, Navigation, Pagination, Lazy, Thumbs, EffectFade, Zoom } from 'swiper/core'; //eslint-disable-line
import cssConstants from '../utils/cssConstants';
import helpers from '../utils/helpers';
import zoom from './zoom';

SwiperCore.use([Autoplay, Navigation, Pagination, Lazy, Thumbs, EffectFade, Zoom]);

const defaultConfig = {
    loop: true,
    slidesPerView: 1,
    loopedSlides: 1,
    effect: 'fade',
    lazy: {
        loadPrevNext: 2,
        loadOnTransitionStart: true
    },
    navigation: {
        nextEl: '.js-swiper-next',
        prevEl: '.js-swiper-prev'
    }
};

const selectors = {
    SLIDERS: '.js-slider',
    SLIDE: '.swiper-slide',
    PRIMARY_IMG: '.js-primary-image',
    ZOOM: '.js-zoom'
};

const slider = {
    init: function () {
        const sliderInstance = document.querySelectorAll(selectors.SLIDERS);
        if (sliderInstance.length > 0) {
            let swiper = null;
            sliderInstance.forEach((sliderItem) => {
                const sliderConfig = sliderItem.getAttribute('data-config') ? JSON.parse(sliderItem.getAttribute('data-config')) : null;
                const thumbnail = (sliderItem.getAttribute('data-thumbnail') && document.querySelector(sliderItem.getAttribute('data-thumbnail'))) ? document.querySelector(sliderItem.getAttribute('data-thumbnail')).swiper : null;

                if (sliderConfig && thumbnail) {
                    sliderConfig.thumbs = {swiper: thumbnail};
                }

                if (sliderItem.querySelectorAll(selectors.SLIDE).length > 1) {
                    swiper = new Swiper(sliderItem, sliderConfig != null ? sliderConfig : defaultConfig);
                }

                if (swiper && sliderConfig && sliderConfig.pagination) {
                    swiper.on('slideChange', () => {
                        if (swiper.el.querySelector('.swiper-pagination-bullet-active')) {
                            swiper.el.querySelector('.swiper-pagination-bullet-active').classList.remove('swiper-pagination-bullet-active');
                        }
                        if (swiper.el.querySelectorAll('.swiper-pagination-bullet') && swiper.el.querySelectorAll('.swiper-pagination-bullet')[swiper.realIndex]) {
                            swiper.el.querySelectorAll('.swiper-pagination-bullet')[swiper.realIndex].classList.add('swiper-pagination-bullet-active');
                        }
                    });
                }

                if (swiper && swiper.el.querySelector(selectors.ZOOM)) {
                    let currentZoomedImg = null;
                    let isDoubleClick = false;

                    for (let i = 0; i < swiper.slides.length; i++) {
                        const imgToZoom = swiper.slides[i].querySelector(selectors.ZOOM);
                        imgToZoom.addEventListener('click', (e) => { // eslint-disable-line
                            if (e.currentTarget.getAttribute('data-zoom-url')) {
                                currentZoomedImg = e.currentTarget.closest('.product-primary-image');
                                currentZoomedImg.closest('.js-primary-image').classList.add(cssConstants.ZOOMED);
                                e.currentTarget.setAttribute('src', e.currentTarget.getAttribute('data-zoom-url'));

                                if (swiper.device.ios || swiper.device.android) {
                                    for (let j = 0; j < swiper.imagesToLoad.length; j++) {
                                        swiper.imagesToLoad[j].setAttribute('src', swiper.imagesToLoad[j].getAttribute('data-zoom-url'));
                                    }

                                    if (!isDoubleClick) {
                                        isDoubleClick = true;
                                        setTimeout(() => {
                                            isDoubleClick = false;
                                        }, 300);
                                        return false;
                                    }

                                    if (swiper.zoom && swiper.zoom.currentScale > 1) {
                                        swiper.zoom.out();
                                        swiper.allowTouchMove = true;
                                    } else {
                                        swiper.zoom.in();
                                        swiper.allowTouchMove = false;
                                    }
                                } else {
                                    currentZoomedImg.style.backgroundImage = 'url("' + e.currentTarget.getAttribute('data-zoom-url') + '")';
                                    zoom.init(currentZoomedImg);
                                }

                                currentZoomedImg.classList.add(cssConstants.ZOOMED);
                            }
                        });
                    }

                    const closeIcon = document.querySelector('.js-zoom-close');
                    closeIcon.addEventListener('click', () => {
                        swiper.zoom.out();

                        if (swiper.allowTouchMove === false) {
                            swiper.allowTouchMove = true;
                        }

                        currentZoomedImg.closest('.js-primary-image').classList.remove(cssConstants.ZOOMED);

                        if (currentZoomedImg) {
                            currentZoomedImg.classList.remove(cssConstants.ZOOMED);
                        }
                        swiper.update();
                    });

                    swiper.on('slideChangeTransitionEnd', (swiperEl) => {
                        if (!helpers.isMobile() && swiperEl.el.parentElement.classList.contains(cssConstants.ZOOMED)) {
                            swiperEl.el.querySelector('.swiper-slide-active .js-zoom').click();
                        }

                        if (swiper.device.ios || swiper.device.android) {
                            if (swiper.allowTouchMove === false) {
                                swiper.allowTouchMove = true;
                            }
                        }
                    });
                }
            });
        }
    }
};

slider.init();

module.exports = slider;