import onePopupSolution from './onepopupsolution';
import cssConstants from '../utils/cssConstants';
import helpers from '../utils/helpers';

const cache = {
    COOKIE_FLYOUT: document.querySelector('.js-flyout-cookie'),
    COOKIE_SETTINGS: document.querySelector('.cky-btn-revisit'),
    FLYOUTS: document.querySelector('.js-flyouts'),
    HEADER_SPACE: document.querySelector('.js-header-space'),
    HEADER: document.querySelector('.js-header-total'),
    BODY: document.documentElement || document.body,
    CONFIG: {attributes: true, childList: true, subtree: true}
};

/**
 * Move consent CookieYes to one popup solution
 * @param {mutations} mutations - elements
 * @param {observer} observer - MutationObserver
 */
function moveCookieConsent(mutations, observer) {
    mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
            if (document.querySelector('.cky-consent-container')) {
                const cookieYes = document.querySelector('.cky-consent-container');
                const delay = +cache.COOKIE_FLYOUT.dataset.delay;

                if (document.body.classList.contains('flyouts-visible') && !!cache.COOKIE_FLYOUT.querySelector('.cky-consent-container')) return;

                if (cookieYes.classList.contains('cky-hide')) {
                    if (cache.COOKIE_FLYOUT.classList.contains(cssConstants.SHOWING)) {
                        cache.COOKIE_FLYOUT.classList.remove(cssConstants.SHOWING);
                    }
                    onePopupSolution.showNextFlyout();
                    observer.disconnect();
                    return;
                }

                cache.COOKIE_FLYOUT.append(cookieYes);
                cookieBar.cookieSettings();

                if (delay > 0) {
                    setTimeout(() => {
                        document.body.classList.add('flyouts-visible');
                        cache.COOKIE_FLYOUT.classList.add(cssConstants.SHOWING);
                        if (helpers.isMobile()) {
                            cache.HEADER_SPACE.style.height = (cache.HEADER.offsetHeight + cache.FLYOUTS.offsetHeight) + 'px';
                            return;
                        }
                        cache.HEADER_SPACE.style.height = cache.HEADER.offsetHeight + 'px';
                    }, delay);
                    observer.disconnect();
                    return;
                }

                document.body.classList.add('flyouts-visible');
                cache.COOKIE_FLYOUT.classList.add(cssConstants.SHOWING);
                if (cache.HEADER_SPACE) {
                    if (helpers.isMobile()) {
                        cache.HEADER_SPACE.style.height = (cache.HEADER.offsetHeight + cache.FLYOUTS.offsetHeight) + 'px';
                        return;
                    }
                    cache.HEADER_SPACE.style.height = cache.HEADER.offsetHeight + 'px';
                }

                observer.disconnect();
            }
        }
    });
}

const cookieBar = {
    init: function() {
        this.checkIfCookieExists();
    },

    cookieSettings: function() {
        let position = 0;

        $('.cky-btn-revisit').on('click', () => {
            cache.COOKIE_FLYOUT.classList.add(cssConstants.SHOWING);
            if (cache.HEADER_SPACE) cache.HEADER_SPACE.style.height = cache.HEADER.offsetHeight + 'px';
        });
        $('.cky-btn-reject, .cky-btn-accept, .cky-btn-preferences').on('click', () => {
            cache.COOKIE_FLYOUT.classList.remove(cssConstants.SHOWING);
            onePopupSolution.showNextFlyout();
            if (document.body.classList.contains('mobile-no-scrolling')) {
                if (position > 0) window.scroll(0, position);
                document.body.classList.remove('mobile-no-scrolling');
            }
        });
        $('.cky-btn-customize').on('click', () => {
            if (helpers.isMobile() && !document.body.classList.contains('mobile-no-scrolling')) {
                position = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
                document.body.classList.add('mobile-no-scrolling');
            }
        });
        $('.cky-btn-close').on('click', () => {
            if (document.body.classList.contains('mobile-no-scrolling')) {
                if (position > 0) window.scroll(0, position);
                document.body.classList.remove('mobile-no-scrolling');
            }
        });
    },

    checkIfCookieExists: function() {
        if (!cache.COOKIE_FLYOUT) return;

        if (cache.COOKIE_FLYOUT.dataset.shown === 'true') {
            onePopupSolution.showNextFlyout();
        }
        if (cache.HEADER_SPACE) cache.HEADER_SPACE.style.height = 'auto';
        const observer = new MutationObserver(moveCookieConsent);

        observer.observe(cache.BODY, cache.CONFIG);
    }
};

export default cookieBar;