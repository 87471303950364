﻿;var MD = window.MD || {};

MD.csp = function (window, $) {
    
	var elements = {
		popup  : null,
        cookie : null
	};
	
    var priv = {
    		
        /*
         * Initialization
         * 
         */
        init: function () {
        	
        	elements.popup = $('.js-popup-countryspecific');
        	
        	priv.bindEvents();
        	
        },
        
        bindEvents: function () {
        	
        	var form = elements.popup.find('form');
        	
        	// subscribe
        	form.find('input.submit').on( "click", function( e ) {
       			e.preventDefault();
       			       			
       			if ( form.valid() ) {
       				
					$.ajax({
	       				type	: "POST",
	       				url		: form.attr( 'action' ),
	       				data	: form.serialize(),
	       			  	success	: function(data) { elements.popup.find('div.newsletter-form-popup-wrap').html( data ); MD.cookie.setCookie('popup', true, 365); },
	       			  	error	: function(data) { elements.popup.find('div.newsletter-form-popup-wrap').html( data ); }
	       			}).done( function( e ) {
	       				MD.cookie.setCookie('popup', true, 365);
	       			});
       			}
       			
        	});
        	
        	form.find('.mfp-close').on( "click", function( e ) {
        		e.preventDefault();
        		
        		MD.cookie.setCookie('popup', true, 1);
        		MD.popups.close();
        	});
        	
        	//mfp-container
        }
        
    };

    return {
        onReady: function () {
            priv.init();
        }
    };

}(window, jQuery);

$(document).ready(function () {    
    MD.csp.onReady();
});