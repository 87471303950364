/*
This file has legacy code that has to be updated.
If you're working on any of the functions below, please move it to separate file,
rewrite it in vanilla javascript and import in cartridges\MD_Storefront\cartridge\static\default\js\productDetailPage.js.
*/

/* eslint-disable */
var MD = window.MD || {};

import popup from '../popups';
import helpers from '../utils/helpers';

MD.productDetailPage = function (window, $) {
    /**
     * @namespace Private methods and variables
     */
    var priv = {

        cache: {},

        // this holds the amount that this product is added after the page is opened
        qtyAddedToCart: 0,

        mobilePrevSlide: 1,

        mobileZoomInitialized: 0,

        mobileNotifyMeCaptcha: null,

        init: function () {
            if ($('.pdp-main').length === 0) { return; }

            priv.initializeCache();
            priv.initializeDom();
            priv.initializeEvents();

            if (SitePreferences.STORE_PICKUP) {
                app.storeinventory.init();
            }

            helpers.makePrevButtonSticky();

            priv.getProductSetPrice();
            priv.redirectStorelocator();

            $(window).on('load', function () {
                if ($('.yotpo-shoppers-say').find('.yotpo-display-wrapper').data('met-requirements') == true) {
                    $('.yotpo-shoppers-say').closest('.yotpo-widgets-container').show();
                }
            });
        },

        initializeCache: function () {
            priv.cache = {
                productId: $("#pid"),
                pdpMain: $("#pdpMain"),
                productContent: $("#product-content"),
                thumbnails: $("#thumbnails"),
                bonusProductGrid: $(".bonusproductgrid"),
                imageContainer: $(".product-primary-image"),
                productSetList: $("#product-set-list"),
                addAllToCart: $("#add-all-to-cart")
            };
            priv.cache.detailContent = priv.cache.pdpMain.find("div.detail-content");
            priv.cache.pdpForm = priv.cache.pdpMain.find("form.pdpForm");
            priv.cache.swatches = priv.cache.pdpMain.find("ul.swatches");
            priv.cache.mainImageAnchor = priv.cache.imageZoom = priv.cache.imageContainer.find("a.main-image");
            priv.cache.mainImage = priv.cache.mainImageAnchor.find("img.primary-image");
            priv.cache.isGift = $("#is_gift").val();
            priv.cache.isGiftPageReloaded = $("#is_gift_page_reloaded").val();
        },

        initializeDom: function () {
            var productTabs = priv.cache.pdpMain.find('div.product-detail .product-tabs');
            if (productTabs.length > 0) {
                productTabs.tabs();
            }

            if (priv.cache.productSetList.length > 0) {
                if (priv.cache.productSetList.find("input.js-is-available[value=0]").length > 0) {
                    priv.cache.addAllToCart.attr("disabled", "disabled");
                    $("#add-to-cart").attr("disabled", "disabled"); // this may be a bundle
                }
            }

        },

        initializeEvents: function () {
            app.product.initAddThis();

            if (SitePreferences.STORE_PICKUP) {
                app.storeinventory.buildStoreList($('.product-number span').html());
            }

            MD.productDetailPage.initAddToCart();

            // quantity change
            priv.cache.pdpMain.on("change", "form.pdpForm select[name='Quantity']", function (e) {
                var availabilityContainer = priv.cache.pdpMain.find("div.availability");
                app.product.getAvailability(
                    $("#pid").val(),
                    $(this).val(),
                    function (data) {
                        if (!data) {
                            $("#add-to-cart").removeAttr("disabled");
                            availabilityContainer.find(".availability-qty-available").html();
                            availabilityContainer.find(".availability-msg").show();
                            return;
                        } else {
                            var avMsg = null;
                            var avRoot = availabilityContainer.find(".availability-msg").html('');

                            // Look through levels ... if msg is not empty, then create span el
                            if (data.levels.IN_STOCK > 0) {
                                avMsg = avRoot.find(".in-stock-msg");
                                if (avMsg.length === 0) {
                                    avMsg = $("<p/>").addClass("in-stock-msg").appendTo(avRoot);
                                }
                                if (data.levels.PREORDER == 0 && data.levels.BACKORDER == 0 && data.levels.NOT_AVAILABLE == 0) {
                                    // Just in stock
                                    avMsg.text(Resources.IN_STOCK);
                                } else {
                                    // In stock with conditions ...
                                    avMsg.text(data.inStockMsg);
                                }

                                $("#add-to-cart").removeAttr("disabled");
                            }
                            if (data.levels.PREORDER > 0) {
                                avMsg = avRoot.find(".preorder-msg");
                                if (avMsg.length === 0) {
                                    avMsg = $("<p/>").addClass("preorder-msg").appendTo(avRoot);
                                }
                                if (data.levels.IN_STOCK == 0 && data.levels.BACKORDER == 0 && data.levels.NOT_AVAILABLE == 0) {
                                    // Just in stock
                                    avMsg.text(Resources.PREORDER);
                                } else {
                                    avMsg.text(data.preOrderMsg);
                                }

                                $("#add-to-cart").removeAttr("disabled");
                            }
                            if (data.levels.BACKORDER > 0) {
                                avMsg = avRoot.find(".backorder-msg");
                                if (avMsg.length === 0) {
                                    avMsg = $("<p/>").addClass("backorder-msg").appendTo(avRoot);
                                }
                                if (data.levels.IN_STOCK == 0 && data.levels.PREORDER == 0 && data.levels.NOT_AVAILABLE == 0) {
                                    // Just in stock
                                    avMsg.text(Resources.BACKORDER);
                                } else {
                                    avMsg.text(data.backOrderMsg);
                                }

                                $("#add-to-cart").removeAttr("disabled");
                            }
                            if (data.inStockDate != '') {
                                avMsg = avRoot.find(".in-stock-date-msg");
                                if (avMsg.length === 0) {
                                    avMsg = $("<p/>").addClass("in-stock-date-msg").appendTo(avRoot);
                                }
                                avMsg.text(String.format(Resources.IN_STOCK_DATE, data.inStockDate));

                                $("#add-to-cart").attr("disabled", "disabled");
                            }
                            if (data.levels.NOT_AVAILABLE > 0) {
                                avMsg = avRoot.find(".not-available-msg");
                                if (avMsg.length === 0) {
                                    avMsg = $("<p/>").addClass("not-available-msg").appendTo(avRoot);
                                }
                                if (data.levels.PREORDER == 0 && data.levels.BACKORDER == 0 && data.levels.IN_STOCK == 0) {
                                    avMsg.text(Resources.NOT_AVAILABLE);
                                } else {
                                    avMsg.text(Resources.REMAIN_NOT_AVAILABLE);
                                }

                                $("#add-to-cart").attr("disabled", "disabled");
                            }

                            return;
                        }
                    });
            });

            priv.cache.pdpMain.find("form.pdpForm select[name='Quantity']").trigger("change");

            // dropdown variations
            priv.cache.pdpMain.on("change", ".product-options select", function (e) {
                var salesPrice = priv.cache.pdpMain.find("div.product-add-to-cart .price-sales");

                var selectedItem = $(this).children().filter(":selected").first();
                var combinedPrice = selectedItem.data("combined");
                salesPrice.text(combinedPrice);
            });

            // prevent default behavior of thumbnail link and add this Button
            priv.cache.pdpMain.on("click", ".thumbnail-link, .addthis_toolbox a", false);
            priv.cache.pdpMain.on("click", "li.unselectable a", false);

            // init mobile variation select events
            priv.initMobileVariationSelectEvents();

            // init Hide Gift Card Friend
            priv.initGifCardHideFriendsEvents();
            
            // init Tooltip
            priv.initGifCardTooltip();

            // init Hide Gift Card Friend
            priv.initHideShippingCostGiftCart();

            // quantity change for product set
            priv.cache.pdpMain.on("change", "div.product-set-item select[name='Quantity']", function (e) {
                priv.getProductSetPrice();
            });

            priv.cache.productSetList.on("click", "div.product-set-item li a[href].swatchanchor", function (e) {
                e.preventDefault();

                var el = $(this);
                // Prevent a swatch from being deselected by clicking on a selected swatch.
                if (el.parents('li').hasClass('selected')) return;
                if (el.parents('li').hasClass('unselectable')) return;

                // get the querystring from the anchor element
                var params = app.util.getQueryStringParams(this.search);
                var psItem = $(this).closest(".product-set-item");

                // set quantity to value from form
                var qty = psItem.find("form").find("select[name='Quantity']").first().val();
                params.Quantity = isNaN(qty) ? "1" : qty;

                var url = Urls.getSetItem + "?" + $.param(params);

                // get container
                var ic = $(this).closest(".product-set-item");
                ic.load(url, function () {
                    app.progress.hide();

                    if (priv.cache.productSetList.find("input.js-is-available[value=0]").length > 0) {
                        priv.cache.addAllToCart.attr("disabled", "disabled");
                        $("#add-to-cart").attr("disabled", "disabled"); // this may be a bundle
                    } else {
                        priv.cache.addAllToCart.removeAttr("disabled");
                        $("#add-to-cart").removeAttr("disabled"); // this may be a bundle
                    }

                    MD.productDetailPage.initAddToCart(ic);

                    priv.getProductSetPrice();
                });
            });

            priv.cache.pdpMain.on("change", ".js-ps-variation-select", function (e) {
                if ($(this).val().length === 0) {
                    return;
                }
                // get the querystring from the value
                var queryStringParams = MD.utils.getQueryString(this.value);
                var params = app.util.getQueryStringParams(queryStringParams);
                var psItem = $(this).closest(".product-set-item");

                // set quantity to value from form
                var qty = psItem.find("form").find("select[name='Quantity']").first().val();
                params.Quantity = isNaN(qty) ? "1" : qty;

                var url = Urls.getSetItem + "?" + $.param(params);

                // get container
                var ic = $(this).closest(".product-set-item");
                priv.cache.addAllToCart.attr("disabled", "disabled");
                ic.find(".add-to-cart").attr("disabled", "disabled"); // this may be a bundle

                ic.load(url, function () {
                    app.progress.hide();

                    if (priv.cache.productSetList.find("input.js-is-available[value=0]").length > 0) {
                        priv.cache.addAllToCart.attr("disabled", "disabled");
                        $("#add-to-cart").attr("disabled", "disabled"); // this may be a bundle
                    } else {
                        priv.cache.addAllToCart.removeAttr("disabled");
                        $("#add-to-cart").removeAttr("disabled"); // this may be a bundle
                    }

                    MD.productDetailPage.initAddToCart(ic);

                    priv.getProductSetPrice();
                });
            });

            priv.cache.addAllToCart.on("click", function (e) {
                e.preventDefault();
                var psForms = priv.cache.productSetList.find("form").toArray(),
                    miniCartHtml = "",
                    addProductUrl = app.util.ajaxUrl(Urls.addProduct);

                // add items to cart
                function addItems() {
                    var form = $(psForms.shift());
                    var itemid = form.find("input[name='pid']").val();

                    $.ajax({
                        dataType: "html",
                        url: addProductUrl,
                        data: form.serialize()
                    })
                        .done(function (response) {
                            // success
                            miniCartHtml = response;
                        })
                        .fail(function (xhr, textStatus) {
                            // failed
                            var msg = Resources.ADD_TO_CART_FAIL;
                            $.validator.format(msg, itemid);
                            if (textStatus === "parsererror") {
                                msg += "\n" + Resources.BAD_RESPONSE;
                            } else {
                                msg += "\n" + Resources.SERVER_CONNECTION_ERROR;
                            }
                            window.alert(msg);
                        })
                        .always(function () {
                            if (psForms.length > 0) {
                                addItems();
                            } else {
                                MD.minicart.show(miniCartHtml);
                                // addtocart GMT (all set items)
                                var addGtmData = [];
                                var setAddToBagItems = document.querySelectorAll('.js-product-set-list .product-set-item');
                                if (setAddToBagItems && setAddToBagItems.length > 0) {
                                    for (var i = 0; i < setAddToBagItems.length; i++) {
                                        var setAddToBagItem = setAddToBagItems[i].querySelector('.js-is-available');
                                        addGtmData.push(JSON.parse(setAddToBagItem.getAttribute('data-gtagadd')));
                                    }
                                }
                                if (addGtmData.length > 0) {
                                    var gtmtracking = require('../gtm/gtmtracking');
                                    gtmtracking.addToCartTracker(addGtmData);
                                }
                            }
                        });
                }
                addItems();
                return false;
            });

            app.sendToFriend.initializeDialog(priv.cache.pdpMain, "a.send-to-friend");

            priv.cache.pdpMain.find("button.add-to-cart[disabled]").attr('title', priv.cache.pdpMain.find(".availability-msg").html());


            priv.cache.pdpMain.on("change", "#GiftCertificateForm input[type=radio]", function (e) {

                var inputs = $('#GiftCertificateForm input[type=radio]:checked').val();

                if (inputs == "myself") {
                    $('#GiftCertificateForm input[name="dwfrm_giftcert_purchase_recipient"]').parent().find('span:first').text(Resources.GIFTCARD_PDP_YOURNAME);
                    $('#GiftCertificateForm input[name="dwfrm_giftcert_purchase_recipientEmail"]').parent().find('span:first').text(Resources.GIFTCARD_PDP_YOUREMAIL);
                    $('#GiftCertificateForm input[name="dwfrm_giftcert_purchase_confirmRecipientEmail"]').parent().css('display', 'none');

                } else {
                    $('#GiftCertificateForm input[name="dwfrm_giftcert_purchase_recipient"]').parent().find('span:first').text(Resources.GIFTCARD_PDP_FRIENDSNAME);
                    $('#GiftCertificateForm input[name="dwfrm_giftcert_purchase_recipientEmail"]').parent().find('span:first').text(Resources.GIFTCARD_PDP_FRIENDSEMAIL);
                    $('#GiftCertificateForm input[name="dwfrm_giftcert_purchase_confirmRecipientEmail"]').parent().css('display', 'block');

                }

            });

            priv.cache.pdpMain.find("#GiftCertificateForm input[type=radio]").trigger("change");

            const giftCardSelect =  $('.cert-amount .js-variation-select');
            const physicalGiftCardOptions = $('.cert-amount .js-variation-select option:not([giftcard-type="digital"])');
            const digitalGiftCardOptions = $('.cert-amount .js-variation-select option:not([giftcard-type="physical"])');

            // set correct option values on initial load and later on change.
            $('[name=dwfrm_giftcert_purchase_digitalphysical]:checked').val() === 'physical' ? giftCardSelect.html(physicalGiftCardOptions) : giftCardSelect.html(digitalGiftCardOptions);

            priv.cache.pdpMain.on("change", "[name=dwfrm_giftcert_purchase_digitalphysical]", function (e) {
                $(this).val() === 'physical' ? giftCardSelect.html(physicalGiftCardOptions) : giftCardSelect.html(digitalGiftCardOptions);
            });
        },

        /**
         * @function
         * @description initialize event to show input fields when the Giftcard to Friends is selected
         */
        initGifCardHideFriendsEvents: function () {
            let giftCardRadio = $(".js-gift-cert-radios input[type='radio']");
            if (giftCardRadio !== null) {
                giftCardRadio.on('click', function () {
                    var radioValue = $(this).val();
                    var checkBoxGiftCardContainer = $('.js-gift-friend');
                    if (radioValue) {
                        giftCardRadio.removeClass("is-hidden");
                        checkBoxGiftCardContainer.toggleClass("is-hidden");
                        var detailFields = $("[name=dwfrm_giftcert_purchase_recipient],[name=dwfrm_giftcert_purchase_recipientEmail]");
                        if (radioValue == 'myself') {
                            detailFields.removeClass("required");
                        } else {
                            detailFields.addClass("required");
                        }
                    }
                });
            }
        },

        /**
         * @function
         * @description Tooltip for the gift card
         */
        initGifCardTooltip: function () {
            let tooltipContainer = $('.js-tooltip-container');
            let tooltipContainerButtonClose = $('.js-tooltip-container .js-button-close');
            let tooltipContainerButtonOpen = $('.js-gift-friend .js-button-info');

            // Open Tooltip
            if (tooltipContainerButtonOpen !== null) {
                tooltipContainerButtonOpen.on('click', function (e) {
                    e.stopPropagation();
                    $(this).siblings('.js-tooltip-container').addClass('is-active');
                });
            }

            // Close Tooltip
            if (tooltipContainerButtonClose !== null) {
                tooltipContainerButtonClose.on('click', function (e) {
                    e.stopPropagation();
                    $(this).closest('.js-tooltip-container').removeClass('is-active');
                });
            }
        },

        /**
        * @function
        * @description Hide shipping cost if the user select Physical Gift card
        */

        initHideShippingCostGiftCart: function () {
            // Is visible when is digital
            if ($('#giftcard-digital:checked').length) {
                $('.form-row.is-shipping-cost').show();
            }

            // Is visible when is digital
            $('#giftcard-digital').on('click', function () {
                $('.form-row.is-shipping-cost').show();
            });

            // Is not visible when is physical
            $('#giftcard-physical').on('click', function () {
                $('.form-row.is-shipping-cost').hide();
            });
        },


        /**
         * @function
         * @description initialize event handlers for mobile variation dropdowns, also used in
         * the PLP product tiles for mobile/desktop
         */
        initMobileVariationSelectEvents: function () {
            $('body').on("click", ".js-mobile-variation-select li", function (e) {
                if ($(this).hasClass('m-outofstock')) {
                    return false;
                }
                $('.js-mobile-variation-select').find('.active').removeClass('active');
                $(this).addClass('active');
            });

            $('body').on("click", ".js-close-mobile-size-layout", function (e) {
                // check if variations are showing inside a product tile
                var variationsInTile = $(e.currentTarget).closest('.js-product-tile').length;

                // if product tile, close overlay
                if (variationsInTile) {
                    $(e.currentTarget).closest('.js-tile-overlay-container').removeClass('active');
                    // show actions bar;
                    $(e.currentTarget).closest('.js-product-tile').find('.js-tile-actions-container').removeClass('inactive');
                } else {
                    $(e.currentTarget).closest('.js-mobile-variation-select').removeClass('visible');
                }

            });

            $('body').on("click", ".m-size-addbutton", function (e) {
                var addToCartData = "";
                var $eventTarget = $(e.currentTarget);
                var isProductTile = $eventTarget.closest('.js-product-tile').length;

                var id = $eventTarget.siblings('.m-size-value').data("variantid");

                var form = isProductTile ? $eventTarget.closest('.js-product-tile').find('.js-add-to-cart-form') : $('.product-add-to-cart').find("form");

                form.find('input[name="pid"]').val(id);
                form.find("select[name='Quantity']").val("1");
                var data = form.serialize();

                if (isProductTile) {
                    // close tile overlay
                    $(e.currentTarget).closest('.js-tile-overlay-container').removeClass('active');
                    // show actions bar;
                    $(e.currentTarget).closest('.js-product-tile').find('.js-tile-actions-container').removeClass('inactive');
                    //get data for GTM (addtocart)
                    addToCartData = $(e.currentTarget).parent().find('.js-variant-data').data('gtagadd');
                } else {
                    $('.js-mobile-variation-select').removeClass('visible');
                    //get data for GTM (addtocart)
                    addToCartData = $(e.currentTarget).parent().find('.js-gtm-add-to-cart').data('gtagadd');
                }
                MD.minicart.update(data);

                if (addToCartData && addToCartData.length > 0) {
                    var gtmtracking = require('../gtm/gtmtracking');
                    gtmtracking.addToCartTracker(addToCartData);
                }
            });
        },

        redirectStorelocator: function () {

            $('button#redirect-to-storelocator').on('click', function () {

                var url = $(this).data('url') + '?label=' + $(this).data('label');
                $(location).attr('href', url);
            });

        },

        getProductSetPrice: function () {
            var element = $('.js-set-price');

            if (!element || element.length == 0) {
                return;
            }

            var pid = $("#pdpMain").data("pid");
            var itemids = '';

            priv.cache.productSetList.find("form").each(function () {
                var itemid = $(this).find("input[name='pid']").val();

                itemids += (itemids != '' ? ',' : '') + itemid;
            });

            var url = app.util.ajaxUrl(Urls.getProductSetPrice);

            $.ajax({
                dataType: "html",
                url: url,
                data: "pid=" + pid + "&pids=" + itemids
            })
                .done(function (response) {
                    var prices = JSON.parse(response);
                    // success
                    var $oldprice = element.find('.oldprice');
                    if (prices.promo) {
                        $oldprice.html(prices.total);
                        $oldprice.show();
                        element.find('.standardprice').html(prices.promo);
                    } else {
                        $oldprice.hide();
                        element.find('.standardprice').html(prices.total);
                    }
                });
        },

        /**
         * @private
         * @function
         * @description Event handler to handle the add to cart event
         */
        setAddToCartHandler: function (e, isProductTile) {
            var $target = $(e.currentTarget);
            var isSubItem = $target.hasClass("sub-product-item");
            var isWishlistAddToCart = $target.closest('.js-wishlist-wrapper').length;
            var isSet = $target.hasClass("js-productset");
            // use js media queries to avoid different results in browsers depending on the scrollbar width
            // < 767px does not include iPads
            var isMobileView = window.matchMedia("(max-width: 767px)").matches;

            if (isProductTile && isSet) {
                var viewLink = $target.parents('.js-tile-actions-container').find('.js-product-link');
                window.location.href = viewLink.attr('href');
            } else if ((isMobileView || isProductTile) && !isSubItem && !isWishlistAddToCart) {
                var mobileVariationSelectClass = '.js-mobile-variation-select';
                var $targetVariationSelect = isProductTile ? $target.closest('.js-product-tile').find(mobileVariationSelectClass) : $(mobileVariationSelectClass);

                // if add to cart came from a product tile, 
                // only open the variation selector from that tile
                if (isProductTile) {

                    var $productTile = $target.closest('.js-product-tile');
                    var $tileOverlay = $productTile.find('.js-tile-overlay-container');
                    var pid = $productTile.attr('data-itemid');
                    var getSizesUrl = app.util.appendParamsToUrl(Urls.getProductSizes, { pid: pid });

                    // Ajax variations every time (keep stock updated) and open overlay
                    app.ajax.load({
                        url: getSizesUrl,
                        target: $tileOverlay,
                        callback: function (response) {
                            // init popups (for size guide & notify me)
                            popup.init();
                            // when done loading, make sizes visible
                            $productTile.find(mobileVariationSelectClass).addClass('visible');
                            // open overlay
                            $tileOverlay.addClass('active');

                            return false;
                        },
                    });
                } else {
                    if (!$targetVariationSelect.hasClass('visible')) {
                        $targetVariationSelect.addClass('visible');
                        return false;
                    }
                }
            } else {
                if ($('input[name=dwfrm_notifyme_notifymeType]').val() == 'pre-order') {
                    return false;
                }

                var addToCartData;
                if (isSubItem) {
                    // sets page, single product adding (addtocart GTM)
                    addToCartData = $target.closest('.product-set-item').find('.js-is-available').data('gtagadd');
                } else {
                    if ($('.js-modal.popup-quickview').length === 0) {
                        // normal pdp page, single product adding (addtocart GTM)
                        addToCartData = $('.js-product-content .js-product-variations').data('gtagadd');
                    } else {
                        // quick view page, single product adding (addtocart GTM)
                        addToCartData = $('.js-modal.popup-quickview .js-product-content .js-product-variations').data('gtagadd');
                    }
                }

                if ($target.closest('.popup-quickview')) {
                    $target.closest('.popup-quickview').find('.js-modal-close').click();
                }

                e.preventDefault();
                var form = $target.closest("form");
                var qty = form.find("select[name='Quantity']");
                if (qty.length === 0 || isNaN(qty.val()) || parseInt(qty.val(), 10) === 0) {
                    qty.val("1");
                }

                var data = form.serialize();
                MD.minicart.update(data);

                if (addToCartData) {
                    var gtmtracking = require('../gtm/gtmtracking');
                    gtmtracking.addToCartTracker(addToCartData);
                }
            }
        },

        checkInventory: function (qty) {
            var inventory = $(this).data("inventory");
            if (inventory != -1) {
                inventory -= priv.qtyAddedToCart;
            }

            if (inventory != null && ($(this).data("inventory") != -1 && parseInt(qty.val()) > parseInt(inventory))) {
                $(this).siblings(".product-stock-adjust").show("slow").delay(10000).hide("slow");
                qty.val(inventory);
                var goToStores = $(this).siblings('.go-to-stores');
                if (goToStores.length > 0) {
                    goToStores.hide();
                }
                return false;
            }
        },

        /* It is unknown if this function is used or should be deleted */
        initRecommendations: function () {
            // The recommendations are outside the productcontent template,
            // so we have copy this clientside to the correct container
            $(".js-recommendations").html($(".js-hidden-recommendations").html());
            // Do the same for the descriptions
            $(".js-product-description").html($(".js-ph-description").html());
        },

        triggerMinicartHover: function () {
            if (priv.cache.isGiftPageReloaded == "true") {
                MD.minicart.triggerHover();
            }
        }
    };

    /** @scope productDetailPage */
    return {
        /**
         * Initializes the logic for the current page
         * to be called on $(document).ready
         */
        onReady: function () {
            priv.init();
        },

        /**
         * @function
         * @description Binds the click event to a given target for the add-to-cart handling
         * @param {Element} target The target on which an add to cart event-handler will be set
         */
        initAddToCart: function (target) {
            if (!priv.cache.isGift) {
                if (target) {
                    target.unbind('click').on("click", ".add-to-cart", priv.setAddToCartHandler);
                } else {
                    $(".add-to-cart").unbind('click').on("click", priv.setAddToCartHandler);
                }
            }
        },

        /**
         * @function
         * @description Binds the click event to add to cart buttons in a product tile
         */
        initTileAddToCart: function () {
            $("body").on("click", ".js-tile-add-to-cart", function (e) {
                // close all tile overlays
                $('.js-close-mobile-size-layout').trigger('click');

                // handle add to cart click
                priv.setAddToCartHandler(e, true);
            });

            // handle product tile dropdown (same as PDP mobile)
            priv.initMobileVariationSelectEvents();
        },

        triggerMinicartHover: function () {
            priv.triggerMinicartHover();
        },
        /**
         * This function will copy the content of the recommendations to a different element,
         * this way the recommendations are also changing when switching between variants.
         */
        initRecommendations: priv.initRecommendations
    };
}(window, jQuery);
/* eslint-enable */