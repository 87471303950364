const captchaScript = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
export default class Captcha {
    constructor(element, callback) {
        this.captchaContainer = null;
        this.load(element, callback);
    }

    load(element, callback) {
        const _this = this;
        if (document.querySelectorAll('[src="' + captchaScript + '"]').length < 1) {
            const script = document.createElement('script');
            window.onloadCallback = loadCaptacha;
            script.setAttribute('src', captchaScript);
            document.getElementsByTagName('head')[0].appendChild(script);
        } else {
            loadCaptacha();
        }

        function loadCaptacha() {
            if (typeof grecaptcha !== 'undefined' && typeof grecaptcha.render !== 'undefined' && document.getElementById(element)) {
                _this.captchaContainer = grecaptcha.render(element, {
                    sitekey : Resources.RECAPTCHA_SITEKEY,
                    callback : callback,
                    size : 'invisible'
                });
            }
        }
    }

    reset() {
        grecaptcha.reset(this.captchaContainer);
    }

    execute() {
        grecaptcha.execute(this.captchaContainer);
    }
}
