var MD = window.MD || {};

MD.cookie = function(window) {
    var priv = {
        getCookie: function(cookieName) {
            var i, x, y, ARRcookies = document.cookie.split(";");
            for (i = 0; i < ARRcookies.length; i++) {
                x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
                y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
                x = x.replace(/^\s+|\s+$/g, "");
                if (x == cookieName) {
                    return unescape(y);
                }
            }
        },

        setCookie: function(cookieName, value, exdays) {
            var exdate = new Date();
            if (exdays < 1) {
                var expectedTime = exdate.getHours() + (exdays * 24);
                if (expectedTime > 24) {
                    expectedTime -= 24;
                    exdate.setDate(exdate.getDate() + 1);
                }
                exdate.setHours(expectedTime);
            } else {
                exdate.setDate(exdate.getDate() + exdays);
            }
            var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString() + "; Secure; path=/; " + (typeof(app.cookieDomain) != 'undefined' && app.cookieDomain.length > 0 ? ("domain=" + app.cookieDomain + "; ") : ""));
            document.cookie = cookieName + "=" + c_value;
        },

        setSiteCookie: function(cookieName, value, exdays, site) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + exdays);
            var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString() + "; Secure; path=/; " + (typeof(site) != 'undefined' && site.length > 0 ? ("domain=" + site + "; ") : ""));
            document.cookie = cookieName + "=" + c_value;
        },

        expireCookie: function(cookieName) {
            setCookie(cookieName, '', 0); // expire the cookie.
        }
    };

    return {
        getCookie: priv.getCookie,
        setCookie: priv.setCookie,
        setSiteCookie: priv.setSiteCookie,
        expireCookie: priv.expireCookie
    };
}(window);
