var MD = window.MD || {};

MD.gridToggle = function (window, $) {
    var elements = {
        searchResultContainerSelector: '.js-search-result-content',
        toggleElSelector : '.js-grid-toggle',
        oneTileButtonSelector: '.js-one-per-row',
        twoTilesButtonSelector : '.js-two-per-row',
        twoTilesClass: 'two-tile-row',
        activeButtonClass: 'active',
    };

    var gridCookie = {
        name : 'grid_layout_preference',
        onePerRowValue : 'one_per_row',
        twoPerRowValue : 'two_per_row'
    }

    var priv = {
        /*
         * Initialization
         * */
        init: function () {
            priv.initEvents();
        },

        initEvents: function () {
            $(document).on('click', elements.toggleElSelector, function(e) {
                if($(e.currentTarget).is(elements.twoTilesButtonSelector)) {
                    // render grid
                    priv.renderTwoPerRow();
                    // save user preference in cookie
                    priv.setGridPreferenceCookie(gridCookie.twoPerRowValue);
                } else {
                    // render grid
                    priv.renderOnePerRow();
                    // save user preference in cookie
                    priv.setGridPreferenceCookie(gridCookie.onePerRowValue);
                }
            });
        },

        renderOnePerRow: function (){
            $(elements.searchResultContainerSelector).removeClass(elements.twoTilesClass);
            $(elements.oneTileButtonSelector).addClass(elements.activeButtonClass);
            $(elements.twoTilesButtonSelector).removeClass(elements.activeButtonClass);

            // trigger reload of sliders in tiles
            priv.reloadTileSliders();
        },

        renderTwoPerRow: function () {
            $(elements.searchResultContainerSelector).addClass(elements.twoTilesClass);
            $(elements.oneTileButtonSelector).removeClass(elements.activeButtonClass);
            $(elements.twoTilesButtonSelector).addClass(elements.activeButtonClass);

            // trigger reload of sliders in tiles
            priv.reloadTileSliders();
        },

        getGridPreferenceCookie: function () {
            // returns grid preference value
            return MD.cookie.getCookie(gridCookie.name);
        },

        setGridPreferenceCookie: function (selectedGridLayout) {
            // sets grid preference value
            MD.cookie.setCookie(gridCookie.name, selectedGridLayout);
        },

        renderPreferredGrid: function (override) {
            // if there is an override, render that, else check cookie
            var gridPreference = override || priv.getGridPreferenceCookie();

            if (gridPreference ===  gridCookie.onePerRowValue) {
                priv.renderOnePerRow();
            } else {
                priv.renderTwoPerRow();
            }
        },

        reloadTileSliders: function () {
            // triggering resize forces the slider element to recalculate and fix itself
            document.querySelectorAll('.js-product-link').forEach((sliderItem) => {
                if (sliderItem.swiper) {
                    sliderItem.swiper.update();
                }
            });
        },

        getUrlGridOverride: function () {
            // check url for grid preference param
            return MD.utils.getQueryStringParams(window.location.href).gridlayout;
        },

        renderGridLayout: function () {
            // render preferred grid (according to cookie) or according to url param
            var override = priv.getUrlGridOverride();

            priv.renderPreferredGrid(override);
        }

    };

    return {
        onReady: function () {
            priv.init();
        },
        renderGridLayout: priv.renderGridLayout
    };

}(window, jQuery);


jQuery(document).ready(function () {
    MD.gridToggle.onReady();
});
