import helpers from '../utils/helpers';

const touch = {
    init: function() {
        if (helpers.isMobile() || helpers.isTablet()) {
            document.documentElement.classList.add('touch');
        } else {
            document.documentElement.classList.add('no-touch');
        }
    }
};

export default touch.init();