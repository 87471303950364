var MD = window.MD || {};

import popups from './popups';
import slider from './components/slider';
import pdp from './pages/productDetail/pdp';
import swatchChange from './pages/productDetail/swatchChange';
import varationSelection from './pages/productDetail/variantSelection';

MD.quickview = function (window, $) {

    var priv = {
        quickviewButton: null,

        enabled: false,

        initiated: false,

        init: function () {
            priv.quickviewButton = $('<a class="quickview-show button darkline" data-popup=\'{"addClass":"popup-quickview", "addParam":{"source":"quickview"}}\'>' + Resources.QUICKVIEW_BUTTON + '</a>');

            if (!priv.initiated) {
                document.addEventListener('popup-initiated', function() {
                    if (document.querySelector('.js-modal.popup-quickview')) {
                        MD.productDetailPage.initAddToCart();
                        if (typeof setAddToCartButton === 'function') { setAddToCartButton(); }
                        slider.init();
                        pdp.init();
                        swatchChange.init();
                        varationSelection.init();
                    }
                });
            }

            priv.initiated = true;

            if ( $(".quickview-item").length === 0 || $(".quickview-item").data("lookbook") == true) { return; }

            $(".quickview-item").on("mouseenter", function () {
                var el = $(this);
                var target = el.find("a");
                var gtagData = $(this).find(".thumb-link").data("gtag-product");

                priv.quickviewButton.attr("href", target.attr("href"));
                priv.quickviewButton.data("gtag", gtagData);

                el.prepend(priv.quickviewButton);
                popups.init();

            }).on("mouseleave", function () {
                $(this).find("a.quickview-show").remove();
            }).on("click", function () {
            	return false;
            }).on("click", "a.quickview-show", function (e) {
            	e.preventDefault();
            	var gtagData = $(this).data("gtag");
            	if (gtagData != null) {
            		priv.initGTMProductClick(gtagData);
            	}
            }).on("click", ".thumb-link, .name-link", function (e) {
            	e.preventDefault();
              	var gtagData = $(this).data("gtag-promotion");
            	if (gtagData != null) {
            		priv.initGTMPromotionClick(gtagData);
            	}
            });

            priv.quickviewButton.on("click", function (e) {
                e.preventDefault();
                priv.enable();
            });

        },

        afterClose: function() {
            initPDP();
        },

        onClose: function () {
            priv.enabled = false;
        },

        isEnabled: function () {
            return priv.enabled;
        },

        enable: function () {
            priv.enabled = true;
        },

        close: function () {
            if (document.querySelector('.js-modal')) {
                document.querySelector('.js-modal').classList.add('hidden');
            }
        },
        
        initGTMProductClick: function (gtagData) {
        	dataLayer.push({
				'event': 'productClick',
				'ecommerce': {
					'click': {
						'actionField': {'list': gtagData[0].list},
						'products': [{
							'name': gtagData[0].name,
							'id': gtagData[0].id,
							'price': gtagData[0].price,    
							'brand': gtagData[0].brand,
							'category': gtagData[0].category,
							'variant': gtagData[0].variant,  
							'dimension1': gtagData[0].dimension1,  
							'dimension2': gtagData[0].dimension2,  
							'dimension3': gtagData[0].dimension3  
						}]
					}
				}
			});
        },
        
        initGTMPromotionClick: function (gtagData) {
        	dataLayer.push({
				'event': 'promotionClick',
				'ecommerce': {
					'promoClick': {
						'promotions': [{
							'id': gtagData[0].id,
							'name': gtagData[0].name,
							'creative': gtagData[0].creative,
							'position': gtagData[0].position
						}]
					}
				}
			});
        }

    };

    return {
        onReady: function () {
            priv.init();
        },
        isEnabled: priv.isEnabled,
        enable: priv.enable,
        onClose: priv.onClose,
        close: priv.close
    };

}(window, jQuery);
