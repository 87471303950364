﻿import helpers from '../js/utils/helpers';

const selectors = {
    MODAL: '.js-modal',
    MODAL_CONTENT: '.js-modal-content',
    CLOSE_BTN: '.js-modal-close',
    ACTION_EL: '[data-popup-action]',
    AJAXED_MODAL: '.js-modal-ajax'
};

const popup = {
    customClasses: '',
    currentPopup: null,
    isModalOpen: false,
    init: function() {
        const popups = document.querySelectorAll('[data-popup]');
        popups.forEach(popup => {
            this.beforeOpen(popup);
            this.bindEvents(popup);
        });
    },

    bindEvents: function(popup) {
        const isPopupBtn = popup.matches('[data-popup]'); //This is for ajaxed popup
        if (isPopupBtn) {
            popup.addEventListener('click', (e) => {
                let cb = null;
                if (popup.getAttribute('data-cb')) {
                    cb = popup.getAttribute('data-cb');
                }
                e.preventDefault();
                if (this.isModalOpen) {
                    return;
                }
                this.isModalOpen = true;
                let url = popup.getAttribute('href');
                url = helpers.appendParamToURL(url, 'format', 'ajax');
                this.openPopup(url, cb);
            });
        }

        document.body.addEventListener('click', (e) => {
            e.stopPropagation();

            const modal = document.querySelector(selectors.MODAL);
            const isAjaxPopupOpen = document.querySelector(selectors.AJAXED_MODAL);
            const isStandardPopupOpen = modal && !modal.classList.contains('hidden') && !modal.classList.contains('js-modal-ajax');
            const standardPopupClickOutside =  isStandardPopupOpen && !e.target.closest(selectors.MODAL_CONTENT);
            const AjaxPopupClickOutside = isAjaxPopupOpen && e.target.closest(selectors.MODAL) && !e.target.closest(selectors.MODAL_CONTENT);
            const closeButtonClicked = modal && e.target.matches(selectors.CLOSE_BTN);
            const actionElementClicked = modal && e.target.matches(selectors.ACTION_EL);

            if (AjaxPopupClickOutside || standardPopupClickOutside) {
                if (isAjaxPopupOpen) {
                    this.closePopup(); //this will remove popup from the DOM
                } else {
                    this.closePopup(modal); //this will just add class on the popup
                }
                return;
            }

            if (!document.querySelector(selectors.MODAL) || (!closeButtonClicked && !actionElementClicked)) {
                return;
            }

            if (e.target.matches(selectors.ACTION_EL)) {
                this.submit(e);
                return;
            }

            if (isAjaxPopupOpen) {
                this.closePopup(); //this will remove popup from the DOM
            } else {
                this.closePopup(popup); //this will just add class on the popup
            }
        });
    },

    closePopup: function(popup) {
        if (!popup) {
            document.querySelector(selectors.MODAL).remove();
        } else {
            popup.classList.add('hidden');
        }
        helpers.enableScroll();
        this.isModalOpen = false;
    },

    openPopup: function(urlOrElement, cb) {
        if (typeof urlOrElement == 'string') { // it is url and should be ajaxed
            helpers.getAjax(urlOrElement, (res) => {
                if (document.querySelector(selectors.AJAXED_MODAL)) {
                    return;
                }

                this.createPopup(res);
                helpers.disableScroll();

                if (cb) { // callback if needed e.g. trigger validation after ajaxed form
                    var parsedCB = eval(cb);
                    parsedCB();
                }
                const popupEvent = new Event('popup-initiated');
                document.dispatchEvent(popupEvent);
            });
        } else { // it's a DOM element (object), should just show it
            setTimeout(() => {
                urlOrElement.classList.remove('hidden');
                helpers.disableScroll();
            }, 100);
        }
    },

    beforeOpen: function(popup) {
        if (popup && popup.getAttribute('data-popup')) {
            const data = JSON.parse(popup.getAttribute('data-popup'));
            if (data.addClass != null) {
                this.customClasses = data.addClass;
            }

            if (data.addParam) {
                const url = popup.getAttribute('href');
                popup.setAttribute('href', helpers.appendParamsToURL(url, data.addParam));
            }
        }
    },

    createPopup: function(content) {
        const popup = document.createElement('div');
        popup.className = 'modal js-modal js-modal-ajax ' + this.customClasses;
        popup.innerHTML = '<div class="modal__wrapper">'+
                        '<div class="modal__container">'+
                        '<button title="Close (ESC)" type="button" class="js-modal-close modal__close-btn">×</button>'+
                        '<div class="modal__content js-modal-content">'+
                        content +
                        '</div>'+
                        '</div>'+
                        '</div>';
        document.body.insertBefore(popup, document.body.firstChild);

        this.currentPopup = popup;
    },

    submit: function(e) {
        e.preventDefault();
        const popup = this.currentPopup;

        if (!popup) {
            return;
        }

        if (e.target.getAttribute('data-popup-action') && JSON.parse(e.target.getAttribute('data-popup-action')).action) {
            if (JSON.parse(e.target.getAttribute('data-popup-action')).action === 'close') {
                this.closePopup();
                return;
            }
        }

        const container = popup.querySelector('.modal__content');
        const form = popup.querySelector('form');

        const additionalInput = document.createElement('input');

        additionalInput.setAttribute('name', e.target.getAttribute('name'));
        additionalInput.setAttribute('type', 'hidden');
        form.appendChild(additionalInput);

        let url = form.getAttribute('action');
        url = helpers.appendParamToURL(url, 'format', 'ajax');

        helpers.postAjax(url, helpers.serialize(form), function(res) {
            container.innerHTML = res;
        });
    }
}
popup.init();

export default popup;
