var MD = window.MD || {};

MD.account = function(window, $) {
    
	var elements = {};
    
    var priv = {
		skipCaptcha: false,
		
		/**
		 * @function
		 * @description Binds the events of the order, address and payment pages
		 */
		init : function () {
			priv.toggleFullOrder();
			
			if ( $('div.pt_account').length === 0 ) { return; }
			
			elements.addressForm = $(".js-address-form");
			elements.importedUserPopup = $(".js-imported-popup");

			priv.initializeAddressForm();
			priv.initPaymentEvents();
			priv.initLoginPage();
			priv.handleImportedUser();
			priv.initNewsletterUnsubscribe();
		},

		handleImportedUser: function() {
			// if popup container not found then return
			if (elements.importedUserPopup.length == 0) {
				return;
			}
			
			MD.popups.open({
			    items: {
			        type: "inline",
			        src: elements.importedUserPopup
			    }
			});

		},
		
		initializeAddressForm: function () {
			$(document).on('submit', '.js-address-form', function (e) {
				e.preventDefault();

				const addressId = $(".js-addr-id");
				if (addressId.length) {
                    addressId.val(addressId.val().replace(/[^\w+-]/g, "-"));
                }
                if (MD.addressValidate && MD.addressValidate.validator()) {
                    const addressFormValidator = MD.addressValidate.validator();

                    addressFormValidator.validateEach();

                    if (!addressFormValidator.isValid) {
                        return;
                    }
                }

                var url = $('.js-address-form').attr('action');

				url = app.util.appendParamsToUrl(url, {
					format: "ajax"
				});
				var applyName = $('.js-address-form').find('.apply-button').attr('name');
				var options = {
					url: url,
					data: $('.js-address-form').serialize() + "&" + applyName + '=x',
					type: "POST"
				};

				$.ajax(options).done(function (data) {
					
					if (typeof (data) !== 'string') {
						
						if (data.success) {
							MD.dialog.close();
							app.page.refresh();
						}
						else {
							alert(data.message);
							return false;
						}
					}
					else {
						$('#dialog-container').html(data);
						app.account.init();
					}
				});
			})
			.on("click", ".cancel-button, .close-button", function (e) {
				e.preventDefault();				
				MD.dialog.close();
			})
			.on("click", ".delete-button", function (e) {
				e.preventDefault();
				
				if (confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
					
					var url = app.util.appendParamsToUrl(Urls.deleteAddress, {
						AddressID: elements.addressForm.find("#addressid").val(),
						format: "ajax"
					});
					
					$.ajax({
						url: url,
						method: "POST",
						dataType: "json"
					})
					.done(function (data) {
						if (data.status.toLowerCase() === "ok") {
							MD.dialog.close();
							app.page.refresh();
						}
						else if (data.message.length > 0) {
							alert(data.message);
							return false;
						}
						else {
							MD.dialog.close();
							app.page.refresh();
						}
					});
				}
			});
		},
		
		
		/**
		 * @private
		 * @function
		 * @description Toggles the list of Orders
		 */
		toggleFullOrder: function() {
			$('.order-items')
				.find('li.hidden:first')
					.prev('li')
						.append('<a class="toggle">View All</a>')
						.children('.toggle')
							.click(function() {
								$(this).parent().siblings('li.hidden').show();
								$(this).remove();
							});
		},
		
		/**
		 * @private
		 * @function
		 * @description Binds the events of the payment methods list (delete card)
		 */
		initPaymentEvents: function() {
			var paymentList = $(".payment-list");
			if (paymentList.length===0) { return; }

			app.util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

			$("form[name='payment-remove']").on("submit", function(e){
				e.preventDefault();
				// override form submission in order to prevent refresh issues
				var button = $(this).find("button.delete");
				$("<input/>").attr({type:"hidden", name:button.attr("name"), value:button.attr("value")||"delete card"}).appendTo($(this));
				var data = $(this).serialize();
				$.ajax({
					type: "POST",
					url: $(this).attr("action"),
					data: data
				})
				.done(function(response) {
					app.page.redirect(Urls.paymentsList);
				});
			});
		},
		
		/** 
		 * @private 
		 * @function 
		 * @description init events for the loginPage
		 */
		initLoginPage: function() {
			
			//o-auth binding for which icon is clicked
			$('.oAuthIcon').bind( "click", function() {
				$('#OAuthProvider').val(this.id);
			});	
			
			//toggle the value of the rememberme checkbox
			$( "#dwfrm_login_rememberme" ).bind( "change", function() {
				if($('#dwfrm_login_rememberme').attr('checked')){
					$('#rememberme').val('true')
				}else{
					$('#rememberme').val('false')
				}
			});	
					
		},
		
		initNewsletterUnsubscribe: function() {
			// check if we have the "unsubscribe" link on the page
			if ($('#jsNewsletterUnsubcribe').length == 0) {
				return;
			}
			
			$('#jsNewsletterUnsubcribe').on("click", function () {
				$.ajax({
					type: "POST",
					url: Urls.newsletterUnsubscribe
				})
				.done(function(response) {
					if (!response.success) {
						$('.js-unsubscribe-message').text(response.error);
					}
					
					var popup = $('.js-unsubscribe-popup');
					
					// show a popup to inform imported customers
		        	var options = $.extend(true, {}, MD.dialog.settings, {
						autoOpen : true,
						height : 'auto',
						width : '720',
						dialogClass : 'newsletter-unsubscribe',
						title : Resources.NEWSLETTER_UNSUBSCRIBE_POPUP_TITLE,
						resizable : false,
						open: function() {
							// on close
		                    $(".js-close-button", popup).on("click", function(){
		                    	MD.dialog.close();
		                    	location.href = Urls.myAccount;
		                    });
						}
					});

					MD.dialog.create({
						target : popup,
						options : options
					});
				});
			});
		}
		
    };
    
    return {
        /**
         * Initializes the logic for the current page
         * to be called on $(document).ready
         */
        onReady: function() {
            priv.init();
        }

    };
}(window, jQuery);

$(document).ready(function() {
    MD.account.onReady();
});
