var MD = window.MD || {};

MD.bonusproduct = function(window, $) {
	var elements = {};
    
    var priv = {
		selectedList: [],
		maxItems: 1,
		bliUUID: "",
    		
		init: function () {
		},
		
		/**
		 * @function
		 * @description Opens the bonus product quick view dialog
		 */
		show : function (url) {
			// add element to cache if it does not already exist
			if (!elements.bonusProduct || elements.bonusProduct.length === 0) {
				priv.init();
			}
		},
		
		/**
		 * @private
		 * @function
		 * @description Updates the summary page with the selected bonus product
		 */
		updateSummary: function() {
			if (priv.selectedList.length === 0) {
				elements.bonusProductList.find("li.selected-bonus-item").remove();
			}
			else {
				var ulList = elements.bonusProductList.find("ul.selected-bonus-items").first();
				// EMAKINA: bonus product fix:
				// when there is already selected bonus products and you want to select one more or remove one from existing then we first remove all
				// already existing bonus products from the html where we display them to user in the popup. Else it was being appended and a bonus product may have shown multiple times.
				ulList.find(".selected-bonus-item").remove();
				var itemTemplate = ulList.children(".selected-item-template").first();
				var i, len;
				for (i = 0, len = priv.selectedList.length; i < len; i++) {
					var item = priv.selectedList[i];
					var li = itemTemplate.clone().removeClass("selected-item-template").addClass("selected-bonus-item");
					li.data("uuid", item.uuid).data("pid", item.pid);
					li.find(".item-name").html(item.name);
					li.find(".item-qty").html(item.qty);
					var ulAtts = li.find(".item-attributes");
					var attTemplate = ulAtts.children().first().clone();
					ulAtts.empty();
					var att;
					for (att in item.attributes) {
						var attLi = attTemplate.clone();
						attLi.addClass(att);
						attLi.children(".display-name").html(item.attributes[att].displayName);
						attLi.children(".display-value").html(item.attributes[att].displayValue);
						attLi.appendTo(ulAtts);
					}
					li.appendTo(ulList);
				}
				ulList.children(".selected-bonus-item").show();
			}

			// EMAKINA: bonus product fix:
			// sitegenesis just checks the length of array but we need to also check quantity
			var totalQuantity = priv.getCount();
			
			// get remaining item count
			var remain = priv.maxItems - totalQuantity;
			elements.bonusProductList.find(".bonus-items-available").text(remain);
			if (remain <= 0) {
				priv.disableVariationSelection();
			}
			else {
				priv.enableVariationSelection();
			}
		},
		
		disableVariationSelection : function() {
			elements.bonusProductList.find("button.button-select-bonus").attr("disabled", "disabled");
			// EMAKINA: bonus product fix:
			// Also disable variation attribute selections (color and size)
			elements.bonusProductList.find("div.bonus-product-item select.js-bonus-variant").attr("disabled", "disabled");
			elements.bonusProductList.find("div.bonus-product-item a[href].swatchanchor").attr("disabled", "disabled");
		},
		
		enableVariationSelection : function() {
			elements.bonusProductList.find("button.button-select-bonus").removeAttr("disabled");
			// EMAKINA: bonus product fix:
			// Also enable variation attribute selections (color and size)
			elements.bonusProductList.find("div.bonus-product-item select.js-bonus-variant").removeAttr("disabled");
			elements.bonusProductList.find("div.bonus-product-item a[href].swatchanchor").removeAttr("disabled");
		},
		
		getCount : function() {
			var length = 0;
			for (var i = 0 ; i < priv.selectedList.length; i++) {
				length += parseInt(priv.selectedList[i].qty);
			}
			
			return length;
		},
		
		/**
		 * @function
		 * @description
		 */
		initializeGrid : function () {
			elements.bonusProductList = $("#bonus-product-list");
			var bliData = elements.bonusProductList.data("line-item-detail");

			priv.maxItems = bliData.maxItems;
			priv.bliUUID = bliData.uuid;

			if (bliData.itemCount >= priv.maxItems) {
				priv.disableVariationSelection();
			}

			var cartItems = elements.bonusProductList.find(".selected-bonus-item");
			
			// EMAKINA: bonus product fix:
			// if somehow "Add To Bag" button fails and the popup closes without page refresh then the previous items in the Array still exists
			// then in the code below it adds the items again in to the Array. So we empty the Array at first.
			if (priv.selectedList.length > 0) {
				var selectedListSize = priv.selectedList.length; 
				for (i = 0; i < selectedListSize; i++) {
					priv.selectedList.pop();
				}
			}

			cartItems.each(function() {
				var ci = $(this);

				// EMAKINA: bonus product fix:
				// added options empty array as a property
				var product = {
					uuid : ci.data("uuid"),
					pid : ci.data("pid"),
					qty : ci.find(".item-qty").text(),
					name : ci.find(".item-name").html(),
					attributes: {},
					options : []
				};
				
				var attributes = ci.find("ul.item-attributes li");
				attributes.each(function(){
					var li = $(this);
					product.attributes[li.data("attributeId")] = {
						displayName:li.children(".display-name").html(),
						displayValue:li.children(".display-value").html()
					};
				});
				
				priv.selectedList.push(product);
			});

			elements.bonusProductList.on("click", "div.bonus-product-item a[href].swatchanchor", function (e) {
				e.preventDefault();

				var anchor = $(this),
					bpItem = anchor.closest(".bonus-product-item"),
					bpForm = bpItem.find("form.bonus-product-form"),
					qty = bpForm.find("input[name='Quantity']").first().val(),
					params = {
						Quantity : isNaN(qty) ? "1" : qty,
						format : "ajax",
						source : "bonus",
						bonusDiscountLineItemUUID : priv.bliUUID
					};

				var url = app.util.appendParamsToUrl(this.href, params);

				app.progress.show(bpItem);
				app.ajax.load({
					url: url,
					callback : function (data) {
						bpItem.html(data);
					}
				});
			})
			// EMAKINA: bonus product fix:
			// we changed "size" selection attribute from link to select. So when user selects a size of bonus product then it should be handled correctly
			.on("change", "div.bonus-product-item select.js-bonus-variant", function (e) {
				e.preventDefault();

				var anchor = $(this),
					bpItem = anchor.closest(".bonus-product-item"),
					bpForm = bpItem.find("form.bonus-product-form"),
					qty = bpForm.find("input[name='Quantity']").first().val(),
					params = {
						Quantity : isNaN(qty) ? "1" : qty,
						format : "ajax",
						source : "bonus",
						bonusDiscountLineItemUUID : priv.bliUUID
					};

				var url = app.util.appendParamsToUrl(this.value, params);

				app.progress.show(bpItem);
				app.ajax.load({
					url: url,
					callback : function (data) {
						bpItem.html(data);
					}
				});
			})
			.on("click", "button.button-select-bonus", function (e) {
				e.preventDefault();
				
				// EMAKINA: bonus product fix:
				// sitegenesis just checks the length of array but we need to also check quantity
				var totalQuantity = priv.getCount();
				
				if (totalQuantity >= priv.maxItems) {
					priv.disableVariationSelection();
					elements.bonusProductList.find("bonus-items-available").text("0");
					return;
				}

				var form = $(this).closest("form.bonus-product-form"),
					detail = $(this).closest(".js-product-detail");
					uuid = form.find("input[name='productUUID']").val(),
					qtyVal = form.find("select[name='Quantity']").val(),
					qty = isNaN(qtyVal) ? 1 : (+qtyVal);
					
				if (qty + totalQuantity > priv.maxItems) {
					alert(Resources.BONUS_PRODUCT_QUANTITY_FAILED);
					return;
				}

				var product = {
					uuid : uuid,
					pid : form.find("input[name='pid']").val(),
					qty : qty,
					name : detail.find(".product-name").text(),
					attributes : detail.find(".product-variations").data("current"),
					options : []
				};

				var optionSelects = form.find("select.product-option");

				optionSelects.each(function (idx) {
					product.options.push({
						name : this.name,
						value : $(this).val(),
						display : $(this).children(":selected").first().html()
					});
				});
				
				priv.selectedList.push(product);
				priv.updateSummary();

			    // Add bonus product instantly when limit is 1
				if (priv.maxItems <= 1) {
				    $('.add-to-cart-bonus').trigger('click');
				}
			})
			.on("click", ".remove-link", function(e){
				e.preventDefault();
				var container = $(this).closest("li.selected-bonus-item");
				if (!container.data("uuid")) { return; }

				var uuid = container.data("uuid");
				var i, len = priv.selectedList.length;
				for(i = 0; i < len; i++) {
					if (priv.selectedList[i].uuid === uuid) {
						priv.selectedList.splice(i,1);
						break;
					}
				}
				
				priv.updateSummary();
			})
			.on("click", ".add-to-cart-bonus", function (e) {
				e.preventDefault();
				var url = app.util.appendParamsToUrl(Urls.addBonusProduct, {bonusDiscountLineItemUUID:priv.bliUUID});
				var bonusProducts = priv.getBonusProducts();
				
				// make the server call
				$.ajax({
					type : "POST",
					dataType : "json",
					cache	: false,
					contentType : "application/json",
					url : url,
					data : JSON.stringify(bonusProducts)
				})
				.done(function (response) {
					// success
					app.page.refresh();
				})
				.fail(function (xhr, textStatus) {
					// failed
					if(textStatus === "parsererror") {
						window.alert(Resources.BAD_RESPONSE);
					} else {
						window.alert(Resources.SERVER_CONNECTION_ERROR);
					}
				})
				.always(function () {
					elements.bonusProduct.dialog("close");
				});
			});
		},
		
		getBonusProducts: function() {
			var o = {};
			o.bonusproducts = [];

			var i, len;
			for (i = 0, len = priv.selectedList.length; i < len; i++) {
				var p = { pid : priv.selectedList[i].pid, qty : priv.selectedList[i].qty, options : {} };
				var a, alen, bp = priv.selectedList[i];
				for (a = 0, alen = bp.options.length; a < alen; a++) {
					var opt = bp.options[a];
					p.options = {optionName:opt.name,optionValue:opt.value};
				}
				o.bonusproducts.push({product:p});
			}
			
			return o;
		},
		
		/**
		 * @function
		 * @description Loads the list of bonus products into quick view dialog
		 */
		loadBonusOption : function () {
			var bonusDiscountContainer = $(".bonus-discount-container");
			if (bonusDiscountContainer.length === 0) { return; }

			app.dialog.create({
				target : bonusDiscountContainer,
				options : {
					height : 'auto',
					width : 350,
					dialogClass : 'quickview',
					title : Resources.BONUS_PRODUCT
				}
			});

			bonusDiscountContainer.dialog('open');

			// add event handlers
			bonusDiscountContainer.on("click", ".select-bonus-btn", function (e) {
				e.preventDefault();
				var uuid = bonusDiscountContainer.data("lineitemid");
				var url = app.util.appendParamsToUrl(Urls.getBonusProducts,
													 {
														bonusDiscountLineItemUUID : uuid,
														source : "bonus"
													 });

				bonusDiscountContainer.dialog('close');
				priv.show(url);
			}).on("click", ".no-bonus-btn", function (e) {
				bonusDiscountContainer.dialog('close');
			});
		}
    };
    
    return {
        /**
         * Initializes the logic for the current page
         * to be called on $(document).ready
         */
        onReady: function() {
            priv.init();
        },
        
        show: function(url) {
        	priv.show(url);
        },
        
        loadBonusOption : function () {
        	priv.loadBonusOption();
        },

        initializeGrid: function () {
            priv.initializeGrid();
        }
    };
}(window, jQuery);

$(document).ready(function() {
    MD.bonusproduct.onReady();
});

function bonusProductsInitGrid() {
    MD.bonusproduct.initializeGrid();
}
