/*
This file has legacy code that has to be updated.
If you're working on any of the functions below, please move it to separate file,
rewrite it in vanilla javascript and import in cartridges\MD_Storefront\cartridge\static\default\js\searchPage.js.
*/

/* eslint-disable */

var MD = window.MD || {};
import slider from '../components/slider';
import priceFilters from './search/priceFilters';
import notifyMe from './productDetail/notifyMe';

MD.categoryPage = function(window, $) {
    var elements = {};
    /**
     * @namespace Private methods and variables
     */
    var priv = {
        init: function() {
            elements.topLevelCatMenu = $('.menu-category.level-1');
            elements.searchResultsBannerGlobal = $('.slot-grid-header');
            elements.catMainBanner = $('#cat-main-banner');
            elements.catMainBannerShowOnly = $('#cat-main-banner-show-only');
            elements.searchResultContent = $('.search-result-content');

            // init event handler to toggle Category Description on click
            priv.initToggleSeoText();

        	// return if not a category or search result page
        	if ( $('.search-result-content').length === 0 ) { return; }

            elements.main = $("#main");

            $(document).on('grid-update', function() {
                elements.searchResultContent = $('.search-result-content');

                // reinit product tile sliders
                // priv.initTileImagesSlider();
                slider.init();

                // reinit notify me event handlers
                notifyMe.reInitNotifyMe();

                // reinit grid layout
                priv.initGridLayout();
            });

            $(document).on('appinitdone', function() {
                elements.searchResultContent = $('.search-result-content');

                // init grid + grid toggle
                priv.initGridLayout();
            });

            $(document).on('refinements-update', function() {
                priv.scrollToBanner();

                // reinit product tile sliders
                slider.init();

                // reinit notify me event handlers
                notifyMe.reInitNotifyMe();

                // init grid + grid toggle
                priv.initGridLayout();

                priceFilters.init();
            });

            // update the page with hash filters
            priv.updateProductListing();

            // reinit product tile sliders
            slider.init();

            // reinit notify me event handlers
            notifyMe.reInitNotifyMe();

            $('body').on('click', '.js-color-swatch-link', function(e) {
                e.preventDefault();

                var $colorSwatch = $(e.target);
                //find the product tile
                var $productTile =  $colorSwatch.closest('.js-product-tile');
                // get new variation product URL
                var colorSwatchURL = $colorSwatch.attr('href');
                // replace the product links in the tile
                var productImageLink = $productTile.find(".js-product-link").attr('href', colorSwatchURL);
                var isRefinedBySize = $productTile.find(".js-product-link").data('isrefinedbysize');
                //find the pID for the colorvariant
                var productOfThisColorID = $colorSwatch.data("productid");
                var url = app.util.appendParamsToUrl(Urls.GetImagesPipeline, {pid: productOfThisColorID, isrefinedbysize: isRefinedBySize});

                // close tile overlays if open
                $('.js-close-mobile-size-layout').trigger('click');

                app.ajax.load({
                    url: url,
                    callback: function(response) {
                        response = JSON.parse(response);

                        var productTileTemplate = response.template;

                        // replace whole tile with new one
                        $productTile.replaceWith(productTileTemplate);

                        //initializeSlider
                        slider.init();

                        // remove selected class from other swatch icons
                        $colorSwatch.closest('.js-color-swatches-list').find('.js-swatch-list-item').removeClass('selected');
                        // add selected class to this swatch icon
                        $colorSwatch.closest('.js-swatch-list-item').addClass('selected');

                        // get wishlist and update wishlist icon
                        if (window.wishListData) {
                            var wishlistItems = window.wishListData.wishlistItemIds;
                            var wishlistItemsArray = wishlistItems == null || wishlistItems.trim().length == 0 ? '' : wishlistItems.split(";");
                            var wishlistIconHTML = response.wishlistIconTemplate;

                            // send first child of product wishlist button, whether img or icon
                            app.search.setAddToWishlistIcon($productTile.find(".product-wishlist-button").children().first(), wishlistItemsArray, wishlistIconHTML);
                        }
                    }
                });

                function getGTMdata(response) {
                    productImageLink.attr("data-gtag", JSON.stringify(response));
                
                } 
            });
            
            priv.pushSegmentDataToGTM();
        },               
      
        pushSegmentDataToGTM: function() {
        	var ab_test_id = 'PLP A/B test';
        	var segmentid = ($('.js-new-plp-active').length > 0 ? 'New PLP' : 'Old PLP');
        	
    		dataLayer.push({
        		'event': 'experimentEvent',
        		'experimentID': ab_test_id,
        		'experimentvariant': segmentid
        	});       	  
        },

        /**
         * @private
         * @function
         * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
         */
        updateProductListing: function() {
            if (!elements.searchResultContent || elements.searchResultContent == 'undefined' || elements.searchResultContent.length === 0) {
                return;
            }


            var hash = encodeURI(decodeURI(window.location.hash));
            if (hash === '#results-content' || hash === '#results-products') {
                return;
            }

            var refineUrl = null;
            if (hash.length > 0) {
                refineUrl = window.location.pathname + "?" + hash.substr(1);
            }

            if (!refineUrl) {
                // apparently no ajax calls available, still do this init
                app.product.tile.init();
                return;
            }

            app.progress.show(elements.searchResultContent);
            var url = app.util.appendParamToURL(refineUrl, "format", "ajax");
            elements.main.load(url, function() {
                priceFilters.init();
                // reinit product tile sliders
                slider.init();

                // reinit notify me event handlers
                notifyMe.reInitNotifyMe();

                app.product.tile.init();
                app.progress.hide();
                if (SitePreferences.LISTING_INFINITE_SCROLL) {
                    jQuery(document).trigger('grid-update');
                }
                jQuery(document).trigger('refinements-update');
            });
        },

        scrollToBanner: function() {
        	$('html, body').animate({
        		scrollTop: $('#primary').offset().top - 85
        	}, 800);
        },

        initToggleSeoText: function () {
            $(document).on('click', '.js-toggle-seo-text', function(e){
                $(e.target).toggleClass('expand');
            });
        },

        initGridLayout: function(){
            // render preferred grid (according to cookie) or according to url param
            if (!!MD.gridToggle) {
                MD.gridToggle.renderGridLayout();

                // init grid toggle functionality
                MD.gridToggle.onReady();
            }
        }
    };

    /** @scope categoryPage */
    return {
        /**
         * Initializes the logic for the current page
         * to be called on $(document).ready
         */
        onReady: function() {
            priv.init();

            // init grid + grid toggle
            priv.initGridLayout();

            // only init these events in pages with namespace: search
            if (app.page && app.page.ns == 'search'){
                // init add to cart events on new PLP redesign
                MD.productDetailPage.initTileAddToCart();

                // init images slider in product tiles
                slider.init();

                // init Notify me event handlers
                notifyMe.init();
            }
        }
    };
}(window, jQuery);

$(() => {
    MD.categoryPage.onReady();
});
/* eslint-enable */