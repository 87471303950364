/* eslint-disable */
const cssConstants = require('./cssConstants');

const helpers = {
    isScrollDisabled: false,
    topScroll: 0,
    win: window,
    body: document.querySelector('body'),
    disabledScrollClass: cssConstants.SCROLL_DISABLE,

    disableScroll: function() {
        if (!this.isScrollDisabled) {
            this.topScroll = this.win.scrollTop;
            this.body.style.top = -this.topScroll + 'px';
            this.body.classList.add(this.disabledScrollClass);
            this.isScrollDisabled = true;
        }
    },

    enableScroll: function() {
        this.body.removeAttribute('style');
        this.body.classList.remove(this.disabledScrollClass);
        this.win.scrollTop = this.topScroll;
        this.isScrollDisabled = false;
    },

    throttle: function(func, interval) {
        let timeout = '';
        return function() {
            const _this = this;
            const args = arguments;
            const later = function() {
                timeout = false;
            };
            if (!timeout) {
                func.apply(_this, args);
                timeout = true;
                setTimeout(later, interval || 250);
            }
        };
    },

    debounce: function(func, interval) {
        let timeout = '';
        return function() {
            const _this = this;
            const args = arguments;
            const later = function() {
                timeout = null;
                func.apply(_this, args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, interval || 100);
        };
    },

    appendParamToURL: function (url, name, value) {
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        var paramValue = decodeURIComponent(value);
        return url + separator + name + '=' + encodeURIComponent(paramValue);
    },

    appendParamsToURL: function(url, data) {
        const queryString =  new URLSearchParams(data).toString();
        return url + '&' + queryString;
    },

    scrollTo(to, duration, elementToScroll) {
        const element = elementToScroll || document.documentElement;
        const start = element.scrollTop;
        const change = to - start;
        const startDate = +new Date();
        // t = current time, b = start value, c = change in value, d = duration
        const easeInOutQuad = function(t, b, c, d) {
            t /= d/2;
            if (t < 1) return c/2*t*t + b;
            t--;
            return -c/2 * (t*(t-2) - 1) + b;
        };
        function animateScroll() {
            const currentDate = +new Date();
            const currentTime = currentDate - startDate;
            element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
            if(currentTime < duration) {
                requestAnimationFrame(animateScroll);
            }
            else {
                element.scrollTop = to;
            }
        };
        animateScroll();
    },

    getAjax(url, success) {
        const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
        xhr.open('GET', url);
        xhr.onreadystatechange = function() {
            if (xhr.readyState>3 && xhr.status==200) success(xhr.responseText);
        };
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send();
        return xhr;
    },

    postAjax(url, data, success) {
        const params = typeof data == 'string' ? data : Object.keys(data).map(
                function(k){ return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
            ).join('&');

        const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
        xhr.open('POST', url);
        xhr.onreadystatechange = function() {
            if (xhr.readyState>3 && xhr.status==200) {
                if (typeof success !== 'undefined') {
                    success(xhr.responseText);
                }
            }
        };
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(params);
        return xhr;
    },

    serialize(form, isJSON) {

        if (isJSON) {
            var obj = {};
            Array.prototype.slice.call(form.elements).forEach(function (field) {
                if (!field.name || field.disabled || ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1) return;
                if (field.type === 'select-multiple') {
                var options = [];
                Array.prototype.slice.call(field.options).forEach(function (option) {
                    if (!option.selected) return;
                    options.push(option.value);
                });
                if (options.length) {
                    obj[field.name] = options;
                }
                return;
                }
                if (['radio'].indexOf(field.type) > -1 && !field.checked) return;
                if (['checkbox'].indexOf(field.type) > -1) {
                    obj[field.name] = field.checked;
                } else {
                    obj[field.name] = field.value;
                }
            });
            return obj;
        } else {
            let field, l, s = [];
            if (typeof form == 'object' && form.nodeName == "FORM") {
                for (let i = 0; i < form.elements.length; i++) {
                    field = form.elements[i];
                    if (field.name && !field.disabled && field.type != 'file' && field.type != 'reset' && field.type != 'submit' && field.type != 'button') {
                        if (field.type == 'select-multiple') {
                            l = form.elements[i].options.length;
                            for (var j=0; j<l; j++) {
                                if(field.options[j].selected)
                                    s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value);
                            }
                        } else if ((field.type != 'checkbox' && field.type != 'radio') || field.checked) {
                            s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
                        }
                    }
                }
            }
            return s.join('&').replace(/%20/g, '+');
        }
    },

    isJSONvalid: function(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },

    makePrevButtonSticky: function() {
        document.documentElement.classList.add('sticky-prev-button-mobile');
        document.body.classList.add('sticky-prev-button-mobile');
    },

    toggleCollapse: (target) => {
        target.classList.toggle(cssConstants.COLLAPSED);
    },

    isDefined: function(value) {
        return typeof value !== 'undefined';
    },

    clearFormValidation: function(fields) {
        fields.forEach(input => {
            if (input.classList.contains('initially-validated')) {
                input.classList.remove('initially-validated');
            }

            if (input.classList.contains('input-error')) {
                input.classList.remove('input-error');
            }

            if (input.parentElement.querySelector('.js-validation-status')) {
                input.parentElement.querySelector('.js-validation-status').classList.add(cssConstants.HIDDEN);
            }
        });
    },

    resetForm: function(fields) {
        fields.forEach((elem) => {
            if (!elem.matches('[name$="country"]') && !elem.matches('[name$="securekey"]') && !elem.matches('[name$="csrf_token"]')) {
                if (elem.matches('.js-addr-phone') && elem.getAttribute('data-country-code')) {
                    elem.value = elem.getAttribute('data-country-code');
                    const inputEvent = new Event('input', {bubbles: true, cancelable: true});
                    elem.dispatchEvent(inputEvent);
                } else {
                    elem.value = '';
                }
            }
        });
    },

    isMobile: function() {
        return window.matchMedia("screen and (max-width: 767px)").matches;
    },

    isTablet: function() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        return isTablet && window.matchMedia("screen and (min-width: 768px)").matches;
    },

    inDateRange: function(date) {
       let minDate = new Date();
       minDate.setFullYear(minDate.getFullYear() - 100);

       let maxDate = new Date();
       maxDate.setFullYear(maxDate.getFullYear() - 10);
       return new Date(date) >= minDate && new Date(date) <= maxDate;
    },

    // fix for header not pushing content down.
    setHeaderHeight: function() {
        const exceptionPages = document.querySelectorAll('.pt_cart, .pt_checkout, .js-initially-hide-mobile-search');

        if (document.querySelector('.js-header-space')) {
            const headerHeight = document.querySelector('.js-header-total .header-fixed').offsetHeight;
            const mobileSearchHeight = exceptionPages.length ? 0 : document.querySelector('.js-mobile-search').offsetHeight; // on pdp, cart and checkout search bar is hidden
            const totalHeight = headerHeight + mobileSearchHeight;
            document.querySelector('.js-header-space').style.height = totalHeight + 'px';
        }
    }
};

module.exports = helpers;
