import helpers from '../../utils/helpers';
import pdp from './pdp'; // eslint-disable-line

const cache = {
    PDP_MAIN: document.querySelector('.js-pdp-main')
};

const selectors = {
    SWATCH: '.js-product-detail a[href].swatchanchor',
    QUICKVIEW_POPUP: '.popup-quickview',
    PRODUCT_VARIATIONS: '.js-product-variations'
};

const swatchChange = {
    getGTMProductDetail: function (gtagData) {
        dataLayer.push({
            event: 'productDetail',
            ecommerce: {
                detail: {
                    products: [{
                        name: gtagData[0].name,
                        id: gtagData[0].id,
                        price: gtagData[0].price,
                        category: gtagData[0].category,
                        variant: gtagData[0].variant,
                        dimension1: gtagData[0].dimension1,
                        dimension2: gtagData[0].dimension2,
                        dimension3: gtagData[0].dimension3,
                        dimension4: gtagData[0].dimension4,
                        dimension5: gtagData[0].dimension5
                    }]
                }
            }
        });
    },

    init: function() {
        if (!cache.PDP_MAIN) {
            return;
        }

        document.querySelectorAll(selectors.SWATCH).forEach((swatch) => {
            swatch.addEventListener('click', function(e) {
                const isQuickviewSwatch = !!this.closest(selectors.QUICKVIEW_POPUP);
                e.preventDefault();
                const swatchListItem = this.closest('li');

                if (swatchListItem.classList.contains('selected') || swatchListItem.classList.contains('unselectable')) {
                    return;
                }
                if (isQuickviewSwatch) {
                    app.progress.show(document.querySelector(selectors.QUICKVIEW_POPUP));
                } else {
                    app.progress.show(cache.PDP_MAIN);
                }

                const variationData = pdp.getVariationData(this);
                const hasSwapImage = this.getAttribute('data-lgimg') !== null;

                helpers.getAjax((variationData.url), (data) => {
                    variationData.target.innerHTML = data;
                    if (hasSwapImage) {
                        pdp.updateImages(isQuickviewSwatch);
                    }
                    pdp.reinitPDPcomponents(isQuickviewSwatch);
                    const gtmData = document.querySelector(selectors.PRODUCT_VARIATIONS).dataset.gtagadd;
                    if (gtmData) {
                        swatchChange.getGTMProductDetail(JSON.parse(gtmData));
                    }
                });
            });
        });
    }
};

export default swatchChange;