'use strict';

import helpers from './utils/helpers';

const selectors = {
    ADDRESS_COUNTRY: '.js-addr-country',
    ADDRESS_FORM: '.js-address-form',
    ADDRESS_FIELDS: '.address-fields'
}

const accountCountryChange = {
    init: function() {
        if(document.querySelector(selectors.ADDRESS_COUNTRY)) {
            document.querySelector(selectors.ADDRESS_COUNTRY).addEventListener('change', this.updateCountryField)
        }
    },
    updateCountryField: function() {
        const url = app.util.appendParamsToUrl(Urls.handleAddressChange, {
            countryCode: this.value
        });
        var editForm = document.querySelector(selectors.ADDRESS_FORM);
        helpers.postAjax(url, helpers.serialize(editForm), (res) => {
            document.querySelector(selectors.ADDRESS_FIELDS).innerHTML = res;
            MD.addressValidate.init();
        });        
    }
}

module.exports = accountCountryChange;